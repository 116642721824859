{
    "added": "Added",
    "back": "Back",
    "bar_categories": {
        "title": "Categories"
    },
    "cart": {
        "active": "Active Carts",
        "booked": {
            "help": "These items are confirmed and booked by you and therefore reserved by us. They are displayed here for your reference only. Please contact us if you would like to make any modifications."
        },
        "checkout_button": "Checkout",
        "checkout_header": "Checkout cart",
        "checkout_one": "Checkout ({{count}})",
        "checkout_order": "Checkout Order",
        "checkout_order_id": "Checkout Order {{id}}",
        "checkout_other": "Checkout ({{count}})",
        "checkout_tip": "Checkout - checkout your current order",
        "checkout_zero": "Checkout",
        "content_one": "You have {{count}} product in your cart.",
        "content_other": "You have {{count}} {{units}} in your cart.",
        "empty_booked_desc": "Newly added items will show up here for review. Once ready, this is also where you finalize the order and reserve these items.",
        "empty_booked_title": "You have no newly selected items (yet)",
        "empty_desc": "Products added to the order will show up here for review. Once ready, this is also where you finalize your order and reserve these items.",
        "empty_title": "Add products",
        "error_api": "An error occurred with your cart.",
        "error_help": {
            "api": "Reload the page and/or remove the product with issues. Contact support if the problem persists.",
            "less_available": "Update cart will lower the product's amount to the amount available.",
            "minimum_quantity": "Update cart will raise the product amount to the minimum order quantity",
            "price_up": "Update cart will raise the cart item to the correct price",
            "unavailable": "Update cart will remove this product from the cart"
        },
        "error_less_available": "Less available",
        "error_minimum_quantity": "Minimum order quantity applies",
        "error_ok": "Products ready to proceed",
        "error_one": "This product requires your attention",
        "error_other": "These products require your attention",
        "error_price_up": "The product price has been raised",
        "error_unavailable": "Unavailable",
        "issues": "Shopping cart updates",
        "issues_action": "Address the following product issues to be able to check out your cart.",
        "issues_none": "Your cart is ready",
        "less_available_action": "Adjust the cart amount accordingly, or remove the product from the cart to continue your checkout.",
        "less_available_one": "Only {{count}} {{units}} is available at the moment.",
        "less_available_other": "Only {{count}} {{units}} are available at the moment.",
        "min_quantity": "The minimum order quantity for this product is {{count}} {{units}}.",
        "min_quantity_action": "Adjust the cart amount accordingly, or remove the product from the cart to continue your checkout.",
        "min_quantity_confirm": "Confirm the minimum order amount: {{count}} {{units}}",
        "minus_all": "Remove {{product}} from your cart.",
        "minus_one": "Remove {{count}} {{units}} from your cart.",
        "minus_other": "Remove {{count}} {{units}} from your cart.",
        "new": "Empty Cart",
        "new_key": "I am a translation",
        "new_quantity_confirmed": "New quantity confirmed.",
        "panel": {
            "header": {
                "cart": "Your Cart ({{id}})",
                "cart_new": "Your Cart"
            }
        },
        "plus_all": "Add {{product}} to your cart",
        "plus_one": "Add {{count}} {{units}} to cart",
        "plus_other": "Add {{count}} {{units}} to cart",
        "price_changed": "This product's price changed from {{old_price}} {{currency}} to {{new_price}} {{currency}} .",
        "price_changed_action": "Confirm the changed price or remove the product from the cart to continue your checkout.",
        "price_confirm": "Confirm the new price.",
        "price_confirmed": "New price confirmed.",
        "price_up_agree_help_one": "Updates your cart with the raised price",
        "price_up_agree_help_other": "Updates your cart with the raised prices",
        "price_up_agree_one": "I accept the raised price",
        "price_up_agree_other": "I accept the raised prices",
        "product_amount": "Your cart now contains {{count}} {{units}} of {{product}}",
        "product_removed": "Product {{product}} was removed from your cart",
        "rounded_one": "The number is rounded to {{count}} case ({{bottles}} bottles)",
        "rounded_other": "The number is rounded to {{count}} cases ({{bottles}} bottles)",
        "soi": {
            "booked_title": "Reserved for you",
            "cart_title": "Your cart content ({{count}} products)"
        },
        "stock_one": "We can supply  {{count}} {{units}} to you",
        "stock_other": "We can supply {{count}} {{units}} to you",
        "switched": "You just switched to cart {{cart}}",
        "switched_new": "You just switched to a new cart",
        "total_bottles": "Total bottles",
        "total_cases": "Total cases",
        "total_price": "Total price",
        "unavailable": "Sadly, this product is not available anymore.",
        "unavailable_action": "Remove the product from your cart to continue the checkout.",
        "yours": "Your Cart"
    },
    "checkout": {
        "booked": {
            "types": {
                "arriving": "Arriving items",
                "stock": "From Stock",
                "tbo": "To Be Ordered"
            }
        },
        "cart": "Cart",
        "comment_delete_tip": "Delete Comment",
        "comment_store": "Save Comment",
        "comment_stored": "Comment Stored",
        "comment_title": "Add a comment for this product",
        "delivery": {
            "asap": "As soon as possible",
            "date": "On a specific date"
        },
        "status": {
            "book": "Book products; finalize order later",
            "book_help": "Products are booked for you, you can add more products later and finalize your order then.",
            "confirm_cart_update_help": "All cart updates will be applied automatically",
            "finalize": "Finalize order",
            "finalize_help": "Your order becomes final and we will check your order; you can already download the proforma invoice.",
            "step0_title": "Your Shopping Cart",
            "step1_button": "Finalize Cart",
            "step1_button_fix": "Update Cart",
            "step1_title": "Verify cart",
            "step2_button": "Place Order",
            "step2_button_book": "Book Products",
            "step2_title": "Checkout Cart"
        },
        "total": "Total",
        "vat": "VAT",
        "vouchers": {
            "label": "Vouchers"
        }
    },
    "dashboard": {
        "widgets": {
            "demo_user": {
                "context_1": "With this demo account you can browse stock, see prices and download pricelists. You can upgrade your account by contacting the sales manager. With your own account you will be able to place orders and mark items as favourite.",
                "context_2": "Any orders with this demo account will not be handled by us. So you will not be charged. Feel free to experiment! Please don't hesitate to contact your sales manager if you have any questions. The details can be found in the lower left corner.",
                "title": "Welcome to our portal, good that you stopped by!"
            },
            "latest_orders": {
                "activate_order_tip": "Activate order",
                "active_order": "Current order",
                "stock_complete": "All items are in stock"
            },
            "tips": {
                "title": "Tips & Tricks"
            }
        }
    },
    "demo": {
        "dialog": {
            "button_ok": "All right then!",
            "checkout_context_1": "You found it! The page with the products you want to order and where you can confirm your order is not functional with a demo account. You need a full account for this. To do that, you need a real account, which your salesmanager can supply to you.",
            "checkout_context_2": "And please, let us know what you think of this portal. That way we can make it better for you (and for us). If you have any questions, please contact your sales manager. The details can be found in the lower left corner.",
            "checkout_title": "Checkout Page"
        },
        "favorites_disabled": "Unfortunately it is not possible to store favourites with your demo account. Please contact your sales manager if you have any questions. The details can be found in the lower left corner."
    },
    "email_address": "Email address",
    "excise": {
        "excluding": "excl. {{amount}} excise",
        "including": "incl. {{amount}} excise"
    },
    "filters": {
        "pricelist": {
            "alcohol_format": "Alcohol Format",
            "alcohol_format_help": "How do you want to format alcohol percentage?",
            "cancel_download": "Cancel",
            "download": "Download Pricelist",
            "download_tip": "Export pricelist in Excel format (.xlsx)",
            "download_tip_busy": "Pricelist is currently being composed for you...",
            "filename": "pricelist export",
            "products_selected": "Products: {{count}}/{{total}}",
            "select_all": "Select all products",
            "volume_format": "Volume Format",
            "volume_format_help": "How do you want to format the bottle volume?"
        },
        "reset_one": "Reset filter",
        "reset_other": "Reset filters",
        "selected_one": "{{count}} selected",
        "selected_other": "{{count}} selected",
        "title": {
            "alcohol": "Alcohol Percentage",
            "availability": "Availability",
            "offertype": "Offertype",
            "packaging": "Packaging",
            "refill": "Refillable",
            "search": "Search",
            "volume": "Volume"
        },
        "types": {
            "availability": {
                "eta": "Expected arrival: {{period}}",
                "purchase": "Arriving",
                "stock": "In Stock",
                "tbo": "To Be Ordered"
            },
            "offertype": {
                "FAVORITES": "Favourites",
                "NEW_ARRIVALS": "Just Arrived",
                "SPECIALS": "Special Offers"
            },
            "products": "Products",
            "refill": {
                "nonref": "Non-refillable",
                "ref": "Refillable"
            }
        }
    },
    "help": {
        "explain": "Explain this page"
    },
    "loading": "Loading",
    "location": "Location",
    "messroom": {
        "description": "This is a one-time setup to optimize the shopping experience in our portal, while we offer you the most recent pricing and availability information. Please wait until we prepare your portal account. Thank you for your patience!",
        "step0": "Preparing Account",
        "step1": "Updating Availabilities",
        "step2": "Generating Prices",
        "steps_completed": "All done! You'll be redirected to the portal now...",
        "steps_processing": "Please wait while we prepare your portal account...",
        "team": "Team {{team}}",
        "title": "One-time portal setup"
    },
    "my_portal": {
        "news": "News",
        "privacy": "Privacy",
        "profile": "My Profile",
        "promotions": "Promotions",
        "security": {
            "label": "Security",
            "notifications": {
                "current_password_invalid": "Your current password is invalid",
                "new_password_invalid": "Your new password is invalid",
                "password_updated": "Your password was updated; please login again."
            },
            "password": {
                "current": "Current Password",
                "current_help": "Your current password is required in order to update it",
                "new": "New Password",
                "new_help": "Password needs to be at least 8 characters",
                "repeat": "Repeat Password",
                "repeat_help": "After changing your password, you will be asked to log in again."
            },
            "save_button": "Change Password"
        },
        "settings": {
            "label": "Settings",
            "price_quantity": "Prices and Quantities",
            "price_quantity_help": "Use Bottle or Case prices throughout the portal",
            "save": "Save Settings",
            "saved_profile": "Your profile has been saved",
            "saved_profile_error": "Your profile could not be updated, please try again later",
            "saved_settings": "Your settings have been saved"
        }
    },
    "no": "No",
    "notifications": {
        "cart_api_error": "Sorry, there was an unexpected issue while updating your cart. Please try to reload the portal. We're already on it, but don't hesitate to contact support if the problem persists.",
        "comment_deleted": "Comment removed",
        "comment_stored": "Comment stored",
        "invite_failure": "Your invite code is expired or no longer valid.",
        "messroom_ready": "Your portal is ready to use!",
        "news": {
            "title": "News Update!"
        },
        "order_booked": "Your products in order ({{id}}) are reserved by us!",
        "order_confirmed": "Your order ({{id}}) is confirmed by us!",
        "order_failure": "An issue occured while loading order {{order}}; Please try again later",
        "order_issue": "There is a problem with one or more items in your order; please checkout the order warnings",
        "order_repeated": "Order lines from order {{order}} were copied to your cart",
        "promotion_redeemed": "Congratulations! You just redeemed your promotion!",
        "removed_product": "Product {{product}} has been removed from your shopping cart",
        "session_expired": "Your portal session has expired; sadly you need to login again"
    },
    "offer": {
        "arriving_soon": "Arriving soon",
        "best_deal": "Best Deal",
        "delivery_week_one": "{{count}} week",
        "delivery_week_other": "{{count}} weeks",
        "download_offer": "Download Offer",
        "expired": {
            "description": "Sadly, the personal offer you are viewing is not valid anymore. Checkout our stock page for other offers.",
            "title": "Offer Expired"
        },
        "latest_orders": "Your latest orders",
        "latest_orders_empty": "Your most recent sales orders will appear here",
        "loading_products": "Retrieving Products...",
        "my_favourites": "My Favourites",
        "my_favourites_add": "Add to My Favourites",
        "my_favourites_help": "Mark items as favourites using the heart icon. These products will appear here, and under the \"My Favourites\" menu item.",
        "my_favourites_remove": "Remove from Favorites",
        "my_favourites_tip": "My favourites - a selection of your favourite products",
        "name": "Promotion",
        "new_arrivals": "New arrivals",
        "new_arrivals_tip": "New arrivals - our newest products; these are either just in or will be arriving on the floor soon",
        "newest_arrivals": "Our newest arrivals",
        "no_personal": "No personal offers at the moment",
        "personal": "Your Personal Offers",
        "personal_not_found": "Sadly, this special offer is not available anymore; did you see our other offers?",
        "personal_valid": "A personal offer for you: '{{text}}'\n",
        "popup": {
            "continue": "To Dashboard",
            "show_offer": "Show Offer"
        },
        "show_all": "Show all",
        "special": "Special offers",
        "special_count": "Special offers",
        "special_tip": "Special offers - products with a special price, valid for a limited time only",
        "valid_until": "Valid until {{date}}",
        "view_offer": "View Offer"
    },
    "order": {
        "agree_t1": "Has products with customs status T1",
        "agree_tos": "I agree with the&nbsp;<a href={{link}} target=\"_blank\">terms and conditions</a>",
        "completion": "Order completion",
        "confirmed": {
            "description": "Now it's our turn; we'll make sure the goods will come to you. In the meantime, feel free to keep an eye on our stock for more great offers. Please don't hesitate to contact us, if you have any questions about your order.",
            "subtitle": "Your order has been placed successfully",
            "title": "Congratulations!"
        },
        "delivery": "Delivery Preference",
        "delivery_date": "Delivery Date",
        "details": {
            "actions": {
                "download_delivery_note": "Download Delivery Note",
                "download_invoice": "Download Invoice",
                "download_order_note": "Download Order Note",
                "download_proforma": "Download Proforma",
                "repeat_order": "To cart",
                "repeat_order_tip": "Copies the content of this order to your cart. This can only be done for products and quantities that are currently in stock."
            },
            "additional": {
                "description": "Description",
                "price_unit": "Price per unit",
                "quantity": "Quantity",
                "type": "Type"
            },
            "additionals": "Additional items and discounts",
            "booked": "Booked",
            "booked_context": "These items are confirmed and booked by you and therefore reserved by us.",
            "cart": "Cart",
            "cases": "Cases",
            "comment": "Comment",
            "created_by": "Created by",
            "credit": {
                "credited_from": "Credited From",
                "label": "Credit Items"
            },
            "date": "Date",
            "empty": "Your order doesn't have products yet",
            "empty_context": "Add products to your cart from our stock overview.",
            "invoice_date": "Invoice date",
            "invoice_number": "Invoice number",
            "items": {
                "arriving": "Arriving Soon",
                "basket_unconfirmed": "Cart items (unconfirmed)",
                "basket_unconfirmed_help": "These items still need confirmation from you before we can reserve them. Confirm your order to proceed.",
                "stock": "From Stock",
                "tbo": "To be ordered"
            },
            "order_number": "Order Number",
            "order_status": "Order Status",
            "price": "Price",
            "product": "Product",
            "quantity": "Quantity",
            "specs": "Specifications",
            "status": "Status",
            "subtotal": "Subtotal",
            "total_value": "Total Value",
            "vat": "VAT"
        },
        "empty_call_to_action": "Browse Stock",
        "loading_orders": "Loading Orders...",
        "new": "New",
        "tos": "Terms & conditions"
    },
    "orders": {
        "invoice_date": "Invoiced On",
        "invoice_number": "Invoice Number",
        "no_orders": "You have no orders yet",
        "portal_status": {
            "Cancelled": "Cancelled",
            "Confirmed": "Confirmed",
            "Finalized": "Finalized",
            "Invoiced": "Invoiced",
            "New": "New",
            "Pending": "Pending"
        },
        "status": "Order Status",
        "status_active": "Active",
        "status_open": "Open"
    },
    "page": {
        "browse_stock_tip": "Browse stock - overview of all our products",
        "dashboard": "Dashboard",
        "dashboard_tip": "Dashboard - an overview of our best deals & offers",
        "latest_news": "Latest news",
        "my_orders": "My orders",
        "my_orders_tip": "My orders - an overview of all your orders",
        "my_portal": "My portal",
        "my_portal_tip": "My portal - profile, news & promotions",
        "my_portal_tip_promotion": "My portal - There is an active promotion!",
        "subtitle": {
            "login": "Login to continue...",
            "privacy": "We sell spirits, not your data!",
            "redeem": "Register with your unique code...",
            "registration": "Please tell us about your company...",
            "registration_completed": "You may close this window now..."
        },
        "title": {
            "checkout": "Checkout",
            "checkout-0": "Shop for products",
            "checkout-1": "Verify cart",
            "checkout-2": "Finalize cart",
            "login": "Welcome to {{vendor}}!",
            "login_slug": "Login",
            "offer": {
                "my_favourites": "My Favourites",
                "new_arrivals": "New arrivals",
                "personal_offer": "Personal Offer - until {{date}}",
                "personal_offer_invalid": "Personal offer expired",
                "special_offers": "Special offers"
            },
            "order": "Order {{id}}",
            "privacy": "Our Privacy Policy",
            "redeem": "Nice to see you!",
            "registration": "Registration",
            "registration_completed": "Thank you for your application!"
        }
    },
    "product": {
        "alcohol_percentage": "Alcohol percentage",
        "best_before": "Best before",
        "bottle_gtin": "Bottle GTIN",
        "bottle_volume": "Bottle Volume",
        "bottles": "Bottles",
        "bottles_per_case": "Bottles per case",
        "cases": "Cases",
        "category": "Category",
        "country_of_origin": "Country of origin",
        "cpp_cpl_tip": "Pallet layout as provided by our warehouse",
        "customs_status": "Customs status",
        "features": "Features",
        "minimum_order_quantity": "Minimum order quantity",
        "refill_status": "Refillable",
        "stock_origin": "This product originates from {{country}}",
        "stock_origin_count_one": "We can supply {{count}} {{units}} to you from this origin",
        "stock_origin_count_other": "We can supply {{count}} {{units}} to you from this origin",
        "weight_tip": "Weight of the case"
    },
    "profile": {
        "company": "Your Company",
        "email_support": "Support email address",
        "family_name": "Family name",
        "first_name": "First name",
        "mobile_phone_number": "Mobile phone number",
        "new_password": "Choose a password",
        "new_password_help": "The new password you want to set",
        "new_password_placeholder": "Choose a password",
        "phone_email": "Phone number or e-mailaddress",
        "phone_number": "Phone number",
        "portal_welcome_desc": "Please login to continue...",
        "portal_welcome_title": "Welcome to our portal!",
        "register": "Register free account",
        "removal": {
            "ack": "Your request has been sent!",
            "ack_description_1": "Your request has been sent. We will come back to you as soon as possible, but at least within 4 weeks from now.",
            "ack_description_2": "We are going to miss you, so if you accidentally filed this request, please contact us at {{email_vendor}} or contact your personal support manager, {{manager}} at {{email}} or {{phone}}.",
            "cancel": "Cancel Request",
            "close_window": "Close Dialog",
            "confirm": "Confirm Request"
        },
        "removal_description_1": "When you proceed we will determine what to do with your deletion request as soon as possible, but at least within 4 weeks. We will inform you about the actions we will take to fulfill your request. If we fail to comply to your request, you can file a complaint with the Authority Personal Data.",
        "removal_description_2": "Before you confirm your deletion request, we would like to ask you who we may contact for your company in your place. Would you leave a name and telephone number or e-mailaddress for us? Please only do this if you have permission of the new contact person. It is not required to leave these contact details!",
        "removal_notice": "Please read this carefully!",
        "removal_questions": "For further information, questions or remarks please mail us at {{email_vendor}} or contact your personal support manager, {{manager}} at {{email}} or {{phone}}.",
        "removal_title": "We will miss you!",
        "replacement": {
            "contact": "Contact info",
            "name": "Contactperson"
        },
        "request_password": "Forgot password?",
        "request_removal": "Removal Request",
        "save_button": "Update Profile"
    },
    "promotions": {
        "active": "Active between: {{date}}",
        "active_grace": "Promotion can be redeemed until: {{date}}",
        "call_to_action": "Order now!",
        "confirmed": {
            "description": "Now it's our turn; we'll make sure the goods will come to you. In the meantime, feel free to keep an eye on our stock for more great offers. Please don't hesitate to contact us, if you have any questions about your order.",
            "subtitle": "Your promotion has been redeemed!"
        },
        "description": "We have yet another great promotion for you! Become a real barista by saving coffee beans with every {{token_value}} you spend. There are several coffee machines you can earn.",
        "details": "Click to view promotion details",
        "eligible_multiple": "One active bean represents {{token_value}} of sales order value that has been confirmed or invoiced by your sales manager. You collected {{turnover_draft}} of eligible sales order value, of which {{turnover_redeemable}} is already confirmed or invoiced.",
        "gifts": {
            "available": "Available Gifts",
            "choose": "Your Offer",
            "choose_help": "You can choose an offer after you've become eligible for the promotion.",
            "disabled": "You can choose a present",
            "locked": "Unlock your gift by adding {{amount}} to your order",
            "locked_placeholder": "Unlock your gift first...",
            "unlocked": "Congratulations! You unlocked a free gift!",
            "unlocked_choice_made": "{{choice}} - Good choice!",
            "unlocked_choose": "Select your preferred gift below...",
            "unlocked_placeholder": "Select your free gift...",
            "unlocked_redirect": "Congratulations! You unlocked a free gift! Checkout the <a href={{link}}>promotions section</a> for more information about the various gifts..."
        },
        "giveaway": "Order {{spent_next}} {{currency}} to earn a coffee bean!",
        "giveaway_encourage": "Order {{spent_next}} {{currency}} more and claim a coffee bean!",
        "incentive_hurry_one": "Only {{count}} day left!",
        "incentive_hurry_other": "Only {{count}} days left!",
        "incentive_progress": "Order {{amount}} more for a coffee bean!",
        "label": {
            "dialog_subtitle": "Ever wanted to be a barista? Now's your chance! Earn a coffee bean for every {{token_value}} you order and choose from one of three fabulous coffee machines! And to top it off, we throw in no less than 6 kg of organic Arabica coffee beans! Save your beans and make your own delicious Americano, Latte or Expresso!",
            "promotion": "Promotion",
            "redeemable_tokens": "Collected beans",
            "show_promotion": "Show promotion"
        },
        "order_reminder": "You are almost ready to become a barista! Once you have reached {{token_value}}, you can redeem your promotion.",
        "order_reminder_eligible": "You are just one step away from becoming a barista! We are reviewing your order and you will receive a confirmation email from us within 24 hours. Once you receive this email, you will be able to redeem your coffee beans. Thank you for your patience!",
        "redeem": "Redeem",
        "terms": {
            "conditions": "Terms and Conditions",
            "period": "Promotion period: {{period}}",
            "terms_0": "Promotion is valid for orders placed via our portal; we count all your finalized orders during this period.",
            "terms_1": "Once you have ordered and are eligible to receive a coffee bean, you can immediately order an espresso machine via the portal, which we will add to your order.",
            "terms_2": "The espresso machines offered are subject to stock availability. If the selected models are not available, we will select a comparable / equivalent model in consultation with you.",
            "terms_3": "We are not responsible for the information provided by the manufacturer on their website. This is purely for your information.",
            "terms_4": "The total amount, is the amount of ordered and paid for merchandise value per invoice. Additional invoiced costs do not count towards the promotion (shipping / trucking / pallets).",
            "terms_5": "Product warranty is only applicable in The Netherlands.",
            "terms_6": "An espresso machine cannot be exchanged for a cash discount.",
            "terms_7": "This promotion was crafted for you with care, however subject to typographical errors."
        }
    },
    "registration": {
        "address": "Address",
        "address_placeholder": "Company Address",
        "agree": "Yes, i agree with the&nbsp;<a href={{link}} target=\"_blank\">terms and conditions</a>",
        "back_login": "Back to login",
        "city": "City",
        "city_placeholder": "Company City",
        "company_details": "Company details",
        "company_name": "Company Name",
        "company_name_placeholder": "Your Company name",
        "consent": "Commercial e-mails and offers from {{vendor}}",
        "country": "Country",
        "country_help": "The country you do business in",
        "email_address": "Your Email Address",
        "email_address_help": "The email address we can reach you on",
        "explanation": "{{vendor}} supplies Business to Business customers throughout Europe and beyond. As a valued customer of {{vendor}}, you will have unlimited access to our Sales Portal. You can enjoy the benefits of 24/7 access to our extensive range of products we offer, whenever you like. Placing an order is easy: just browse, choose, click and you’re done!",
        "family_name": "Family Name",
        "family_name_placeholder": "Your Family Name",
        "first_name": "First Name",
        "first_name_placeholder": "Your First Name",
        "import_goods_temp_license": "I import goods with a temporary license",
        "invite": "Invites",
        "message": "Questions?",
        "message_placeholder": "Do you have any questions for us? Feel free to ask them...",
        "name": "Your Name",
        "position": "Your Company role",
        "position_placeholder": "Your Company role",
        "postal_code": "Postal Code",
        "postal_code_placeholder": "Company Postal Code",
        "redeem": {
            "code": "Invite code",
            "code_help": "Your unique code to access our portal",
            "partnership": {
                "footer": "With love for our planet",
                "header": "{{vendor}} is a proud partner of {{partner}}."
            },
            "step0_desc": "Are you just as excited about the spirits business as we are? Log in with your personal code to claim your gift and to get access to our extended, varied and sharply priced assortment.",
            "step0_slug": "Together we make the world a better place",
            "step1_desc": "Fill in the email address you want to use, choose a password and let's begin!",
            "step1_slug": "About You",
            "step2_desc": "Is the following information correct? Then press the \"Register & Login\" button and you will automatically be situated in the portal.",
            "step2_slug": "Review Registration",
            "step3_slug": "Register & Login"
        },
        "review_request": "Please review all the information you provided. If everything is correct you can submit your registration and we will get back to you shortly.",
        "signup": "Become a Customer",
        "step0_desc": "{{vendor}} supplies to Business to Business customers. For which company do you want to request an account?",
        "step0_slug": "Your Company",
        "step1_desc": "Please fill in the address of your company.",
        "step1_slug": "Company Address",
        "step2_desc": "How can we contact you?",
        "step2_slug": "Contact Details",
        "step3_desc": "Do you use the facilities of a bonded warehouse in the EU?",
        "step3_desc_vat": "Please provide your VAT number and warehousing preferences.",
        "step3_slug": "Logistic Details",
        "step4_desc": "Please review the information you're about to send to us for your registration request. You can use the questions field below, in case you have any questions for us.",
        "step4_slug": "Review Registration",
        "step5_desc": "We will verify the company details you provided. This process may take a few days. In case we need any additional information, we will contact you personally. After confirmation, you will receive an email containing an acknowledgment of your registration.",
        "step5_footer": "We are looking forward to receive your first online order soon!",
        "step5_incentive": "Did you know we have our own <a target=\"_blank\" href=\"https://www.youtube.com/@movingspirits474/videos\">Youtube channel</a>?",
        "step5_notify": "Thank you for your application! We will process your application within a few working days.",
        "step5_slug": "Submit request",
        "step5_title": "What happens next?",
        "submit": "Submit Registration",
        "telephone": "Your Phone number",
        "telephone_placeholder": "+31 502110909",
        "terms_conditions": "General terms and conditions",
        "vat_number": "VAT number",
        "vat_number_help": "VAT number is required in EU countries",
        "vat_number_placeholder": "VAT number",
        "warehouse": {
            "address": "Warehouse Address",
            "address_help": "Address of your warehouse",
            "address_unloading": "Unloading Address",
            "address_unloading_help": "The place where we can unload your order",
            "bonded_eu": "Bonded warehouse in the EU?",
            "city": "Warehouse City",
            "city_unloading": "Unloading City",
            "excise_id": "Warehouse Excise ID",
            "location_id": "Warehouse Location ID",
            "name": "Warehouse Name",
            "name_unloading": "Unloading",
            "options": {
                "bonded": "I have my own bonded warehouse",
                "inc_vat": "I purchase including VAT and Excise from the Netherlands",
                "other": "Other",
                "temp_license": "I import goods with a temporary license",
                "third_party": "I use a third party warehouse/warehouse partner"
            },
            "postal_code": "Warehouse Postal Code",
            "postal_code_unloading": "Unloading Postal Code",
            "select": "Select Warehouse",
            "shipping": {
                "free_shipping": "Well done! We will deliver your order free of charge.",
                "incentive": "Add <b>{{amount}}</b> to your order for free of charge delivery. For orders below <b>{{threshold}}</b>, additional transport costs will be added to your proforma invoice for you to confirm."
            },
            "warehousing": "Warehousing"
        },
        "website": "Company Website",
        "website_placeholder": "your-company.com"
    },
    "search": {
        "categories": {
            "category": "category",
            "search": "product name"
        },
        "exact": "Search by product name or GTIN using term \"{{term}}\".",
        "placeholder": {
            "general": "What are you looking for?",
            "raw": "Search by product name or GTIN",
            "raw_term": "Search by product name or GTIN using term \"{{term}}\".",
            "suggestion": "Search by {{type}} \"{{value}}\""
        },
        "suggestions": {
            "activate": "<Ctrl><Enter> for exact term search"
        }
    },
    "select": "Select",
    "spotlight": {
        "countdown": {
            "still_unsold": "Still unsold",
            "valid_for": "Offer still valid:"
        }
    },
    "status": {
        "error": {
            "redeem": {
                "invalid": "Too bad, this code is not correct; try again.",
                "length": "Redeem code is 6 characters",
                "unknown": "Something went wrong while checking your redeem code; please try again later.",
                "used": "This invite code has already been used to create an account."
            }
        }
    },
    "view": {
        "grid": "Grid",
        "grid_switch": "Switch to grid view",
        "list": "List",
        "list_switch": "Switch to list view"
    },
    "yes": "Yes"
}