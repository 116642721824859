import m from 'mithril'
import {Icon} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {classes} from '@bitstillery/common/lib/utils'
import {$m, $s, $t, api} from '@bitstillery/common/app'

interface ProductImageAttrs {
    ribbon?: boolean // show the offer ribbon
    offer_item: any
    onclick: (any) => any
}

export class ProductImage extends MithrilTsxComponent<ProductImageAttrs> {

    view(vnode: m.Vnode<ProductImageAttrs>) {
        const offer_item = vnode.attrs.offer_item
        const offer_item_price = $m.offer.offer_item_price(offer_item)
        const image_location = $m.offer.primary_photo(vnode.attrs.offer_item?.artkey)

        return <div
            className={classes('c-product-image', {
                'carousel-active': offer_item.artkey in $s.product_photos && $s.product_photos[offer_item.artkey].length,
            })}
            onclick={(e) => {
                vnode.attrs.onclick(e)
            }}
        >
            {image_location ? <img src={image_location}/> : <Icon
                className="image-placeholder"
                name="bottlePlaceholder"
                type="unset"
            />}

            <Icon
                className={classes('c-favourites', {
                    active: offer_item.favourite_artkey,
                })}
                name={offer_item.favourite_artkey ? 'favorite' : 'favorite-o'}
                onclick={async(e) => {
                    e.stopPropagation()
                    if (offer_item.favourite_artkey) {
                        await api.post('favourite.delete', {offer_item_artkey: offer_item.artkey})
                        offer_item.favourite_artkey = null
                    } else {
                        const {result} = await api.post('favourite.create', {offer_item_artkey: offer_item.artkey}) as any
                        offer_item.favourite_artkey = result.favourite_artkey
                    }

                }}
                tip={() => {
                    if (vnode.attrs.offer_item.favourite_artkey) return $t('offer.my_favourites_remove')
                    return $t('offer.my_favourites_add')
                }}
                type="brand"
            />

            {offer_item_price.has_discount && <div className={classes('c-offer-price-ribbon', offer_item.offer_type)}>
                {offer_item.offer_type === 'spotlight' && $t('offer.best_deal')}
                {offer_item.offer_type === 'offer' && $t('offer.name')}
            </div>}
        </div>
    }
}
