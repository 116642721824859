import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Button, FieldCode, FieldText} from '@bitstillery/common/components'
import {and, email, required, validation} from '@bitstillery/common/lib/validation'
import {proxy} from '@bitstillery/common/lib/proxy'
import {$s, $t} from '@bitstillery/common/app'

import QR from './qr'

export class AuthLogin extends MithrilTsxComponent<any> {

    data = proxy({
        $m: {
            password: '',
            email_address: '',
            email_support: '',
            otp_code: '',
        },
        otp_fail: null,
        // One-time token confirmation
        otp_secret: '',
        pre_auth_token: '',
        stepper: {
            options: [
                {slug: 1, title: $t('landing.login.step0_slug')},
                {slug: 2, title: $t('landing.login.step1_slug')},
                {slug: 3, title: $t('landing.login.step2_slug')},
            ],
            selection: 0,
        },
        support_mode: !!m.route.param('support'),
    })

    $v = {
        email_address: validation([this.data.$m, 'email_address'], and([required(), email()])),
        otp_code: validation([this.data.$m, 'otp_code'], {
            validate: (modelValue) => {
                if (modelValue.length < 6) {
                    this.message = $t('status.error.otp.length')
                    return this
                } else if (this.data.otp_fail) {
                    this.message = $t('status.error.otp.invalid')
                    return this
                }
                return false
            },
            label: '*',
            message: '',
        }),
        password: validation([this.data.$m, 'password'], required()),
    }

    is_invalid() {
        if (!this.validationGroup[this.data.stepper.selection]) return false
        const validation_fields = this.validationGroup[this.data.stepper.selection]
        const invalid = validation_fields.map((i) => this.$v[i]).filter((i) => i && i._invalid)
        return !!invalid.length
    }

    validationGroup = {
        0: ['email_address', 'password'],
        1: ['otp_code'],
    }

    redirect_to = m.route.param('redirect')

    oninit() {
        $s.page.title = $t('landing.login.title', {vendor: process.env.MSI_TITLE})
        $s.page.subtitle = $t('landing.login.subtitle')
    }

    view() {
        return (
            <div className="c-auth-login landing">
                <div className="form stepper-form" onkeypress={(e: KeyboardEvent) => {
                    if (e.key === 'Enter' && !this.is_invalid()) {
                        this.login()
                    }
                }}>
                    {this.data.stepper.selection === 0 && <div className="step step-unknown step-0">
                        <h3>{$t('landing.login.step0_slug')}</h3>
                        <p className="step-info">
                            {$t('landing.login.step0_desc', {vendor: process.env.MSI_TITLE})}
                        </p>
                        {process.env.MSI_PACKAGE === 'portal' && this.data.support_mode && (
                            <FieldText
                                label={$t('profile.email_support')}
                                model={[this.data.$m, 'email_support']}
                                placeholder={$t('profile.email')}
                            />
                        )}

                        <FieldText
                            autofocus={true}
                            help={$t('profile.email_help')}
                            label={$t('profile.email')}
                            model={[this.data.$m, 'email_address']}
                            placeholder={$t('profile.email_placeholder')}
                            validation={this.$v.email_address}
                        />

                        <FieldText
                            help={$t('profile.password_help', {vendor: process.env.MSI_TITLE})}
                            label={$t('profile.password')}
                            model={[this.data.$m, 'password']}
                            placeholder={$t('profile.password_placeholder')}
                            type="password"
                            validation={this.$v.password}
                        />

                        <div className="forgot-pw">
                            <Button
                                variant="link"
                                link={'/user/forgot-password'}
                                text={$t('profile.forgot_pw')}
                                type="info"
                            />
                        </div>

                        <Button
                            className="btn-login btn-submit"
                            disabled={this.is_invalid()}
                            onclick={() => {
                                this.login()
                            }}
                            text={$t('profile.login')}
                            type="success"
                        />
                    </div>}

                    {this.data.stepper.selection === 1 && <div className="step step-1">
                        <h3>{$t('landing.login.step1_slug')}</h3>
                        <p className="step-info">{$t('landing.login.step1_desc', {vendor: process.env.MSI_TITLE})}</p>

                        <div className="otp-options">
                            <div className="field-readonly-group fl-column">
                                <div className="field-readonly">
                                    <div className="key">Google Authenticator</div>
                                    <div className="value">
                                        <Button
                                            icon="android"
                                            link="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                                            size="l"
                                            type="info"
                                            variant="icon"
                                        />
                                        <Button
                                            icon="apple"
                                            link="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8"
                                            size="l"
                                            type="info"
                                            variant="icon"
                                        />
                                    </div>
                                </div>

                                <div className="field-readonly">
                                    <div className="key">FreeOTP</div>
                                    <div className="value">
                                        <Button
                                            icon="android"
                                            link="https://play.google.com/store/apps/details?id=org.fedorahosted.freeotp"
                                            size="l"
                                            type="info"
                                            variant="icon"
                                        />
                                        <Button
                                            icon="apple"
                                            link="https://itunes.apple.com/us/app/freeotp-authenticator/id872559395?mt=8"
                                            size="l"
                                            type="info"
                                            variant="icon"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="code">
                                <QR url={this.data.otp_secret}/>
                            </div>
                        </div>

                        <FieldCode
                            characters={/^[0-9]$/}
                            help={$t('landing.login.otp_code_help')}
                            label={$t('landing.login.otp_code')}
                            model={[this.data.$m, 'otp_code']}
                            onchange={() => {
                                this.login()
                            }}
                            placeholders={6}
                            validation={this.$v.otp_code}
                        />
                    </div>}

                    {this.data.stepper.selection === 2 && <div className="step-2">
                        <div className="otp-required">
                            <h3>{$t('landing.login.step2_slug')}</h3>
                            <p className="step-info">{$t('landing.login.step2_desc')}</p>

                            <FieldCode
                                autofocus={true}
                                characters={/^[0-9]$/}
                                help={$t('landing.login.otp_code_help')}
                                label={$t('landing.login.otp_code')}
                                model={[this.data.$m, 'otp_code']}
                                onchange={() => {
                                    this.login()
                                }}
                                placeholders={6}
                                validation={this.$v.otp_code}
                            />
                        </div>
                    </div>}
                </div>
            </div>
        )
    }
}
