import m from 'mithril'
import {proxy} from '@bitstillery/common/lib/proxy'
import {Button, Icon, Dialog} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {logger} from '@bitstillery/common/app'

export interface VersionCheckerAttrs {
    title: string
    reloadButton: string
}

export class VersionChecker extends MithrilTsxComponent<VersionCheckerAttrs> {

    static timeout = 60000
    checkIntervalHandle: number
    countIntervalHandle: number | null = null

    data = proxy({
        modal: {
            visible: false,
        },
        reload: {
            count: 60,
        },
        version: {
            current: process.env.MSI_VERSION as string,
            isNew: false,
            latest: process.env.MSI_VERSION as string,
        },
    })

    async oncreate() {
        if (process.env.MSI_TEST_CHECK_VERSION || ['staging', 'production'].includes(process.env.NODE_ENV)) {
            this.checkIntervalHandle = window.setInterval(async() => await this.check_version(), VersionChecker.timeout)
            this.data.version.current = await this.fetch_current_version()
            this.data.version.latest = this.data.version.current
        }
    }

    async check_version() {
        try {
            this.data.version.latest = await this.fetch_current_version()

            if (this.data.version.current !== this.data.version.latest) {
                if (!this.data.version.isNew) {
                    this.start_reload_count()
                }
                this.data.version.isNew = true
            }
        } catch (error) {
            logger.error(error)
        }
    }

    async fetch_current_version(): Promise<string> {
        const request = new Request('version.json', {
            cache: 'no-cache',
            headers: {
                Accept: 'application/json, text/*',
                'Accept-Encoding': 'gzip, deflate, br',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            method: 'GET',
        })

        let response

        try {
            response = await fetch(request, {})
            if (response.status >= 200 && response.status < 300) {
                return await response.json()
            }
            // response status 0: fetch was interrupted (browser slept, user navigated, firewall, plugins, ...)
            return Promise.resolve(this.data.version.latest)
        } catch (error) {
            // Swallow exceptions. If there is a problem with this GET, there are other issues than reloading the site.
            return Promise.resolve(this.data.version.latest)
        }
    }

    start_reload_count() {
        this.data.modal.visible = true
        this.countIntervalHandle = window.setInterval(() => {
            this.data.reload.count -= 1
            if (this.data.reload.count === 0 && this.countIntervalHandle) {
                clearInterval(this.countIntervalHandle)
                window.location.reload()
            }
        }, 1000)
    }

    view(vn: m.Vnode<VersionCheckerAttrs>): m.Children {
        return (
            <div className="c-version-checker">
                {this.data.version.isNew && this.data.modal.visible &&
                <Dialog
                    title={`${vn.attrs.title} (${this.data.version.current} => ${this.data.version.latest})`}
                >
                    <div className="info-box">
                        <div className="icon-construction">
                            <Icon name="party" className="icon-xl"/>
                        </div>
                        <div class="text">
                            {vn.children}
                            {'This page will automatically reload in '}<b>{`${this.data.reload.count}`}</b> {' seconds. '}
                        </div>
                    </div>

                    <Button
                        icon="repeat"
                        className="btn-confirm"
                        onclick={() => window.location.reload()}
                        text={vn.attrs.reloadButton}
                        type="info"
                    />
                </Dialog>
                }
            </div>
        )
    }
}
