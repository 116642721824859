import m from 'mithril'
import {classes, unique_id} from '@bitstillery/common/lib/utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {tip} from '@bitstillery/common/components'

import {Icon} from '../icon/icon'
import {Spinner} from '../spinner/spinner'

export interface ButtonAttrs {
    active?: boolean
    active_exact?: boolean
    className?: string
    disabled?: boolean

    id?: string
    icon?: string
    loading?: boolean
    link?: string
    onclick: (...args) => void
    size?: string
    target?: string
    type?: string
    tip?: string | {(): string}
    tip_config?: {}
    text?: string
    variant?: 'action' | 'link' | 'toggle'
}

export class Button extends MithrilTsxComponent<ButtonAttrs> {

    id = unique_id(8, false)
    tippy:any

    elements(vn: m.Vnode<ButtonAttrs>) {
        const elements: m.Children[] = []
        if (vn.attrs.icon && vn.attrs.loading) elements.push(m(Spinner))
        if (vn.attrs.icon && !vn.attrs.loading) {
            elements.push(<Icon size={vn.attrs.size} name={vn.attrs.icon} type="vn.attrs.type" />)
        }
        if (vn.attrs.text && vn.attrs.variant !== 'toggle') {
            elements.push(m('span', vn.attrs.text))
        }

        if (vn.children) elements.push(vn.children)
        return elements
    }

    onremove(vn: m.Vnode<ButtonAttrs>) {
        if (vn.attrs.tip && this.tippy) {
            this.tippy.destroy()
        }
    }

    oncreate(vn: m.Vnode<ButtonAttrs>) {
        if (vn.attrs.tip) {
            let selector
            if (vn.attrs.variant === 'link') {
                selector = document.querySelector(`#tip-${this.id}`)
            } else {
                selector = document.querySelector(`#c-button-${this.id}`)
            }

            const tip_config:any = {
                allowHTML: true,
                animation: 'scale',
                appendTo: document.body,
                content: this.update_tip(vn),
                delay: 500,
                inertia: true,
                interactive: true,
                onTrigger: (instance) => {
                    instance.setProps({content: this.update_tip(vn)})
                },
                touch: 'hold',
                zIndex: 10000000000000000,
            }

            if (vn.attrs.tip_config) {
                Object.assign(tip_config, vn.attrs.tip_config)
            }
            this.tippy = tip(selector, tip_config)
        }
    }

    update_tip(vn: m.Vnode<ButtonAttrs>) {
        if (typeof vn.attrs.tip === 'function') {
            return vn.attrs.tip()
        } else {
            return vn.attrs.tip
        }
    }

    view(vn:m.Vnode<ButtonAttrs>) {
        const attrs:any = {
            className: classes('c-button', vn.attrs.className,
                `type-${vn.attrs.type ? vn.attrs.type : 'default'}`,
                `size-${vn.attrs.size ? vn.attrs.size : 'd'}`,
                `variant-${vn.attrs.variant ? vn.attrs.variant : 'action'}`, {
                    active: (() => {
                        if (typeof vn.attrs.active === 'boolean') {
                            return vn.attrs.active
                        }
                        if (vn.attrs.link) {
                            if (vn.attrs.active_exact) {
                                return m.route.get() === vn.attrs.link
                            }
                            return m.route.get().startsWith(vn.attrs.link)
                        }
                    })(),
                    disabled: vn.attrs.disabled,
                },
            ),
            id: `c-button-${vn.attrs.id ?? this.id}`,
            onclick: (...args) => {
                if (vn.attrs.disabled) {
                    args[0].preventDefault()
                }

                if (vn.attrs.onclick && !vn.attrs.disabled) {
                    vn.attrs.onclick(...args)
                }
            },
            tabindex: vn.attrs.tabindex ? vn.attrs.tabindex : undefined,
        }

        if (vn.attrs.key) {
            attrs.key = vn.attrs.key
        }
        if (vn.attrs.link) {
            if (vn.attrs.link.startsWith('mailto:') || vn.attrs.link.startsWith('tel:') || vn.attrs.link.startsWith('http')) {
                attrs.href = vn.attrs.link
                attrs.target = '_blank'
            } else {
                if (vn.attrs.target) {
                    attrs.target = vn.attrs.target
                }
                attrs.href = `/#!${vn.attrs.link}`
            }
        }
        const elements = [
            m(vn.attrs.link ? 'a' : 'button', attrs, this.elements(vn)),
        ]

        if (vn.attrs.variant === 'link' && vn.attrs.tip) {
            elements.push(
                <Icon
                    className="tip"
                    id={`tip-${this.id}`}
                    name="info"
                    size="s"
                    type="info"
                />,
            )
        }

        return elements
    }
}
