{
    "collection": {
        "bulk": {
            "tip": {
                "cancel": "Annuler l'édition en bloc",
                "cancel_changes": "Annuler l'édition en bloc (annuler toutes les modifications non persistantes)",
                "persist_one": "Persister l'élément en vrac modifié",
                "persist_other": "Persiste {{count}} éléments en vrac modifiés",
                "toggle": "Basculer le mode d'édition en vrac"
            }
        },
        "exclude_label": "Exclure",
        "exclude_one": "Exclut l'article {{count}} ",
        "exclude_other": "Exclut les articles {{count}} ",
        "exclude_tip": "Exclure des articles ; {{count}} articles actuellement inclus",
        "include_label": "Inclure",
        "include_one": "Inclure {{count}} item",
        "include_other": "Inclure les articles {{count}} ",
        "include_tip": "Inclure des éléments ; {{count}} éléments déjà inclus",
        "modify_selection": "Modifier la sélection",
        "no_results": "Aucun résultat trouvé",
        "no_results_clear_filters": "As-tu essayé d'effacer les filtres actifs ?"
    },
    "contact": {},
    "data_card": {
        "text": "Carte de données",
        "tip": "Carte de données à bascule"
    },
    "dialog": {
        "cancel": "Annuler"
    },
    "filters": {
        "actions": {
            "clear_filter_selection": "Filtre transparent : {{name}}/{{selection}}",
            "deactivate_filter": "Désactive le filtre",
            "pricelist": "Liste de prix"
        },
        "clear": "Effacer les filtres",
        "collapse": "Réduire les filtres",
        "collapse_tip": "Réduire tous les filtres",
        "expand": "Développer les filtres",
        "expand_tip": "Développer tous les filtres",
        "inactive_filter": "Le filtre est inactif",
        "no_results": "Pas de produits",
        "no_results_hint": {
            "filters": "Modifie tes filtres actifs ?",
            "search": "Modifie tes termes de recherche ?"
        },
        "no_selection": "Aucun élément sélectionné",
        "panel_title": "Filtres",
        "personal_offer": "Personnel",
        "reset_disabled": "Active les filtres pour utiliser cette option",
        "reset_one": "Réinitialiser le filtre",
        "reset_other": "Réinitialiser les filtres de {{count}} ",
        "sort": {
            "direction": "Ordre de tri",
            "direction_tip": "Modifier l'ordre de tri",
            "name": "Trier",
            "options": "Options de tri",
            "reset": "Réinitialiser le tri",
            "types": {
                "avg_purchase_price": "Prix d'achat moyen",
                "created_by": "Créé par",
                "created_on": "Créé le",
                "delivery_period": "Délai de livraison",
                "entry_date": "Date d'entrée",
                "invoice_number": "Numéro de facture",
                "is_invoiced_on": "Date de la facture",
                "item_stock_age": "Âge du stock",
                "item_throughput": "Débit",
                "last_price_change_age": "Dernier changement de prix",
                "list_price": "Prix de vente",
                "list_quantity": "Quantité",
                "lowest_price_competitors": "Le prix le plus bas de la concurrence",
                "margin_percentage": "Marge",
                "market_score": "Rang",
                "number_of_cases": "Quantité",
                "number_of_cases_available": "Disponibilité",
                "portal_status": "Statut",
                "price_age_in_days": "Âge du prix (jours)",
                "price_per_case": "Prix par carton",
                "product_name": "Nom du produit",
                "reference": "Référence",
                "stock_age": "Âge du stock",
                "total_value": "Valeur totale"
            }
        },
        "title": {
            "cases_available": "Nombre de cas disponibles",
            "category": "Catégorie",
            "customs_status": "Statut douanier",
            "flags": "Propriétés de l'article",
            "item_type": "Type d'article",
            "offer_hash": "Offres personnelles",
            "price_age": "Prix âge",
            "properties": "Propriétés",
            "purchase_history_selection_overall": "Historique des achats en général",
            "purchase_history_selection_supplier": "Historique des achats fournisseur",
            "relation": "Relation",
            "relation_type": "Type de relation",
            "sales_history_selection": "Historique des ventes",
            "stock_age_range": "Âge du stock",
            "stock_availability_selection": "Stock",
            "suppressed_selection": "Sélection supprimée"
        },
        "types": {
            "category": "Catégories",
            "packaging": {
                "+ Mug": "+ Tasse",
                "2 Glasses": "2 verres",
                "2 Golden Glasses": "2 verres d'or",
                "Aluminium Bottle": "Bouteille en aluminium",
                "Bottle": "Bouteille",
                "Can": "Peut",
                "Cannister": "Cannister",
                "Cradle": "Berceau",
                "Flask": "Flacon",
                "Frosted Bottle": "Bouteille givrée",
                "GB + Jar of Cherries": "GB + pot de cerises",
                "GB with Cradle": "GB avec berceau",
                "Giftbag": "Sac cadeau",
                "Giftbox": "Boîte cadeau",
                "Gifttin": "Boîte cadeau",
                "Leather GB": "Cuir GB",
                "PET bottle": "Bouteille en PET",
                "Pack": "Emballer",
                "Porcelain Bottle": "Bouteille en porcelaine",
                "Slightly dirty outercases": "Des boîtiers extérieurs légèrement sales",
                "Square Bottle": "Bouteille carrée",
                "Wooden Giftbox": "Boîte cadeau en bois"
            },
            "products": "Produits"
        },
        "units": {
            "%": "%",
            "cl": "cl",
            "cs": "cs",
            "day_one": "jour",
            "day_other": "jours"
        }
    },
    "forgot_password": {
        "confirmed": "L'e-mail est en cours d'acheminement. Vérifie ta boîte de réception (et ton dossier spam) pour l'email contenant le lien de réinitialisation du mot de passe.",
        "description": "Tu as oublié ton mot de passe ? Saisis ton adresse email ci-dessous, clique sur le bouton et un email te sera envoyé qui te permettra de réinitialiser ton mot de passe.",
        "email": "Ton adresse électronique",
        "email_help": "L'adresse électronique que tu utilises pour te connecter",
        "start_flow": "Demande l'email de réinitialisation",
        "title": "Mot de passe oublié"
    },
    "issues": {
        "button": {
            "label": {
                "select_image": "Sélectionne l'image",
                "submit_issue": "Soumettre un problème",
                "take_snapshot": "Prends un instantané"
            }
        },
        "field": {
            "help": {
                "description": "Une description concise et complète du problème",
                "media": "Une capture d'écran ou une vidéo montrant le problème",
                "title": "Quatre ou cinq mots résumant la question",
                "url": "URL de la page du problème"
            },
            "label": {
                "description": "Description",
                "media": "Les médias",
                "title": "Titre",
                "url": "URL"
            },
            "placeholder": {
                "description": "Une description concise et complète du problème, afin que nous puissions l'identifier mieux et plus rapidement.",
                "media": "Ctrl-v pour coller une image",
                "title": "Quatre ou cinq mots résumant la question",
                "url": "URL de la page du problème"
            }
        },
        "label": {
            "title": "Signaler un problème",
            "title_submitted": "Merci pour ton rapport de bogue !"
        },
        "support": {
            "help": {
                "template": "Le modèle qui est utilisé pour les nouveaux problèmes Gitlab."
            },
            "label": {
                "button_submit_issue": "Sauvegarder les paramètres",
                "template": "Modèle"
            },
            "placeholder": {
                "template": "Il contient un modèle clair pour des rapports de bogues optimaux."
            }
        },
        "text": {
            "submitted": "Merci d'avoir soumis ton rapport de problème ! Tu peux vérifier le statut de ton problème dans notre <a href={{link}} target='_blank'>issue tracker</a>. N'hésite pas à ajouter des commentaires connexes dans le problème ; soit pour fournir un contexte supplémentaire, soit simplement si tu souhaites obtenir des éclaircissements supplémentaires. Nous améliorons Discover & Portal étape par étape, grâce à ton aide !"
        }
    },
    "landing": {
        "force_logout": "Désolé, tu dois te connecter à nouveau...",
        "login": {
            "otp_code": "Jeton de sécurité",
            "otp_code_help": "Saisis ici le code généré par l'appli",
            "step0_desc": "Utilise tes identifiants {{vendor}} pour accéder au site. Si tu as perdu ton mot de passe, tu peux indiquer ton adresse e-mail ci-dessous et cliquer sur le lien \"Mot de passe oublié\".",
            "step0_slug": "Se connecter à notre portail",
            "step1_desc": "Une application d'authentification supplémentaire est nécessaire pour protéger ton compte d'un accès non autorisé. Installe l'une de ces suggestions d'applications d'authentification sur ton téléphone portable et scanne le code QR. Utilise le jeton de ton téléphone pour enregistrer ton appareil et te connecter. Réajoute le jeton dans ton application si quelque chose ne va pas pendant le processus. Toujours pas de chance ? Contacte-nous pour obtenir de l'aide.",
            "step1_slug": "Sécurité supplémentaire",
            "step2_desc": "Un jeton de sécurité supplémentaire est nécessaire. Vérifie que le jeton se trouve dans ton application d'authentification. Tu n'as plus accès à ton application d'authentification ? Contacte-nous pour obtenir de l'aide.",
            "step2_slug": "Jeton de sécurité requis",
            "subtitle": "Connecte-toi pour continuer...",
            "title": "Bienvenue sur {{vendor}}!"
        }
    },
    "languages": {
        "de": "Allemand",
        "en_gb": "Anglais",
        "es": "Espagnol",
        "fr": "Français",
        "it": "Italien",
        "nl": "Néerlandais"
    },
    "my_portal": {
        "label": "Mon portail",
        "logout": "Déconnexion",
        "settings": {
            "language": "Langue",
            "language_help": "Généré par l'IA ; peut contenir une utilisation incorrecte de la langue."
        },
        "tip": "Mon portail - Nouvelles, profil et paramètres"
    },
    "page": {
        "subtitle": {
            "forgot_password": "Réinitialiser ton mot de passe",
            "reset_password": "Vérifie bien ton nouveau mot de passe..."
        },
        "title": {
            "forgot_password": "Mot de passe oublié",
            "login": "Bienvenue sur {{vendor}}!",
            "login_slug": "Connexion",
            "registration": "Inscription",
            "reset_password": "Réinitialiser le mot de passe"
        }
    },
    "password_reset": {
        "confirmed": "Ton mot de passe a été réinitialisé",
        "description": {
            "new-account": "Après avoir défini ton mot de passe, tu seras invité à te connecter.",
            "reset": "Après avoir réinitialisé ton mot de passe, il te sera demandé de te connecter à nouveau."
        },
        "email": "Ton adresse électronique",
        "email_help": "L'adresse électronique que tu utilises pour le portail",
        "failed": "La demande de modification de ton mot de passe a échoué : \"{{reason}}\"",
        "new_password": "Nouveau mot de passe",
        "new_password_help": "Ton mot de passe préféré ; vérifie avec l'œil si ton mot de passe est correct.",
        "new_password_repeat": "Répéter le mot de passe",
        "new_password_repeat_help": "Répète ton mot de passe",
        "reset_button": "Mise à jour du mot de passe",
        "title": {
            "new-account": "Créer un mot de passe",
            "reset": "Mise à jour du mot de passe"
        }
    },
    "price": {
        "offer_until_one": "Dernier jour de l'offre !",
        "offer_until_other": "Valable pour une autre période de {{count}} jours",
        "price_down": "Récemment en réduction à partir de {{price}} {{currency}}"
    },
    "product": {
        "availability": {
            "eta": "Arrivée ~ {{period}}",
            "stock": "En stock",
            "tbo": "À commander"
        }
    },
    "profile": {
        "email": "Ton adresse électronique",
        "email_help": "Ton adresse e-mail pour te connecter",
        "email_placeholder": "you@yourbusiness.com",
        "forgot_pw": "Tu as oublié ton mot de passe ?",
        "login": "Se connecter",
        "password": "Ton mot de passe",
        "password_help": "Le mot de passe pour {{vendor}}",
        "password_placeholder": "Psst, c'est un secret !"
    },
    "rating": {
        "rate": {
            "description": "Tes commentaires nous aideront à améliorer le service que nous te proposons",
            "placeholder": "N'hésite pas à nous faire part de tes suggestions pour améliorer la façon dont tu commandes.",
            "sent": "Merci beaucoup pour vos commentaires !",
            "title": "Que penses-tu de notre processus de commande ?"
        },
        "send.title": "Envoyer",
        "thanks": {
            "description": "Nous faisons de notre mieux pour que notre service soit le meilleur possible. Si tu as d'autres questions ou suggestions, contacte ton responsable d'assistance.",
            "notification": "Merci beaucoup pour vos commentaires !",
            "title": "Merci au nom de {{team}}!"
        }
    },
    "sales_order_addition_types": {
        "discount": "Remise",
        "other": "Autres",
        "pallet": "palette",
        "revenue": "Revenus",
        "transport": "transport",
        "voucher": "Bon",
        "waste_fund": "Fonds de déchets"
    },
    "settings": {
        "beta": {
            "active": "Mode bêta activé",
            "inactive": "Mode bêta désactivé"
        }
    },
    "status": {
        "error": {
            "login": {
                "credentials_invalid": "Il y a un problème avec tes identifiants de connexion ! Vérifie ton adresse e-mail et ton mot de passe."
            },
            "message": "Une erreur inattendue s'est produite ; essaie à nouveau plus tard (référence : {{message}})",
            "order": {
                "delivery_invalid": "La condition de livraison n'est pas remplie"
            },
            "otp": {
                "invalid": "Le jeton d'authentification n'est pas valide ; vérifie ton appli d'authentification.",
                "invalid_notify": "Ton jeton d'authentification n'est pas valide. Vérifie dans ton appli que le code est correct. Lorsque le problème persiste, vérifie si les paramètres de date et d'heure de ton appareil sont exacts.",
                "length": "Le jeton d'authentification est composé de 6 algorithmes de chiffrement."
            },
            "unknown": "Nous sommes désolés ! Une erreur inattendue s'est produite. Nous anticipons activement ce type de problèmes. Essaie à nouveau plus tard pour voir si le problème a été résolu. Contacte-nous si le problème persiste plus longtemps. Nous te remercions de ta patience."
        },
        "success": {
            "login": {
                "forgot_pw": "Un courriel contenant les instructions pour réinitialiser ton mot de passe est envoyé à {{email}} s'il s'agit d'une adresse électronique valide. Pas de courriel ? Vérifie l'adresse électronique et consulte ton dossier de courrier indésirable. Contacte-nous si tu n'as toujours pas reçu d'email."
            },
            "redeem": {
                "verified": "Félicitations ! Tu as utilisé avec succès le code pour : {{name}}!"
            }
        }
    },
    "support": {
        "contact": {
            "collapse": "Basculer les détails de l'assistance",
            "email": "Courriel de contact : {{contact}}",
            "mobile": "Contact mobile : {{contact}}",
            "phone": "Téléphone de contact : {{contact}}",
            "support_manager": "responsable de l'assistance",
            "team_etr": "Équipe A2BC",
            "team_msp": "L'équipe Moving Spirits",
            "tech_support": "Tech Soutien"
        },
        "roles": {
            "default": "Responsable du soutien",
            "sales_nl": "Trader Pays-Bas",
            "tech": "Support technique"
        }
    },
    "system": {
        "incompatible": "Ton navigateur ne répond pas à la configuration minimale requise ; mets d'abord à jour ton navigateur ({{code}}).",
        "reload_portal": "Portail de rechargement",
        "scheme_change": "Une mise à jour récente te demande de te connecter à nouveau"
    },
    "unit": {
        "bottle_one": "bouteille",
        "bottle_other": "bouteilles",
        "case_one": "cas",
        "case_other": "cas",
        "percentage": "%"
    },
    "validation": {
        "email": "Il semble qu'il s'agisse d'une adresse électronique non valide.",
        "form": {
            "action_required": "Ces domaines requièrent votre attention :",
            "valid": "Le formulaire semble valide"
        },
        "length": {
            "fixed_length": "Ce champ nécessite précisément {{fixed_length}} caractères",
            "max_length": "Ce champ ne doit pas comporter plus de {{max_length}} caractères",
            "min_length": "Ce champ nécessite au moins {{min_length}} caractères"
        },
        "must_match": "Les champs doivent correspondre",
        "password": {
            "cannot_end_with_space": "Le mot de passe ne peut pas se terminer par un espace",
            "min_length": "Le mot de passe doit comporter au moins 9 caractères",
            "one_lower_case": "Le mot de passe doit contenir au moins une lettre minuscule",
            "one_upper_case": "Le mot de passe doit contenir au moins une lettre majuscule"
        },
        "required": "Ce champ est obligatoire",
        "vat_id": {
            "country_incorrect": "L'identifiant TVA a un code pays incorrect ; attendu : '{{expected}}'",
            "help_disabled": "L'identification de la TVA n'est pas prise en charge pour les pays non membres de l'UE : {{country}}",
            "help_validate": "Utilise le bouton Valider pour vérifier l'ID de TVA pour le pays : {{country}}",
            "invalid_format": "<a href='{{link}}' target='_blank'>Format d'identification de la TVA</a> invalide pour le pays : {{country_code}}",
            "label": "NUMÉRO DE TVA ({{country}})",
            "verify": "Vérifier l'identité",
            "vies_match": "Correspondance de l'ID de TVA : {{match}}",
            "vies_nomatch": "Cet identifiant de TVA ne semble pas valide"
        },
        "website": "Cette adresse n'est pas valide"
    }
}