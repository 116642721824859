import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Button, Icon} from '@bitstillery/common/components'
import {blob_to_base64, unique_id} from '@bitstillery/common/lib/utils'
import {proxy} from '@bitstillery/common/lib/proxy'
import {$t, logger} from '@bitstillery/common/app'

let current_stream

interface FieldUploadAttrs {
    accept?: string
    disabled?: boolean
    model: {
        data: string
        name: string
        content_type: string
    }
    help?: string
    label?: string
    placeholder?: string
    icon?: string
    className?: string

    onafter_media: () => unknown
    onbefore_media: () => unknown

    validation: {
        dirty: boolean
        label: string
        _invalid?: {
            message: string
        }
    }
}

export class FieldUpload extends MithrilTsxComponent<FieldUploadAttrs> {

    data = proxy({
        blob_url: '',
    })

    $container: HTMLElement
    canvas = document.createElement('canvas')
    id = unique_id()
    video = document.createElement('video')
    onpaste_listener: (any) => void

    async prepare_file(vnode: m.Vnode<FieldUploadAttrs>, blob) {
        logger.info('[file-upload] prepare file')
        if (vnode.attrs.validation) {
            vnode.attrs.validation.dirty = true
        }

        vnode.attrs.model.name = blob.name
        vnode.attrs.model.content_type = blob.type

        if (vnode.attrs.type === 'screenshot') {
            if (!blob.type.startsWith('image/')) {
                return
            }
            this.prepare_screenshot(vnode, blob)
        } else {
            const file_reader = new FileReader()
            file_reader.onload = (event) => {
                vnode.attrs.model.data = event.target.result
            }
            file_reader.readAsDataURL(blob)
        }
    }

    async prepare_screenshot(vnode: m.Vnode<FieldUploadAttrs>, blob) {
        logger.info('[file-upload] prepare_screenshot')

        if (blob.type.startsWith('video/')) {
            const video = document.createElement('video')
            video.src = URL.createObjectURL(blob)
            video.addEventListener('loadeddata', () => {
                const canvas = document.createElement('canvas')
                canvas.width = video.videoWidth
                canvas.height = video.videoHeight
                const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
                ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
                const background_image = canvas.toDataURL('image/png')
                this.$container.style.backgroundImage = `url(${background_image})`

                // Cleanup
                URL.revokeObjectURL(video.src)
            }, {once: true})
        } else {
            this.data.blob_url = URL.createObjectURL(blob)
            this.$container.style.backgroundImage = `url(${this.data.blob_url})`
        }
        vnode.attrs.model.content_type = blob.type

        const data = await blob_to_base64(blob)
        Object.assign(vnode.attrs.model, {
            data,
            name: blob.name,
        })
    }

    oncreate(vnode: m.Vnode<FieldUploadAttrs>) {
        this.$container = document.querySelector(`#field-media-upload-${this.id} .js-snapshot`) as HTMLElement

        this.onpaste_listener = (e) => this.onpaste(e, vnode)
        document.addEventListener('paste', this.onpaste_listener)

        this.ondragover_listener = (e) => {
            e.preventDefault()
        }
        this.ondrop_listener = (e) => {
            e.preventDefault()
            const files = e.dataTransfer.files
            if (files.length !== 1) return
            const file = files[0]
            this.prepare_file(vnode, file)
        }
        this.$container.addEventListener('dragover', this.ondragover_listener)
        this.$container.addEventListener('drop', this.ondrop_listener)
    }

    on_remove() {
        document.removeEventListener('paste', this.onpaste_listener)
    }

    async onpaste(event, vnode) {
        let disabled = false
        if ('disabled' in vnode.attrs) {
            disabled = vnode.attrs.disabled[0][vnode.attrs.disabled[1]] as any
        }
        if (disabled) {
            return
        }

        const items = event.clipboardData.items
        if (items.length !== 1) {
            return
        }
        const item = items[0]
        // Check if the pasted item type is accepted
        const blob = item.getAsFile()
        this.prepare_file(vnode, blob)
    }

    view(vnode: m.Vnode<FieldUploadAttrs>) {
        const validation = vnode.attrs.validation

        const invalid = validation ? validation._invalid : false
        let disabled = false
        if ('disabled' in vnode.attrs) {
            disabled = vnode.attrs.disabled[0][vnode.attrs.disabled[1]] as any
        }
        return <div
            className={classes('c-field-media-upload', 'field', vnode.attrs.className, {
                disabled,
                invalid: validation && invalid && validation.dirty,
                valid: validation && !invalid && validation.dirty,
            })}
            id={`field-media-upload-${this.id}`}
        >
            {vnode.attrs.label && (
                <label>{vnode.attrs.label}
                    {vnode.attrs.icon && <Icon name={vnode.attrs.icon}/>}
                    {vnode.attrs.validation && <span className="validation">{validation.label}</span>}
                </label>
            )}

            <div className="media-area">
                <div className="actions">
                    {vnode.attrs.type === 'screenshot' && <Button
                        icon="screenshot"
                        onclick={async() => {
                            if (vnode.attrs.onbefore_media) {
                                vnode.attrs.onbefore_media()
                            }
                            if (!current_stream) {
                                const stream = await navigator.mediaDevices.getDisplayMedia()
                                this.video.srcObject = stream
                                await this.video.play()
                                // Wait until the video is ready to be captured
                                while (this.video.readyState < 3) {
                                    await new Promise(resolve => setTimeout(resolve, 100)) // Wait for 100ms before checking again
                                }
                            }

                            this.canvas.width = this.video.videoWidth
                            this.canvas.height = this.video.videoHeight
                            this.canvas.getContext('2d')?.drawImage(this.video, 0, 0, this.canvas.width, this.canvas.height)
                            if (vnode.attrs.onafter_media) {
                                vnode.attrs.onafter_media()
                            }
                            const file = await new Promise(resolve => this.canvas.toBlob(resolve, 'image/png')) as any
                            this.prepare_file(vnode, file)
                        }}
                        tip={$t('issues.button.label.take_snapshot')}
                        type="info"
                        variant="toggle"
                    />}
                    <Button
                        icon="upload"
                        tip={$t('issues.button.label.select_image')}
                        type="info"
                        variant="toggle"
                        onclick={() => {
                            const file_input = document.createElement('input')
                            file_input.type = 'file'
                            file_input.accept = vnode.attrs.accept
                            file_input.onchange = (e) => {
                                if (e.target.files.length !== 1) return
                                const file = e.target?.files[0]
                                this.prepare_file(vnode, file)
                            }

                            file_input.click()
                        }}
                    />
                    <Button
                        disabled={!vnode.attrs.model.data}
                        icon="trash"
                        type="info"
                        variant="toggle"
                        onclick={() => {
                            Object.assign(vnode.attrs.model, {
                                file: '',
                                data: '',
                                name: '',
                            })
                            this.data.blob_url = ''
                            this.$container.style.backgroundImage = `url(${this.data.blob_url})`
                        }}
                    />
                </div>
                <div className={classes('snapshot js-snapshot', {
                    'contains-image': this.data.blob_url,
                })}>
                    <Icon name={vnode.attrs.type === 'screenshot' ? 'image' : 'upload'} type="unset" />
                    <p>{vnode.attrs.model.name ? vnode.attrs.model.name : vnode.attrs.placeholder}</p>
                </div>
            </div>
            {(() => {
                if (invalid && validation.dirty) {
                    return <div className="help validation">{typeof invalid.message === 'function' ? invalid.message() : invalid.message}</div>
                } else if (vnode.attrs.help) {
                    return <div className="help">{vnode.attrs.help}</div>
                }
            })()}
        </div>
    }
}
