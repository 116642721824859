import m from 'mithril'
import {debounce} from '@bitstillery/common/lib/utils'
import {next_tick} from '@bitstillery/common/lib/proxy'
import {set_language} from '@bitstillery/common/lib/i18n'
import {$t, api, events, logger, notifier, store, telemetry} from '@bitstillery/common/app'

import {$s} from '@/app'
import {GetAccountResponse, GetPortalProfileResponse, PricePreference} from '@/factserver_api/fact2server_api.ts'
import {volatile} from '@/lib/state'

export function bind() {
    events.on('identity.logout', async() => {
        // Finally, give Mithril some time to route to the logged-out view,
        // sow we can safely remove the identity data.
        await next_tick()
        await next_tick()
        Object.assign($s.identity, JSON.parse(JSON.stringify(volatile)).identity)
    })
}

export function logout(force = false) {
    logger.debug('[identity] identity.logout')

    // Change minimal profile data to indicate we are logged out first.
    $s.identity.token = null
    $s.cart.artkey = null
    // Then route to the login view; giving logged-in views room to close.
    m.route.set('/login')
    telemetry.set_user(undefined)
    store.save()

    if (force) {
        notifier.notify($t('landing.force_logout', 'warning'))
    }
    events.emit('identity.logout')
}

export async function load_user() {
    logger.debug('[identity] load_user')

    const [{result: account_result}, {result: profile_result}, {result: demo_result}] = await Promise.all([
        api.get<GetAccountResponse>(`portal/account/${process.env.MSI_THEME}`),
        api.get<GetPortalProfileResponse>('portal/user/profile'),
        api.post('portal_account.is_demo_user', {}),
    ])

    logger.debug('[identity] load_user_status')
    Object.assign($s.identity, {
        demo: demo_result,
        user: profile_result,
    })

    set_language($s.identity.user.language)
    $s.cart.show_bottles = $s.identity.user.price_preference === PricePreference.Bottle

    telemetry.set_user({
        country_code: $s.identity.user.country_code,
        emailaddress: $s.identity.user.email_address,
        id: $s.identity.user.artkey,
        username: $s.identity.user.username,
    })

    $s.portal.account = account_result
    if ($s.identity.user.sales_manager) {
        const support_manager = $s.identity.user.sales_manager
        // Make the email address account-specific. The phonenumber is a direct
        // number to the support manager and is not account-specific. Therefor,
        // we use the account's generic number here.
        support_manager.email_address = `${support_manager.email_address.split('@')[0]}@${$s.portal.account.domain}`
        support_manager.phone_number = $s.portal.account.telephone_number
        $s.support.manager = support_manager
    }

    store.save()
}

export async function new_token(token) {
    logger.debug(`[identity] loading ${token ? 'existing' : 'new'} token`)
    $s.identity.token = token
    // (!) Load profile-related data first, before signalling that we're logged in.
    // Otherwise code has to be needlessly sprinkled around in components, checking for
    // logged in status.
    await load_user()
    events.emit('identity.login', $s.identity)
}

export async function refresh_token() {
    const {result} = await api.post('refresh-token', {}) as any
    await new_token(result.token)
}

export const logout_debounced = debounce(100, () => {
    logout()
    notifier.notify($t('landing.force_logout', 'warning'))
})
