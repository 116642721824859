import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Button, FieldText} from '@bitstillery/common/components'
import {proxy} from '@bitstillery/common/lib/proxy'
import {$t, api, notifier} from '@bitstillery/common/app'

import {$m} from '@/app'

export class MyPortalSecurity extends MithrilTsxComponent<any> {

    data = proxy({
        password: {
            _valid: function() {
                return ((this.new === this.repeated) && !!this.current && !!this.new && !!this.repeated)
            },
            current: null,
            new: null,
            repeated: null,
        },
    })

    async update_password() {
        const {status_code} = await api.post('portal/user/change-password', {
            current_password: this.data.password.current,
            new_password: this.data.password.new,
        },
        true,
        )
        if (status_code === 400) {
            notifier.notify($t('my_portal.security.notifications.new_password_invalid'), 'danger')
        } else if (status_code === 403) {
            notifier.notify($t('my_portal.security.notifications.current_password_invalid'), 'danger')
        } else {
            $m.identity.logout()
            notifier.notify($t('my_portal.security.notifications.password_updated'), 'danger')
        }
    }

    view(_vn:m.Vnode<any>) {
        return (
            <div className="c-my-portal-security form">
                <div className="fieldset">
                    <FieldText
                        help={$t('my_portal.security.password.current_help')}
                        label={$t('my_portal.security.password.current')}
                        model={[this.data.password, 'current']}
                        required={true}
                        type="password"
                    />
                    <FieldText
                        help={$t('my_portal.security.password.new_help')}
                        label={$t('my_portal.security.password.new')}
                        model={[this.data.password, 'new']}
                        required={true}
                        type="password"
                    />
                    <FieldText
                        help={$t('my_portal.security.password.repeat_help')}
                        label={$t('my_portal.security.password.repeat')}
                        model={[this.data.password, 'repeated']}
                        required={true}
                        type="password"
                    />
                </div>
                <Button
                    className="btn-submit"
                    disabled={!this.data.password._valid}
                    icon="save"
                    type="success"
                    text={$t('my_portal.security.save_button')}
                    onclick={this.update_password.bind(this)}
                />
            </div>
        )
    }
}
