// (!) Do not import from tsconfig paths; e.g. no $s, $t, $... The reason is that this file
// is imported inside tests. The current ts-node config does not support ts-config.json paths.
// Package https://www.npmjs.com/package/tsconfig-paths could enable this.

import classnames from 'classnames'
import m from 'mithril'

// Accepts multiple arguments and types ('', [], {})
// See https://github.com/JedWatson/classnames
export const classes = classnames

export function add_unique_to_array(array, value) {
    if (!array.includes(value)) {
        array.push(value)
    }
    return array
}

export function copy_object(obj) {
    return JSON.parse(JSON.stringify(obj))
}

export async function blob_to_base64(blob): Promise<String> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => {
            // The "data:image/...;base64," prefix is part of the data URL scheme,
            // and must be omitted from the raw base64.
            let base64_data = reader.result as string
            let raw_base64_data = base64_data.split(',')[1]

            // Make sure the base64 is padded correctly, before sending to the backend.
            const padding = '='.repeat((4 - raw_base64_data.length % 4) % 4)
            raw_base64_data += padding
            resolve(raw_base64_data)
        }
        reader.onerror = reject
        reader.readAsDataURL(blob)
    })
}

/**
 * Converts a base64 encoded string to a Uint8Array of bytes.
 * This function is useful for decoding a base64 string to its original binary form.
 * @param {string} base64 The base64 encoded string to convert.
 * @returns {Uint8Array} A Uint8Array representing the decoded bytes of the base64 string.
 */
export function base64_to_bytes(base64: string): Uint8Array {
    const binary_string = atob(base64)
    const len = binary_string.length
    const bytes = new Uint8Array(len)

    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i)
    }

    return bytes
}

export function data_from_blueprint(data, blueprint) {
    for (const key of Object.keys(data)) {
        if (Object.hasOwnProperty.call(blueprint, key)) {
            if (is_nested_object(data[key])) {
                this.update_data_from_blueprint(data[key], blueprint[key])
            } else {
                data[key] = blueprint[key]
            }
        }
    }
    return data
}

/**
 * Downloads a file using a base64 encoded string.
 * @param {string} base64 The base64 encoded string representing the file content.
 * @param {string} filename The name of the file to be downloaded.
 */
export function download_base64_file(base64: string, filename: string) {
    const byte_array = base64_to_bytes(base64)
    const blob = new Blob([byte_array], {type: 'application/octet-stream'})
    const a = document.createElement('a')
    document.body.appendChild(a) // necessary for Firefox(?)
    const url = window.URL.createObjectURL(blob)
    a.href = url
    a.download = filename
    a.click()

    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)
}

Number.prototype.format_percentage = function() {
    let n = this
    if (isNaN(n)) return 0
    return parseFloat(n).toFixed(2)
}

/**
 * Fast and simple insecure string hash for JavaScript
 * See https://gist.github.com/jlevy/c246006675becc446360a798e2b2d781
 */
export function hash(str) {
    let hash = 0
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i)
        hash = (hash << 5) - hash + char
        hash &= hash // Convert to 32bit integer
    }
    return new Uint32Array([hash])[0].toString(36)
}

export function is_nested_object(value) {
    return value !== null && typeof value === 'object' && !Array.isArray(value)
}

export function is_object(v) {
    return (v && typeof v === 'object' && !Array.isArray(v))
}

export function is_promise(value) {
    return Boolean(value && typeof value.then === 'function')
}

/**
 * Returns a function, that, as long as it continues to be invoked, will not be
 * triggered. The function will be called after it stops being called for N
 * milliseconds.
 */
export function debounce(wait: number, func) {
    type Timeout = ReturnType<typeof setTimeout>
    type Context = {timeout: Timeout | undefined}
    let ctx: Context = {timeout: undefined}
    let callback = function(this: any, ...args: Parameters) {
        return new Promise((resolve) => {
            let later = async() => {
                ctx.timeout = undefined
                resolve(func.apply(this, args))
            }
            if (ctx.timeout) {
                clearTimeout(ctx.timeout)
            }

            ctx.timeout = setTimeout(later, wait)
        })
    }
    return callback
}

export function delay(msec, value) {
    return new Promise(done => window.setTimeout((() => done(value)), msec))
}

/**
 * Get the descendant of an object by string. E.g.:
 * get_descendant_prop({ a: { b: { c: { d: 3 } } } }, "a.b.c.d")
 * returns 3
 * @param obj An object
 * @param desc A string representing the path to the descendant
 */
export function get_descendant_prop(obj: Record<string, any>, desc: string): any {
    const arr = desc.split('.')
    for (const key of arr) {
        obj = obj[key]
        if (!obj) {
            break
        }
    }
    return obj
}

export function get_route(path, params = {} as any, update_key = true, exclude_keys = []) {
    if (update_key) {
        params.key = Date.now()
    } else {
        const {params} = m.parsePathname(m.route.get())
        const params_from_url = Object.fromEntries(new URLSearchParams(params))

        if ('key' in params_from_url) {
            // Reuse an existing key in the params, but don't add a new one,
            // or we'll trigger a component rerendering loop.
            params.key = params_from_url.key
        }
    }

    if (exclude_keys.length) {
        for (const param_key of Object.keys(params)) {
            if (exclude_keys.includes(param_key)) {
                delete params[param_key]
            }
        }
    }

    return `${path}?${new URLSearchParams(params).toString()}`
}

export function group_by(values, keyFinder) {
    // using reduce to aggregate values
    return values.reduce((a, b) => {
        // depending upon the type of keyFinder
        // if it is function, pass the value to it
        // if it is a property, access the property
        const key = typeof keyFinder === 'function' ? keyFinder(b) : b[keyFinder]

        // aggregate values based on the keys
        if (!a[key]) {
            a[key] = [b]
        } else {
            a[key] = [...a[key], b]
        }

        return a
    }, {})
}

/**
 * Identifies keys present in the reference object but missing in the target object,
 * concatenating them into a dot notation path and adding to the diff array.
 * @param {Object} reference - The reference object to compare keys from.
 * @param {Object} target - The target object to compare keys against.
 * @param {Array} diff - An array to store the paths of keys that are in reference
 * but not in target.
 * @param {Array} currentPath - The current path being traversed, for nested objects.
 */
export function key_diff(reference: Record<string, any>, target:Record<string, any>, diff, currentPath) {
    if (!currentPath) {
        currentPath = []
    }
    for (const key of Object.keys(reference)) {
        if (typeof target[key] === 'object') {
            currentPath.push(key)
            key_diff(reference[key], target[key], diff, currentPath)
        } else if (!(key in target)) {
            diff.push([...currentPath, key].join('.'))
        }
    }
    currentPath.pop()
}

export function key_path(obj, path) {
    if (typeof path !== 'string') return null
    const _path = path.split('.')
    let _obj = obj
    while (_path.length) {
        _obj = _obj[_path.shift()]
    }

    return _obj
}

/**
 * Deeply merges multiple source objects into a target object. It recursively
 * merges only own and enumerable properties of the source objects into the
 * target object. Arrays and primitive types are overwritten by assignment.
 * @param {Object} target - The target object to merge properties into.
 * @param {...Object} sources - One or more source objects from which to copy properties.
 * @returns {Object} The target object after merging.
 */
export function merge_deep(target, ...sources) {
    if (!sources.length) return target
    const source = sources.shift()

    if (is_object(target) && is_object(source)) {
        for (const key in source) {
            if (is_object(source[key])) {
                if (!target[key]) Object.assign(target, {[key]: {}})
                merge_deep(target[key], source[key])
            } else {
                Object.assign(target, {[key]: source[key]})
            }
        }
    }

    return merge_deep(target, ...sources)
}

export function object_to_query_string(obj) {
    return Object.entries(obj).map(([key, value]) => {
        if (typeof value === 'object' && value !== null) {
            // Convert nested objects to a stringified JSON format
            return `${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(value))}`
        } else {
            // Convert other values to a URL-encoded format
            return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        }
    }).join('&')
}

// Removes the value from a mutable array.
// Assumes that the value won't occur more than once.
export function remove_from_array(array, value) {
    const idx = array.indexOf(value)
    if (idx >= 0) {
        array.splice(idx, 1)
    }
    return array
}

/**
 * Converts a string into a URL-friendly slug.
 * The function takes a string, converts it to lowercase, then replaces spaces with dashes.
 * @param {string} name - The string to be converted into a slug.
 * @returns {string} The resulting slug.
 */
export function slugify(name:string) {
    return name.toLowerCase().split(' ').join('-')
}
/**
 * Splits an incoterm string into its components.
 * If the incoterm string contains a single part, it assumes the incoterm is 'EXW'
 * and the location is the single part. If the incoterm string contains multiple parts, t
 * he first part is considered the incoterm and the rest is joined as the location.
 *
 * @param {string} incoterm - The incoterm string to be split.
 * @returns {Object} An object containing the incoterm and location.
 */
export function split_incoterm(incoterm) {
    if (!incoterm) return
    const parts = incoterm.split(' - ')
    // If we split more with our -, join the rest on -
    if (parts.length === 1) {
        return {
            incoterm: 'EXW',
            location: parts[0],
        }
    } else {
        return {
            incoterm: parts[0],
            location: parts.slice(1).join('-'),
        }
    }
}

export function stringify_json(data) {
    return JSON.stringify(data, function(k,v) {
        if (v instanceof Array) {
            return JSON.stringify(v)
        }
        return v
    },2).replace(/\\/g, '')
        .replace(/"\[/g, '[')
        .replace(/]"/g,']')
        .replace(/"\{/g, '{')
        .replace(/}"/g,'}')
}

export function strip_empty_values_nested_obj(obj: object, skip_keys: string[] = []) {
    Object.keys(obj).forEach(key => {
        if (skip_keys.includes(key)) return
        if ((obj[key] === '' || obj[key] === undefined || obj[key] === null)) {
            delete obj[key]
        } else if (typeof obj[key] === 'object') {
            strip_empty_values_nested_obj(obj[key], skip_keys)
        }
    })
    return obj
}

/**
 * Constructs a template string from the provided inputs.
 * @param {TemplateStringsArray} strings The template strings array.
 * @param {...any} keys The keys to replace within the template.
 * @returns {Function} Returns a function that accepts replacements for the template and returns the resulting string.
 */
export function template(strings, ...keys) {
    return (...values) => {
        const dict = values[values.length - 1] || {}
        const result = [strings[0]]
        keys.forEach((key, i) => {
            const value = Number.isInteger(key) ? values[key] : dict[key]
            result.push(value, strings[i + 1])
        })
        return result.join('')
    }
}

export function titleize(str) {
    return str.replace(/\b[a-z]/g, (_str) => (str.toUpperCase()))
}

/**
 * Creates a string that can be used for unique dynamic id attributes
 * @param length The length of the string
 * @param include_numbers Whether to include numbers in the string
 */
export function unique_id(length: number = 9, include_numbers: boolean = true) {
    let result = ''
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
    if (include_numbers) {
        characters += '0123456789'
    }
    const characters_length = characters.length
    for (let i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * characters_length),
        )
    }
    return result
}

/**
 * Converts the first character of a string to uppercase.
 * If the input string is empty or null, returns an empty string.
 * @param {string} str The string to convert.
 * @returns {string} The string with the first character in uppercase.
 */
export function ucfirst(str) {
    if (!str || !str.length) return ''
    return str[0].toUpperCase() + str.substring(1)
}

/**
 * Compares two URLSearchParams objects to determine if they have different parameters.
 * @param {URLSearchParams} searchParams1 The first URLSearchParams object for comparison.
 * @param {URLSearchParams} searchParams2 The second URLSearchParams object for comparison.
 * @returns {boolean} Returns true if there is a difference in the parameters, otherwise false.
 */
export function url_search_params_diff(searchParams1:URLSearchParams, searchParams2:URLSearchParams) {
    const params1 = Object.fromEntries(searchParams1)
    const params2 = Object.fromEntries(searchParams2)
    const params2_diff = Object.entries(params2).some(([k, v]) => v !== params1[k])
    const params1_diff = Object.entries(params1).some(([k, v]) => v !== params2[k])
    return params2_diff || params1_diff
}

export function validate_bottle_gtin(gtin) {
    if (gtin.match(/^[0-9]+$/) === null) {
        return 'Please enter a valid bottle GTIN'
    }

    if (![12, 13].includes(gtin.length)) {
        return 'Bottle GTIN contains either 12 or 13 digits'
    }

    if (!validate_check_digit(gtin)) {
        return 'GTIN check digit is incorrect'
    }
}

export function validate_check_digit(gtin) {
    const gtin_min_check = gtin.substring(0, gtin.length - 1).split('').map(Number).reverse()

    let sum = 0
    let i = 1
    for (const digit of gtin_min_check) {
        if (i % 2 === 0) {
            sum += digit
        } else {
            sum += digit * 3
        }
        i++
    }

    const check_digit = (10 - (sum % 10)) % 10
    const same_digit_bool = check_digit.toString() === gtin.charAt(gtin.length - 1)
    return same_digit_bool
}

/**
 * Converts "2024-09-25T00:00:00Z" to "2024-09-25"
 * @param dateTimeString
 * @returns
 */
export function datetime_to_date(datetime) {
    if (typeof datetime !== 'string') {
        return null
    }

    if (!datetime.includes('T')) {
        throw new Error('invalid datetime string')
    }
    return datetime.split('T')[0]
}
