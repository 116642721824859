import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {classes, unique_id} from '@bitstillery/common/lib/utils'
import {Button, Dropdown, FieldSelect, FieldText, Icon} from '@bitstillery/common/components'
import {FieldSelectManyAttrs} from '@bitstillery/common/types/field'
import {proxy} from '@bitstillery/common/lib/proxy'

export interface SelectValue {
    value: number | string
    label: string
}

export class FieldSelectMany extends MithrilTsxComponent<FieldSelectManyAttrs> {

    id = unique_id()

    data = proxy({
        inline: {
            search: '',
            toggle: false,
        },
        selected: '',
    })

    oninit(vnode: m.Vnode<FieldSelectManyAttrs>) {
        // Augment the options with a disabled property if it is missing.
        for (const option of vnode.attrs.options) {
            if (!('disabled' in option)) {
                option.disabled = vnode.attrs.model.includes(option.value)
            }
        }
    }

    view(vnode: m.Vnode<FieldSelectManyAttrs>) {
        const selected_option = vnode.attrs.options.find((i:any) => i.value === this.data.selected)
        const variant = vnode.attrs.variant || 'default'

        // Sort the model array
        const sorted_model = [...vnode.attrs.model].sort((a, b) => {
            const option_a = vnode.attrs.options.find((opt: any) => opt.value === a)
            const option_b = vnode.attrs.options.find((opt: any) => opt.value === b)
            return (option_a?.label || String(a)).localeCompare(option_b?.label || String(b))
        })

        return <div id={`c-field-multiselect-${this.id}`} className={classes(
            vnode.attrs.composed ? null : 'c-field-select-many field',
            `variant-${variant}`,
            vnode.attrs.className, {
                disabled: vnode.attrs.disabled,
            })
        }>
            {/* Shows a regular select with options; the selected options are shown in a list below */}
            {variant === 'default' && [
                <div className="control">
                    <FieldSelect
                        composed={true}
                        label={vnode.attrs.label}
                        model={[this.data, 'selected']}
                        options={vnode.attrs.options}
                        placeholder={vnode.attrs.placeholder ? vnode.attrs.placeholder : 'Select...'}
                    />
                    <Button
                        icon="plus"
                        disabled={!this.data.selected}
                        onclick={(event) => {
                            // if not yet in selection and something is selected
                            if (!vnode.attrs.model.includes(this.data.selected) && selected_option) {
                                vnode.attrs.model.push(this.data.selected)
                            }
                            selected_option.disabled = true
                            this.data.selected = ''
                            if (vnode.attrs.onchange) {
                                vnode.attrs.onchange(vnode.attrs.model)
                            }
                            event.preventDefault()
                        }}
                        tip="Add item to selection"
                        type="info"
                    />
                </div>,
                sorted_model.length ? <div className="selection-list">
                    {sorted_model.map((value:any) => <div className="selection-item">
                        {(() => {
                            const option = vnode.attrs.options.find((i) => i.value === value)
                            return option?.label || value
                        })()}
                        <Icon
                            name="trash"
                            size="s"
                            onclick={(e) => {
                                e.preventDefault()
                                const index = vnode.attrs.model.findIndex((i) => i === value)
                                const clicked_option = vnode.attrs.options.find((i:any) => i.value === value)
                                clicked_option.disabled = false

                                m.redraw()
                                if (index >= 0) {
                                    vnode.attrs.model.splice(index, 1)
                                }
                                if (vnode.attrs.onchange) {
                                    vnode.attrs.onchange(vnode.attrs.model)
                                }
                            }}
                            type="unset"
                        />
                    </div>)}
                </div> : null,
            ]}
            {/* The inline variant shows a dropdown with options; the selected options are toggled and shown in the same dropdown*/}
            {variant === 'inline' && [
                vnode.attrs.label ? <label>{vnode.attrs.label}</label> : null,
                <Dropdown
                    disabled={vnode.attrs.disabled || !vnode.attrs.options.length}
                    model={this.data.inline}
                    trigger="hover"
                >
                    <Button
                        className={classes('btn-dropdown', {
                            disabled: vnode.attrs.disabled || !vnode.attrs.options.length,
                        })}
                        icon={this.data.inline.toggle ? 'chevronDown' : 'chevronUp'}
                        text={(() => {
                            if (!vnode.attrs.model.length) {
                                if (vnode.attrs.options.length) {
                                    return 'Select...'
                                }
                                return 'No options...'
                            } else {
                                if (vnode.attrs.options.length === vnode.attrs.model.length) return `All selected (${vnode.attrs.model.length})`
                                return `${vnode.attrs.model.length}/${vnode.attrs.options.length} selected...`
                            }
                        })()}
                        type='unset'
                        variant='menu'
                    />

                    {(vnode.attrs.options.length > 0) && <div className='dropdown-body'
                        onclick={(e) => {
                            if (e.target.closest('input[type="checkbox"]')) {
                                const index = vnode.attrs.model.findIndex((i) => i === e.target.value)
                                if (index >= 0) {
                                    vnode.attrs.model.splice(index, 1)
                                } else {
                                    vnode.attrs.model.push(e.target.value)
                                }

                                if (vnode.attrs.onchange) {
                                    vnode.attrs.onchange(vnode.attrs.model)
                                }
                            }
                        }}
                    >
                        {vnode.attrs.options.length > 10 ? <FieldText
                            model={[this.data.inline, 'search']}
                            placeholder="Search..."
                        /> : null}
                        <div className="scroll-container">
                            {vnode.attrs.options.filter((option:any) => {
                                if (!this.data.inline.search) return true
                                return option.label.toLowerCase().includes(this.data.inline.search.toLowerCase())
                            }).map((option:any) => {
                                const checked = vnode.attrs.model.includes(option.value)
                                return <label>
                                    <input
                                        type="checkbox"
                                        checked={checked}
                                        value={option.value}
                                    /><span>{option.label}</span>
                                </label>
                            })}
                        </div>
                    </div>}
                </Dropdown>,
            ]}
        </div>
    }
}
