import m from 'mithril'
import {Icon, Spinner} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {$t, api} from '@bitstillery/common/app'

import {DashboardWidget} from './widget'

export class TipsWidget extends MithrilTsxComponent<any> {

    item: {
        content: string
        title: string
    }

    async oninit() {
        const {result, status_code} = await api.post('tip', {})
        if (status_code > 299) {
            return
        }
        this.item = result
    }

    view(_vn:m.Vnode<any>) {
        if (!this.item) return <Spinner/>
        return (
            <DashboardWidget
                icon="lightBulb"
                name="tips"
                title={$t('dashboard.widgets.tips.title')}
            >
                <div className="tip-container">
                    <div className="tip-item">
                        <div className="tip-item-title">
                            <Icon className="icon-l" name="lightBulb"/>
                            <span>{this.item.title}</span>
                        </div>
                        <div className="tip-item-content">
                            {this.item.content}
                        </div>
                    </div>
                </div>
            </DashboardWidget>
        )
    }
}
