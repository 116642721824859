import {$t} from '@bitstillery/common/app'

import {format_date} from './format'

interface SpecsItem {
    bottle_volume?: string
    bottle_alcohol_percentage?: string
    bottle_refill_status?: string
    case_number_of_bottles: number
    case_gift_box_type?: string | null
    case_customs_status: string
    case_tax_label?: string | null
    product_category_name?: string
}

export function to_specs(item: SpecsItem) {
    const spec_line = [
        item.case_number_of_bottles,
        item.bottle_volume ? `${Number(item.bottle_volume).toFixed(1)} cl` : '',
        `${Number(item.bottle_alcohol_percentage).toFixed(1)}${$t('unit.percentage')}`,
        item.case_gift_box_type,
        item.bottle_refill_status ? item.bottle_refill_status.toUpperCase() : '',
        item.case_customs_status,
        item.case_tax_label,
        item.product_category_name,
    ]
    return spec_line.filter((i) => i).join(' / ')
}

export function to_tags(offer_item) {
    let bbd = ''
    if (offer_item.item_best_before_date) {
        bbd = `BBD ${format_date(offer_item.item_best_before_date)}`
    }
    const tags_line = [
        [offer_item.case_tax_label],
        [offer_item.item_damages],
        [offer_item.item_general_tags],
        [offer_item.item_pack_size],
        [offer_item.item_packaging],
        [bbd, 'bbd'],
    ]
    return tags_line.filter((i) => {
        return i[0] && i[0].length
    }).join(' / ')
}
