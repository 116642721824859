import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {classes} from '@bitstillery/common/lib/utils'
import {$s} from '@bitstillery/common/app'

interface CollectionViewAttrs {
    className?: string
    /** Greys out the CollectionView to indicate it is inactive */
    inactive?: boolean
    mode?: 'grid' | 'table'
    size?: 'd' | 's'
}

export class CollectionView extends MithrilTsxComponent<CollectionViewAttrs> {

    view(vnode:m.Vnode<CollectionViewAttrs>) {
        return <div className={classes('c-collection-view', 'view', `size-${vnode.attrs.size ? vnode.attrs.size : 'd'}`, vnode.attrs.className,
            $s.env.layout === 'mobile' ? 'grid' : vnode.attrs.mode, {
                inactive: vnode.attrs.inactive,
            })}>
            {vnode.children}
        </div>
    }
}
