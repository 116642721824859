import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {FieldText, Icon} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {FilterSelectSingleState} from '@bitstillery/common/types'
import {clear_filter} from '@bitstillery/common/lib/filters'
import {$t} from '@bitstillery/common/app'

interface FilterTextAttrs {
    className: string
    filter: FilterSelectSingleState
    title: string
}

export class FilterText extends MithrilTsxComponent<FilterTextAttrs> {

    view(vnode:m.Vnode<FilterTextAttrs>) {
        return <div className={classes('c-filter-text', 'filter', 'non-collapsable', vnode.attrs.className, {
            active: vnode.attrs.filter.selection.length,
        })}>
            <div className="title">
                <div class="name">
                    <Icon
                        active={vnode.attrs.filter.selection.length}
                        className="btn-filter-toggle"
                        name={(() => {
                            if (vnode.attrs.filter.icon) return vnode.attrs.filter.icon
                            return vnode.attrs.filter.selection ? 'filterRemove' : 'filterPlus'
                        })()}
                        onclick={(e) => {
                            e.stopPropagation()
                            clear_filter(vnode.attrs.filter)
                        }}
                        tip={() => {
                            return vnode.attrs.filter.selection.length ? $t('filters.actions.deactivate_filter') : $t('filters.inactive_filter')
                        }}
                    />
                </div>
                <FieldText
                    model={[vnode.attrs.filter, 'input']}
                    onenter={(_e, model_value) => {
                        vnode.attrs.filter.selection = model_value
                    }}
                    placeholder={`${$t(vnode.attrs.filter.title)}...`}
                />
            </div>
            {vnode.attrs.filter.help && <div class="help">{vnode.attrs.filter.help}</div> }
        </div>
    }
}
