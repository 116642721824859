import m from 'mithril'
import {
    CollectionFilterControl,
    CollectionHeader,
    CollectionItems,
    CollectionSorter,
    CollectionStatus,
    CollectionView,
    CollectionViewToggle,
    PanelFilters,
} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {proxy} from '@bitstillery/common/lib/proxy'
import {CollectionTransforms, CollectionProxy} from '@bitstillery/common/lib/collection'
import {
    FiltersDescription,
    FilterSelectMultipleState,
    FilterSelectSingleState,
    FilterTextState,
} from '@bitstillery/common/types'
import {generate_filters} from '@bitstillery/common/lib/filters'
import {format_date} from '@bitstillery/common/lib/format'
import {$t, api, notifier} from '@bitstillery/common/app'

import {CollectionProduct} from '@/components/collection/product'
import {$m, $s} from '@/app'
import {IncentivePersonalOffer} from '@/components/incentives'
import {PanelCart} from '@/components/panels'
import {PricelistDownload} from '@/components/pricelist_download/pricelist_download'
import {
    GetPortalOfferItemsResponse,
    StatisticsForFilterResponse,
} from '@/factserver_api/fact2server_api'

interface OfferFilters extends FiltersDescription {
    alcohol: Partial<FilterSelectSingleState>
    availability: Partial<FilterSelectMultipleState>
    category: Partial<FilterSelectMultipleState>
    offer_hash: Partial<FilterSelectSingleState>
    offertype: Partial<FilterSelectSingleState>
    packaging: Partial<FilterSelectMultipleState>
    refill: Partial<FilterSelectSingleState>
    search: Partial<FilterTextState>
    volume: Partial<FilterSelectSingleState>
}

export const filters = generate_filters<OfferFilters>({
    alcohol: {
        count: true,
        icon: 'alcoholPercentage',
        placement: {order: 5},
        serialize: 'number',
        type: 'SELECT_SINGLE',
    },
    availability: {
        count: true,
        icon: 'availability',
        options_i18n: 'filters.types.availability.',
        placement: {order: 4},
        serialize: ['string'],
        type: 'SELECT_MULTIPLE',
    },
    category: {
        count: true,
        icon: 'bottle',
        placement: {order: 3},
        serialize: ['number'],
        type: 'SELECT_MULTIPLE',
    },
    offer_hash: {
        icon: 'offer',
        placement: {min: 1, order: 0},
        serialize: 'string',
        type: 'SELECT_SINGLE',
    },
    offertype: {
        count: true,
        icon: 'specialOffers',
        options_i18n: 'filters.types.offertype.',
        placement: {order: 1},
        serialize: 'string',
        type: 'SELECT_SINGLE',
    },
    packaging: {
        count: true,
        icon: 'packaging',
        options_i18n: 'filters.types.packaging.',
        placement: {order: 6},
        serialize: ['string'],
        type: 'SELECT_MULTIPLE',
    },
    refill: {
        count: true,
        icon: 'refillable',
        options_i18n: 'filters.types.refill.',
        placement: {order: 7},
        serialize: 'string',
        type: 'SELECT_SINGLE',
    },
    search: {
        icon: 'search',
        serialize: ['string'],
        type: 'TEXT',
    },
    volume: {
        count: true,
        icon: 'volume',
        placement: {order: 5},
        serialize: 'number',
        type: 'SELECT_SINGLE',
    },
})

const transforms:CollectionTransforms = {
    filters_to_query: (filters) => {
        return {
            alcohol_percentage: filters.alcohol.selection ? filters.alcohol.selection : undefined,
            availabilities: filters.availability.selection,
            category_artkeys: filters.category.selection,
            offer_hash: filters.offer_hash.selection ? filters.offer_hash.selection : undefined,
            offer_item_type: filters.offertype.selection ? filters.offertype.selection : 'BROWSE_STOCK',
            packaging: filters.packaging.selection,
            refill_status: filters.refill.selection,
            search_terms: filters.search.selection,
            sort_ascending: collection.state.sort.order === 'asc' ? true : false,
            sort_by: collection.state.sort.by,
            volume: filters.volume.selection ? filters.volume.selection : undefined,
        }
    },
    filter_metadata: {
        endpoint: 'portal/filter/statistics',
        transform: (result:StatisticsForFilterResponse) => {
            function format_category_stats(category) {
                const statistic = result.category_statistics?.find((i) => i.artkey === category.artkey)
                return [
                    category.artkey,
                    category.name,
                    statistic ? statistic.count : 0,
                    category.children.map((child) => format_category_stats(child)),
                ]
            }

            const category_stats = $s.categories.menu.map((category) => format_category_stats(category)) as [number, string, number, []]
            for (const category of category_stats.filter((category) => category[2] === 0)) {
                category[2] = category[3].map((child) => child[2]).reduce((acc, value) => acc + value, 0)
            }

            // Update the filter statistics.
            const percentage_unit = $t('unit.percentage')
            const alcohol_stats = result.alcohol_statistics?.map((i) => [i.alcohol_percentage, `${i.alcohol_percentage}${percentage_unit}`, i.count]) as []
            const availability_stats = result.availability_statistics?.map((i) => [i.offer_item_type, i.offer_item_type, i.count]) as []
            const packaging_stats = result.packaging_statistics?.map((i) => [i.name, i.name, i.count]) as []
            const offer_type_stats = result.offer_item_statistics?.map((i) => [i.offer_item_type, i.offer_item_type, i.count]) as []
            const refill_stats = result.refill_status_statistics?.map((i) => [i.refill_status, i.refill_status, i.count]) as []
            const volume_stats = result.volume_statistics?.map((i) => [i.size_in_cl, `${i.size_in_cl} cl`, i.count]) as []

            // Update the filter options.
            filters.alcohol.options.splice(0, filters.alcohol.options.length, ...alcohol_stats)
            filters.availability.options.splice(0, filters.availability.options.length, ...availability_stats)
            filters.category.options.splice(0, filters.category.options.length, ...category_stats)
            filters.packaging.options.splice(0, filters.packaging.options.length, ...packaging_stats)
            filters.offertype.options.splice(0, filters.offertype.options.length, ...offer_type_stats)
            filters.refill.options.splice(0, filters.refill.options.length, ...refill_stats)
            filters.volume.options.splice(0, filters.volume.options.length, ...volume_stats)
        },
    },
    items_queried: async({result, status_code}: {result: GetPortalOfferItemsResponse; status_code: number}) => {
        if (status_code === 404) {
            $s.portal.ready = false
            return []
        }

        if (status_code > 299) {
            return []
        }
        await Promise.all([
            $m.offer.load_country_of_origin(result.portal_offer_items.map((i) => i.artkey)),
            $m.offer.load_product_photos(result.portal_offer_items.map((i) => i.artkey)),
        ])
        return result.portal_offer_items
    },
}

export const collection = new CollectionProxy()

export class OfferList extends MithrilTsxComponent<unknown> {

    data = (() => {
        return proxy({
            carousel_item: null,
        })
    })()

    oninit() {
        collection.init({
            endpoint: {
                method: 'post',
                path: 'portal/offer-items',
            },
            sort: {
                by: 'product_name',
                options: [
                    ['product_name', 'Product name'],
                    ['list_quantity', 'List quantity'],
                    ['delivery_period', 'Delivery period'],
                    ['list_price', 'List price'],
                    ['entry_date', 'Entry date'],
                ],
                order: 'asc',
            },
        }, filters, transforms)

        $m.cart.data.stepper.selection = 0
        $s.panels.view.categories.collapsed = true
        $s.panels.view.filters.collapsed = true
        this.get_personal_offers()
        this.set_title()
    }

    async get_personal_offers() {
        filters.offer_hash.loading = true
        const {status_code, result: active_personal_offers} = await api.post('offer.active-offers') as any
        if (status_code > 299) {
            return
        }
        $s.offers.personal = active_personal_offers
        filters.offer_hash.options = active_personal_offers.map((i) => [i.hash, i.title])
        const active_personal_offer = active_personal_offers.find((i) => i.hash === filters.offer_hash.selection)

        if (filters.offer_hash.selection) {
            // A personal offer is supposed to be active
            if (active_personal_offer) {
                notifier.notify($t('offer.personal_valid', {
                    date: format_date(active_personal_offer.end_date),
                    text: active_personal_offer.title,
                }))
            } else {
                setTimeout(() => {
                    // This personal offer doesn't exist (anymore); switch back
                    // to regular special offers and reset the personal offer.
                    Object.assign(filters.offertype, {
                        collapsed: false,
                        selection: 'SPECIALS',
                    })

                    filters.offer_hash.selection = ''
                }, 5000)
            }
        }

        filters.offer_hash.loading = false
    }

    set_title() {
        if (filters.offer_hash.selection) {
            $s.page.icon = 'specialOffers'
            const active_personal_offer = $s.offers.personal.find((i) => i.hash === filters.offer_hash.selection)
            if (active_personal_offer) {
                $s.page.title = $t('page.title.offer.personal_offer', {date: format_date(active_personal_offer.end_date)})
            } else {
                $s.page.title = $t('page.title.offer.personal_offer_invalid')
            }
        } else if (filters.offertype.selection === '') {
            $s.page.icon = 'storeSearch'
            $s.page.title = $t('page.title.checkout-0')
        } else if (filters.offertype.selection === 'SPECIALS') {
            $s.page.icon = 'specialOffers'
            $s.page.title = $t('page.title.offer.special_offers')
        } else if (filters.offertype.selection === 'NEW_ARRIVALS') {
            $s.page.icon = 'newArrivals'
            $s.page.title = $t('page.title.offer.new_arrivals')
        } else if (filters.offertype.selection === 'FAVORITES') {
            $s.page.icon = 'favorite-o'
            $s.page.title = $t('page.title.offer.my_favourites')
        }
        $s.context.title = $t('page.title.checkout-0')
    }

    view(_vn:m.Vnode<any>):m.Children {
        return (
            <div className="c-offer view-container minimizable-context-panel">
                <PanelFilters collection={collection}>
                    <IncentivePersonalOffer />
                </PanelFilters>
                <CollectionView
                    inactive={filters.offer_hash.selection && !$s.offers.personal.find((i) => i.hash === filters.offer_hash.selection)}
                    mode={collection.state.view.mode}
                >
                    <CollectionHeader>
                        <div className="actions">
                            {$s.env.layout === 'desktop' && <CollectionFilterControl collection={collection} />}
                            {$s.env.layout !== 'mobile' && <CollectionViewToggle collection={collection} />}
                            <PricelistDownload />
                            <CollectionSorter collection={collection}/>
                            <CollectionStatus collection={collection}/>
                        </div>
                    </CollectionHeader>

                    <CollectionItems
                        collection={collection}
                        item={(row) => <CollectionProduct
                            collection={collection}
                            key={row.case_artkey}
                            offer_item={row}
                            variant="offer"
                        />}
                    />
                </CollectionView>
                <PanelCart
                    count={$m.cart.cart_units}
                    icon="cart"
                    keep_open={true}
                    minimizable={true}
                    title={$s.page.title}
                />
            </div>
        )
    }
}
