import * as account from './models/account'
import * as offer from './models/offer'
import * as item from './models/item'
import * as sales_order from './models/sales_order'

export interface ModelsCommon {
    account: typeof account
    offer: typeof offer
    item: typeof item
    sales_order: typeof sales_order
}

export const models = {account, offer, item, sales_order} as ModelsCommon
