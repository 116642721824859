import {copy_object} from '@bitstillery/common/lib/utils'

export interface StateCommon {
    context: {
        count?: number | null
        /** The context which is edited; most of the times a Collection row */
        data: {}
        /** When opening a page; use this context by default */
        default: boolean
        icon?: string
        id: null | number
        loading: boolean
        key?: string
        mode: 'inline' | 'panel'
        /** This field is used also to determine the title from i18n */
        name: string
        /** A stepper proxy object; optionally assigned from PanelContext to control the stepper's flow from outside */
        stepper?: any
        /** The title of the context panel */
        title?: string

    }
    env: {
        browserName: string
        isBrowser: boolean
        isChrome: boolean
        isFirefox: boolean
        isSafari: boolean
        layout: string
        ua: string
    }
    identity: {
        artkey: string
        exp: string
        token: string
        user: {
            decimal_locale: string
        }
    }
    // a toggle that changes the listed price to include excise tax
    include_excise: boolean
    language: string
    // A generic value cache for ui-elements that need to persist a value that survives a page reload.
    lookup: {}
    notifications: []
    page: {
        breadcrumbs: []
        /** The header properties determine the vertical height of the view-container */
        header: {
            /**
             * The collapsed state of the header changes when scrolling the
             * view-container in order to save vertical space while scrolling
             */
            collapsed: boolean
            /** The first level menu becomes a panel on mobile to saver vertical space */
            menu: boolean
            /** The (optional) second level menu becomes a panel on mobile to save vertical space */
            submenu: boolean
        }
        help: boolean
        icon: string
        subtitle: string
        title: string
    }
    panels: {
        /** The collapsed state of the context panel is managed by a watcher on $s.context.name */
        context: {
            collapsed: boolean
        }
        left: {
            collapsed: boolean
        }
        view: {
            categories: {collapsed: boolean}
            filters: {collapsed: boolean}
        }
    }
    scheme: {
        changed: boolean
        version: string
    }
    session: {
        account: {
            slug: string
        }
    }
    settings: {
        beta: boolean
    }
    support: {
        collapsed: boolean
        manager: null | {}
    }
}

export const persistent = {
    context: {
        default: true,
    },
    identity: {
        artkey: null,
        exp: null,
        token: null,
    },
    issues: {
        collapsed: true,
    },
    language: '',
    lookup: {},
    panels: {
        context: {
            collapsed: false,
            title: '',
        },
        left: {
            collapsed: false,
        },
        view: {
            categories: {
                collapsed: false,
            },
            filters: {
                collapsed: false,
            },
        },
    },
    scheme: {
        changed: false,
        version: process.env.MSI_PACKAGE === 'discover' ? process.env.MSI_SCHEME_DISCOVER : process.env.MSI_SCHEME_PORTAL,
    },
    settings: {
        beta: false,
    },
    support: {
        collapsed: false,
        manager: null,
    },
}

export const session = {}

export const volatile = {
    context: {
        count: null,
        data: {},
        icon: '',
        id: null,
        loading: false,
        name: null,
    },
    env: {
        browserName: '',
        isBrowser: true,
        isChrome: false,
        isFirefox: false,
        isSafari: false,
        layout: 'desktop',
        ua: 'unknown',
    },
    identity: {
        user: {},
    },
    include_excise: false,
    page: {
        breadcrumbs: [],
        header: {
            collapsed: false,
            menu: false,
            submenu: false,
        },
        help: null,
        icon: '',
        subtitle: '',
        title: '',
    },
    notifications: [],
    session: copy_object(session),
}
