import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {Icon} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {FieldTextAreaAttrs} from '@bitstillery/common/types/field'

export class FieldTextArea extends MithrilTsxComponent<FieldTextAreaAttrs> {

    view(vnode: m.Vnode<FieldTextAreaAttrs>) {
        const validation = vnode.attrs.validation
        const invalid = validation ? validation._invalid : false
        const disabled = vnode.attrs.disabled
        return <div className={classes('c-field-textarea', 'field', vnode.attrs.className, {
            disabled: disabled,
            invalid: validation && invalid && validation.dirty,
            valid: validation && !invalid && validation.dirty,
        })}>
            {vnode.attrs.label && <label>{vnode.attrs.label}
                {vnode.attrs.icon && <Icon name={vnode.attrs.icon}/>}
                {vnode.attrs.validation && <span className="validation">{validation.label}</span>}
            </label>}
            <textarea
                disabled={vnode.attrs.disabled}
                oninput={(e) => {
                    if (vnode.attrs.validation) {
                        vnode.attrs.validation.dirty = true
                    }
                    let value = e.target.value
                    vnode.attrs.model[0][vnode.attrs.model[1]] = value
                }}
                placeholder={vnode.attrs.placeholder ? vnode.attrs.placeholder : ''}
                value={vnode.attrs.model[0][vnode.attrs.model[1]]}
            >
                {vnode.attrs.model[0][vnode.attrs.model[1]]}
            </textarea>
            {(() => {
                if (invalid && validation.dirty) {
                    return <div className="help validation">{typeof invalid.message === 'function' ? invalid.message() : invalid.message}</div>
                } else if (vnode.attrs.help) {
                    return <div class="help">{vnode.attrs.help}</div>
                }
            })()}
        </div>

    }
}
