{
    "added": "Toegevoegd",
    "back": "Terug",
    "bar_categories": {
        "title": "Categorieën"
    },
    "cart": {
        "active": "Actieve Winkelwagens",
        "booked": {
            "help": "Deze items zijn bevestigd en geboekt door jou en worden daarom door ons gereserveerd. Ze worden alleen ter referentie hier getoond. Neem contact met ons op als je nog een wijziging in je bestelling wilt doorgeven."
        },
        "checkout_button": "Uitchecken",
        "checkout_header": "Winkelwagen afrekenen",
        "checkout_one": "Uitchecken ({{count}})",
        "checkout_order": "Order uitchecken",
        "checkout_order_id": "Order {{id}} uitchecken",
        "checkout_other": "Uitchecken ({{count}})",
        "checkout_tip": "Je huidige winkelwagen uitchecken",
        "checkout_zero": "Uitchecken",
        "content_one": "Je hebt {{count}} product in je winkelwagen.",
        "content_other": "Je hebt {{count}} {{units}} in je winkelwagen.",
        "empty_booked_desc": "Nieuw toegevoegde producten worden hier getoond ter controle. Als je klaar bent, kun je vanaf hier je order afronden en producten reserveren.",
        "empty_booked_title": "Je hebt (nog) geen nieuw geselecteerde producten",
        "empty_desc": "Producten die toegevoegd zijn aan je winkelwagen komen hier te staan ter controle. Als je klaar bent, kun je vanaf hier je order afronden en producten reserveren.",
        "empty_title": "Producten toevoegen",
        "error_api": "Er is een fout opgetreden in je winkelwagentje.",
        "error_help": {
            "api": "Herlaad de pagina en/of verwijder het product met problemen. Neem contact op met support als het probleem aanhoudt.",
            "less_available": "Winkelwagen bijwerken verlaagt de hoeveelheid van het product naar de beschikbare hoeveelheid.",
            "minimum_quantity": "Winkelwagen bijwerken verhoogt de producthoeveelheid tot de minimale bestelhoeveelheid",
            "price_up": "Winkelwagen bijwerken verhoogt het item in de winkelwagen naar de juiste prijs",
            "unavailable": "Winkelwagen bijwerken verwijdert dit product uit de winkelwagen"
        },
        "error_less_available": "Minder beschikbaar",
        "error_minimum_quantity": "Minimum bestelhoeveelheid is van toepassing",
        "error_ok": "Producten klaar om verder te gaan",
        "error_one": "Dit product vereist je aandacht",
        "error_other": "Deze producten vragen je aandacht",
        "error_price_up": "De productprijs is verhoogd",
        "error_unavailable": "Niet beschikbaar",
        "issues": "Winkelwagen updates",
        "issues_action": "Verhelp de volgende productproblemen om je winkelwagen uit te checken.",
        "issues_none": "Je winkelwagen is klaar",
        "less_available_action": "Pas de hoeveelheid in je winkelwagen aan, of verwijder het product uit je winkelwagen om verder te gaan met de checkout.",
        "less_available_one": "Slechts {{count}} {{units}} beschikbaar op dit moment.",
        "less_available_other": "Slechts {{count}} {{units}} beschikbaar op dit moment.",
        "min_quantity": "Het minimum orderaantal voor dit product is {{count}} {{units}}.",
        "min_quantity_action": "Pas de hoeveelheid in je winkelwagen aan, of verwijder het product uit je winkelwagen om verder te gaan met de checkout.",
        "min_quantity_confirm": "Bevestig het minimum orderaantal: {{count}} {{units}}",
        "minus_all": "Verwijder {{product}} uit je winkelwagen.",
        "minus_one": "Verwijder {{count}} {{units}} uit je winkelwagen.",
        "minus_other": "Verwijder {{count}} {{units}} uit je winkelwagen.",
        "new": "Lege winkelwagen",
        "new_key": "Ik ben een Vertaling",
        "new_quantity_confirmed": "Nieuwe hoeveelheid bevestigd.",
        "panel": {
            "header": {
                "cart": "Je Winkelwagen ({{id}})",
                "cart_new": "Uw mandje"
            }
        },
        "plus_all": "Voeg {{product}} toe aan je winkelwagen",
        "plus_one": "Voeg {{count}} {{units}} toe aan winkelwagen",
        "plus_other": "Voeg {{count}} {{units}} toe aan winkelwagen",
        "price_changed": "De prijs van dit product is veranderd van {{old_price}} {{currency}} naar {{new_price}} {{currency}} .",
        "price_changed_action": "Bevestig de veranderde prijs of verwijder het product uit de winkelwagen om verder te gaan met de checkout.",
        "price_confirm": "Bevestig de nieuwe prijs.",
        "price_confirmed": "Nieuwe prijs bevestigd.",
        "price_up_agree_help_one": "Werkt je winkelwagen bij met de verhoogde prijs",
        "price_up_agree_help_other": "Werkt je winkelwagen bij met de verhoogde prijzen",
        "price_up_agree_one": "Ik accepteer de verhoogde prijs",
        "price_up_agree_other": "Ik accepteer de verhoogde prijzen",
        "product_amount": "Je winkelwagen bevat nu {{count}} {{units}} van {{product}}",
        "product_removed": "Product {{product}} is verwijderd uit je winkelwagen",
        "rounded_one": "Het aantal is afgerond op {{count}} case ({{bottles}} flessen)",
        "rounded_other": "Het aantal is afgerond op {{count}} cases ({{bottles}} flessen)",
        "soi": {
            "booked_title": "Gereserveerd voor jou",
            "cart_title": "De inhoud van je winkelwagen ({{count}} producten)"
        },
        "stock_one": "We kunnen {{count}} {{units}} aan je leveren",
        "stock_other": "We kunnen {{count}} {{units}} aan je leveren",
        "switched": "Je bent overgeschakeld naar winkelwagen {{cart}}",
        "switched_new": "Je bent overgeschakeld naar een nieuwe winkelwagen",
        "total_bottles": "Flessen totaal",
        "total_cases": "Dozen totaal",
        "total_price": "Totale kosten",
        "unavailable": "Helaas, dit product is niet meer beschikbaar.",
        "unavailable_action": "Verwijder het product uit je winkelwagen om door te gaan met de checkout.",
        "yours": "Je Winkelwagen"
    },
    "checkout": {
        "booked": {
            "types": {
                "arriving": "Aankomende artikelen",
                "stock": "Uit voorraad",
                "tbo": "Te bestellen"
            }
        },
        "cart": "Winkelwagen",
        "comment_delete_tip": "Opmerking Verwijderen",
        "comment_store": "Reactie opslaan",
        "comment_stored": "Commentaar opgeslagen",
        "comment_title": "Voeg een opmerking toe voor dit product",
        "delivery": {
            "asap": "Zo snel mogelijk",
            "date": "Op een specifieke datum"
        },
        "status": {
            "book": "Reserveer producten; bestelling later afronden",
            "book_help": "Producten worden voor je gereserveerd, je kunt later meer producten toevoegen en de order afronden.",
            "confirm_cart_update_help": "Alle winkelwagenupdates worden automatisch toegepast",
            "finalize": "Bestelling afronden",
            "finalize_help": "Je order wordt definitief en wordt gecontroleerd door ons; je kunt alvast de proforma factuur downloaden.",
            "step0_title": "Je winkelwagen",
            "step1_button": "Winkelwagen voltooien",
            "step1_button_fix": "Winkelwagen bijwerken",
            "step1_title": "Controleer winkelwagen",
            "step2_button": "Bestelling plaatsen",
            "step2_button_book": "Boek Producten",
            "step2_title": "Winkelwagen afrekenen"
        },
        "total": "Totaal",
        "vat": "BTW",
        "vouchers": {
            "label": "Vouchers"
        }
    },
    "dashboard": {
        "widgets": {
            "demo_user": {
                "context_1": "Met dit demo account kun je door de voorraad browsen, prijzen bekijken en prijslijsten downloaden. Je kunt je account upgraden door contact op te nemen met je sales manager. Met je volledige account kun je orders plaatsen en items als favorieten markeren.",
                "context_2": "Orders die met dit demo account aangemaakt worden, worden niet door ons verwerkt. Hier zitten dus geen kosten aan verbonden. Voel je vrij om wat rond te klikken! Mocht je vragen hebben, neem dan contact op met je sales manager. De details staan linksonder op de pagina.",
                "title": "Welkom bij ons portaal, leuk dat je er bent!"
            },
            "latest_orders": {
                "activate_order_tip": "Activeer Order",
                "active_order": "Huidige order",
                "stock_complete": "Alle artikelen zijn op voorraad"
            },
            "tips": {
                "title": "Tips & Tricks"
            }
        }
    },
    "demo": {
        "dialog": {
            "button_ok": "Oké dan!",
            "checkout_context_1": "Je hebt het gevonden! De pagina met de producten die je wilt bestellen en waar je de order kunt bevestigen is alleen niet functioneel met een demo account. Hiervoor heb je een volledig account nodig, dat je sales manager voor je kan instellen.",
            "checkout_context_2": "We horen graag wat je vindt van ons portaal. Op die manier kunnen we de serviceverlening naar jou toe nog beter maken. Mocht je vragen hebben, dan kun je contact opnemen met je sales manager. De gegevens van je sales manager staan onderin de navigatiebalk.",
            "checkout_title": "Checkout Pagina"
        },
        "favorites_disabled": "Helaas is het niet mogelijk om favorieten op te slaan met uw demo-account. Neem contact op met uw sales manager als u vragen heeft. De gegevens vindt u linksonder."
    },
    "email_address": "Emailadres",
    "excise": {
        "excluding": "excl. {{amount}} accijns",
        "including": "incl. {{amount}} accijns"
    },
    "filters": {
        "pricelist": {
            "alcohol_format": "Alcohol Formaat",
            "alcohol_format_help": "Hoe wil je het alcoholpercentage opmaken?",
            "cancel_download": "Annuleren",
            "download": "Download Prijslijst",
            "download_tip": "Exporteer prijslijst in Excel formaat (.xlsx)",
            "download_tip_busy": "Prijslijst wordt momenteel voor je samengesteld...",
            "filename": "prijslijst export",
            "products_selected": "Producten: {{count}}/{{total}}",
            "select_all": "Selecteer alle producten...",
            "volume_format": "Volume Formaat",
            "volume_format_help": "Hoe wil je het flesvolume opmaken?"
        },
        "reset_one": "Filter uitzetten",
        "reset_other": "Filters uitzetten",
        "selected_one": "{{count}} geselecteerde",
        "selected_other": "{{count}} geselecteerde",
        "title": {
            "alcohol": "Alcohol Percentage",
            "availability": "Beschikbaarheid",
            "offertype": "Aanbiedingstype",
            "packaging": "Verpakking",
            "refill": "Navulbaar",
            "search": "Zoeken",
            "volume": "Inhoud"
        },
        "types": {
            "availability": {
                "eta": "Verwachte aankomst: {{period}}",
                "purchase": "Binnenkomend",
                "stock": "Op voorraad",
                "tbo": "Te bestellen"
            },
            "offertype": {
                "FAVORITES": "Favorieten",
                "NEW_ARRIVALS": "Net binnen",
                "SPECIALS": "Aanbiedingen"
            },
            "products": "Producten",
            "refill": {
                "nonref": "Niet navulbaar",
                "ref": "Navulbaar"
            }
        }
    },
    "help": {
        "explain": "Leg deze pagina uit"
    },
    "loading": "Laden",
    "location": "Locatie",
    "messroom": {
        "description": "Dit is een eenmalige instelling om de winkelervaring in ons portaal te optimaliseren, terwijl we je de meest recente prijs- en beschikbaarheidsinformatie bieden. Wacht even totdat we je portaalaccount hebben voorbereid. Hartelijk dank voor uw geduld!",
        "step0": "Account voorbereiden",
        "step1": "Beschikbaarheid bijwerken",
        "step2": "Prijzen genereren",
        "steps_completed": "Klaar! Je wordt nu doorgestuurd naar het portaal...",
        "steps_processing": "Wacht even terwijl we je portaalaccount voorbereiden...",
        "team": "Team {{team}}",
        "title": "Eenmalig portaal instellen"
    },
    "my_portal": {
        "news": "Nieuws",
        "privacy": "Privacy",
        "profile": "Mijn Profiel",
        "promotions": "Promoties",
        "security": {
            "label": "Beveiliging",
            "notifications": {
                "current_password_invalid": "Je huidige wachtwoord is ongeldig",
                "new_password_invalid": "Je nieuwe wachtwoord is ongeldig",
                "password_updated": "Je wachtwoord is bijgewerkt; log opnieuw in."
            },
            "password": {
                "current": "Huidig Wachtwoord",
                "current_help": "Je huidige wachtwoord is vereist om het te kunnen aanpassen",
                "new": "Nieuw Wachtwoord",
                "new_help": "Wachtwoord is tenminste 8 tekens lang",
                "repeat": "Herhaal Wachtwoord",
                "repeat_help": "Na het wijzigen van je wachtwoord wordt je gevraagd om opnieuw in te loggen."
            },
            "save_button": "Wijzig Wachtwoord"
        },
        "settings": {
            "label": "Instellingen",
            "price_quantity": "Prijzen en Hoeveelheden",
            "price_quantity_help": "Gebruik fles- of doosprijzen in het portaal",
            "save": "Instellingen Bijwerken",
            "saved_profile": "Uw profiel is opgeslagen",
            "saved_profile_error": "Je profiel kon niet worden bijgewerkt, probeer het later nog eens",
            "saved_settings": "Uw instellingen zijn opgeslagen"
        }
    },
    "no": "Nee",
    "notifications": {
        "cart_api_error": "Sorry, er was een onverwacht probleem tijdens het bijwerken van je winkelwagen. Probeer het portaal opnieuw te laden. We zijn er al mee bezig, maar aarzel niet om contact op te nemen met support als het probleem zich blijft voordoen.",
        "comment_deleted": "Commentaar verwijderd",
        "comment_stored": "Commentaar opgeslagen",
        "invite_failure": "Je uitnodigingscode is verlopen of niet meer geldig.",
        "messroom_ready": "Je portaal is klaar voor gebruik!",
        "news": {
            "title": "Nieuws Update!"
        },
        "order_booked": "Je producten in bestelling ({{id}}) zijn door ons gereserveerd!",
        "order_confirmed": "Je bestelling ({{id}}) is door ons bevestigd!",
        "order_failure": "Er is een probleem opgetreden bij het laden van bestelling {{order}}; Probeer het later nog eens.",
        "order_issue": "Er is een probleem met één of meer artikelen in je order; controleer de checkout waarschuwingen.",
        "order_repeated": "Orderregels uit bestelling {{order}} zijn gekopieerd naar je winkelwagen",
        "promotion_redeemed": "Gefeliciteerd! Je hebt zojuist je promotie verzilverd!",
        "removed_product": "Product {{product}} verwijderd uit je winkelwagen",
        "session_expired": "Je portaal sessie is verlopen; je moet helaas opnieuw inloggen"
    },
    "offer": {
        "arriving_soon": "Bijna Binnen",
        "best_deal": "Beste Deal",
        "delivery_week_one": "{{count}} week",
        "delivery_week_other": "{{count}} weken",
        "download_offer": "Aanbieding Downloaden",
        "expired": {
            "description": "Helaas is de persoonlijke aanbieding die je bekijkt niet meer geldig. Bekijk onze voorraadpagina voor andere aanbiedingen.",
            "title": "Aanbod verlopen"
        },
        "latest_orders": "Je laatste orders",
        "latest_orders_empty": "Je meest recente verkooporders staan hier",
        "loading_products": "Producten Ophalen...",
        "my_favourites": "Mijn Favorieten",
        "my_favourites_add": "Aan Mijn Favorieten toevoegen",
        "my_favourites_help": "Markeer favorieten met het hart icoontje. Deze producten komen hier terug en onder \"Mijn Favorieten\".",
        "my_favourites_remove": "Uit Mijn Favorieten verwijderen",
        "my_favourites_tip": "Mijn favorieten - een selectie van je favoriete producten",
        "name": "Promotie",
        "new_arrivals": "Net Binnen",
        "new_arrivals_tip": "Net Binnen - onze nieuwste producten; net binnen of binnenkort beschikbaar",
        "newest_arrivals": "Nieuwste binnengekomen",
        "no_personal": "Geen persoonlijke aanbiedingen op dit moment",
        "personal": "Je Persoonlijke Aanbiedingen",
        "personal_not_found": "Helaas is deze speciale aanbieding niet meer beschikbaar; heb je onze andere aanbiedingen al gezien?",
        "personal_valid": "Een persoonlijk aanbod voor jou: '{{text}}'\n",
        "popup": {
            "continue": "Naar Dashboard",
            "show_offer": "Toon Aanbieding"
        },
        "show_all": "Toon alle",
        "special": "Aanbiedingen",
        "special_count": "Aanbiedingen",
        "special_tip": "Aanbiedingen - producten met een speciale prijs, die een beperkte periode geldig zijn",
        "valid_until": "Geldig tot {{date}}",
        "view_offer": "Toon Aanbieding"
    },
    "order": {
        "agree_t1": "Heeft producten met douanestatus T1",
        "agree_tos": "Ik ga akkoord met de <a href={{link}} target=\"_blank\">voorwaarden</a>",
        "completion": "Bestelling afronden",
        "confirmed": {
            "description": "Nu is het onze beurt; wij zorgen ervoor dat de goederen bij jou terechtkomen. Houd in de tussentijd onze voorraad in de gaten voor meer leuke aanbiedingen. Aarzel niet om contact met ons op te nemen als je vragen hebt over je bestelling.",
            "subtitle": "Je bestelling is succesvol geplaatst",
            "title": "Bedankt!"
        },
        "delivery": "Bezorgingsvoorkeur",
        "delivery_date": "Bezorgingsdatum",
        "details": {
            "actions": {
                "download_delivery_note": "Download Afleveringsbon",
                "download_invoice": "Download Factuur",
                "download_order_note": "Download Orderbon",
                "download_proforma": "Download Proforma",
                "repeat_order": "Naar winkelwagen",
                "repeat_order_tip": "Kopieert de inhoud van deze bestelling naar je winkelwagen. Dit kan alleen worden gedaan voor producten en hoeveelheden die op dit moment op voorraad zijn."
            },
            "additional": {
                "description": "Beschrijving",
                "price_unit": "Prijs per stuk",
                "quantity": "Hoeveelheid",
                "type": "Type"
            },
            "additionals": "Extra's en kortingen",
            "booked": "Geboekt",
            "booked_context": "Deze artikelen zijn door jou bevestigd en geboekt en worden daarom door ons gereserveerd.",
            "cart": "Winkelwagen",
            "cases": "Dozen",
            "comment": "Opmerking",
            "created_by": "Aangemaakt door",
            "credit": {
                "credited_from": "Gecrediteerd Van",
                "label": "Tegoed Artikelen"
            },
            "date": "Datum",
            "empty": "Je order bevat nog geen producten",
            "empty_context": "Voeg producten toe aan je winkelwagen vanuit het voorraad overzicht.",
            "invoice_date": "Factuur datum",
            "invoice_number": "Factuur nummer",
            "items": {
                "arriving": "Snel Binnen",
                "basket_unconfirmed": "Winkelwagen (onbevestigd)",
                "basket_unconfirmed_help": "Deze producten moeten nog door je bevestigd worden, voordat we ze voor je kunnen reserveren. Bevestig of boek je order om deze producten te bevestigen.",
                "stock": "Vanuit Voorraad",
                "tbo": "Bestellen we voor je"
            },
            "order_number": "Order Nummer",
            "order_status": "Order Status",
            "price": "Prijs",
            "product": "Product",
            "quantity": "Hoeveelheid",
            "specs": "Specificaties",
            "status": "Status",
            "subtotal": "Subtotaal",
            "total_value": "Totale Waarde",
            "vat": "BTW"
        },
        "empty_call_to_action": "Doorzoek Voorraad",
        "loading_orders": "Orders aan het laden...",
        "new": "Nieuw",
        "tos": "Algemene voorwaarden"
    },
    "orders": {
        "invoice_date": "Factuurdatum",
        "invoice_number": "Factuur Nummer",
        "no_orders": "Je hebt nog geen bestellingen",
        "portal_status": {
            "Cancelled": "Geannuleerd",
            "Confirmed": "Bevestigd",
            "Finalized": "Afgerond",
            "Invoiced": "Gefactureerd",
            "New": "Nieuw",
            "Pending": "Open"
        },
        "status": "Status Bestelling",
        "status_active": "Actief",
        "status_open": "Open"
    },
    "page": {
        "browse_stock_tip": "Door voorraad bladeren - overzicht van al onze producten",
        "dashboard": "Dashboard",
        "dashboard_tip": "Dashboard - overzicht van onze beste deals & aanbiedingen",
        "latest_news": "Laatste nieuws",
        "my_orders": "Mijn Bestellingen",
        "my_orders_tip": "Mijn Bestellingen - overzicht van al je bestellingen",
        "my_portal": "Mijn Portaal",
        "my_portal_tip": "Mijn portaal - nieuws, profiel & instellingen",
        "my_portal_tip_promotion": "Mijn portal - er is een promotie!",
        "subtitle": {
            "login": "Login om door te gaan...",
            "privacy": "We verkopen spirits; niet je data!",
            "redeem": "Meld je aan met je unieke code...",
            "registration": "Vertel ons wat meer over je bedrijf...",
            "registration_completed": "Je kunt dit scherm nu sluiten."
        },
        "title": {
            "checkout": "Checkout",
            "checkout-0": "Producten zoeken",
            "checkout-1": "winkelwagen controleren",
            "checkout-2": "Winkelwagen voltooien",
            "login": "Welkom bij {{vendor}}!",
            "login_slug": "Inloggen",
            "offer": {
                "my_favourites": "Favorieten",
                "new_arrivals": "Net binnen",
                "personal_offer": "Persoonlijk aanbod - tot {{date}}",
                "personal_offer_invalid": "Persoonlijke aanbieding verlopen",
                "special_offers": "Speciale aanbiedingen"
            },
            "order": "Order {{id}}",
            "privacy": "Ons Privacy Beleid",
            "redeem": "Leuk dat je er bent!",
            "registration": "Registratie",
            "registration_completed": "Bedankt voor je aanmelding!"
        }
    },
    "product": {
        "alcohol_percentage": "Alcohol percentage",
        "best_before": "Tenminste houdbaar",
        "bottle_gtin": "GTIN fles",
        "bottle_volume": "Inhoud fles",
        "bottles": "Flessen",
        "bottles_per_case": "Flessen per doos",
        "cases": "Dozen",
        "category": "Categorie",
        "country_of_origin": "Land van herkomst",
        "cpp_cpl_tip": "Palletindeling zoals aangeleverd door ons magazijn",
        "customs_status": "Douane status",
        "features": "Kenmerken",
        "minimum_order_quantity": "Minimale bestelhoeveelheid",
        "refill_status": "Hervulbaarheid",
        "stock_origin": "Dit product is afkomstig van {{country}}",
        "stock_origin_count_one": "We kunnen je {{count}} {{units}} van deze oorsprong leveren",
        "stock_origin_count_other": "We kunnen je {{count}} {{units}} van deze oorsprong leveren",
        "weight_tip": "Gewicht van de doos"
    },
    "profile": {
        "company": "Je Bedrijf",
        "email_support": "Ondersteuning emailadres",
        "family_name": "Achternaam",
        "first_name": "Voornaam",
        "mobile_phone_number": "Mobiel telefoonnummer",
        "new_password": "Kies een wachtwoord",
        "new_password_help": "Het wachtwoord dat je wilt gebruiken",
        "new_password_placeholder": "Kies een wachtwoord",
        "phone_email": "Telefoonnummer of emailadres",
        "phone_number": "Telefoonnummer",
        "portal_welcome_desc": "Log in om door te gaan...",
        "portal_welcome_title": "Welkom bij ons portal!",
        "register": "Registreer gratis account",
        "removal": {
            "ack": "Je verzoek is verzonden!",
            "ack_description_1": "We komen zo snel mogelijk bij je terug; uiterlijk binnen 4 weken.",
            "ack_description_2": "We zullen je missen! Mocht je per ongeluk dit verzoek hebben verstuurd, laat je het ons dan even weten via {{email_vendor}} of via je persoonlijke support manager {{manager}} ({{email}} of {{phone}}).",
            "cancel": "Annuleer Verzoek",
            "close_window": "Sluit Dialoog",
            "confirm": "Bevestig Verzoek"
        },
        "removal_description_1": "Wanneer je besluit dat je door wilt gaan met je verwijderingsverzoek, zullen we zo snel mogelijk (uiterlijk binnen 4 weken) ingaan op je verzoek. We informeren je met betrekking tot de vervolgacties. Mochten we hier onverhoopt in gebreke blijven, dan kun je een klacht indienen bij de Autoriteit Persoonsgegevens.",
        "removal_description_2": "Voordat je dit verwijderingsverzoek bevestigd, vragen we je of we met een ander contactpersoon binnen je bedrijf contact op mogen nemen in jouw plaats. Wil je een telefoonnummer of emailadres voor ons achterlaten? Graag alleen gegevens invullen wanneer je toestemming hebt van de nieuwe contactpersoon. Dit is uiteraard niet verplicht om in te vullen.",
        "removal_notice": "Lees dit alsjeblieft zorgvuldig",
        "removal_questions": "Voor meer informatie, vragen of opmerkingen kun je ons altijd mailen ({{email_vendor}}) of contact opnemen met je  persoonlijke support manager, {{manager}} via email ({{email}}) of telefoon ({{phone}}).",
        "removal_title": "We zullen je missen!",
        "replacement": {
            "contact": "Contactgegevens",
            "name": "Contactpersoon"
        },
        "request_password": "Wachtwoord vergeten?",
        "request_removal": "Verwijderingsverzoek",
        "save_button": "Profiel Bijwerken"
    },
    "promotions": {
        "active": "Actief tussen: {{date}}",
        "active_grace": "Promotie kan worden ingewisseld tot: {{date}}",
        "call_to_action": "Bestel direct!",
        "confirmed": {
            "description": "Nu is het onze beurt; wij zorgen ervoor dat de goederen bij jou terechtkomen. Houd in de tussentijd onze voorraad in de gaten voor meer leuke aanbiedingen. Aarzel niet om contact met ons op te nemen als je vragen hebt over je bestelling.",
            "subtitle": "Je promotie is ingewisseld!"
        },
        "description": "We hebben weer een geweldige promotie voor je! Word een echte barista door koffiebonen te sparen bij elke {{token_value}} die je uitgeeft. Er zijn verschillende koffiemachines die je kunt verdienen.",
        "details": "Klik om de promotiedetails te bekijken",
        "eligible_multiple": "Eén actieve boon vertegenwoordigt {{token_value}} aan verkooporderwaarde die is bevestigd of gefactureerd door je verkoopmanager. Je hebt {{turnover_draft}} aan verkooporderwaarde verzameld, waarvan {{turnover_redeemable}} al is bevestigd of gefactureerd.",
        "gifts": {
            "available": "Beschikbare geschenken",
            "choose": "Je Aanbieding",
            "choose_help": "Je mag een aanbieding kiezen wanneer je in aanmerking komt voor de promotie.",
            "disabled": "Je mag een cadeau kiezen",
            "locked": "Ontgrendel je aanbieding door {{amount}} toe te voegen aan je order",
            "locked_placeholder": "Ontgrendel je aanbieding eerst...",
            "unlocked": "Gefeliciteerd! Je hebt een cadeau ontgrendeld!",
            "unlocked_choice_made": "{{choice}} - Goede keus!",
            "unlocked_choose": "Kies je cadeau hieronder...",
            "unlocked_placeholder": "Kies je cadeau...",
            "unlocked_redirect": "Gefeliciteerd! Je hebt een cadeau ontgrendeld! Kijk bij de <a href={{link}}>promotie pagina</a> voor meer informatie over de beschikbare cadeauopties..."
        },
        "giveaway": "Bestel {{spent_next}} {{currency}} om een koffieboon te verdienen!",
        "giveaway_encourage": "Bestel {{spent_next}} {{currency}} meer en claim een koffieboon!",
        "incentive_hurry_one": "Nog maar {{count}} dag!",
        "incentive_hurry_other": "Nog maar {{count}} dagen!",
        "incentive_progress": "Bestel {{amount}} meer voor een koffieboon!",
        "label": {
            "dialog_subtitle": "Ooit barista willen worden? Dit is je kans! Verdien een koffieboon voor elke {{token_value}} die je bestelt en kies uit een van de drie fantastische koffiemachines! En als klap op de vuurpijl krijg je er maar liefst 6 kg biologische Arabica koffiebonen bij! Spaar je bonen en maak je eigen heerlijke Americano, Latte of Expresso!",
            "promotion": "Promotie",
            "redeemable_tokens": "Verzamelde bonen",
            "show_promotion": "Toon promotie"
        },
        "order_reminder": "Je bent bijna klaar om barista te worden! Zodra je {{token_value}} hebt bereikt, kun je je promotie inwisselen.",
        "order_reminder_eligible": "Je bent nog maar één stap verwijderd van het worden van een barista! We zijn je bestelling aan het bekijken en je ontvangt binnen 24 uur een bevestigingsmail van ons. Zodra je deze e-mail hebt ontvangen, kun je je koffiebonen inwisselen. Bedankt voor je geduld!",
        "redeem": "Inwisselen",
        "terms": {
            "conditions": "Algemene voorwaarden",
            "period": "Promotieperiode: {{period}}",
            "terms_0": "Promotie is geldig voor bestellingen via ons portaal; we tellen al je afgeronde bestellingen gedurende deze periode.",
            "terms_1": "Zodra je hebt besteld en in aanmerking komt om een koffieboon te ontvangen, kun je meteen een espressomachine bestellen via het portaal, die we aan je bestelling toevoegen.",
            "terms_2": "De aangeboden espressomachines zijn onder voorbehoud van voorraad. Als de geselecteerde modellen niet beschikbaar zijn, selecteren we in overleg met jou een vergelijkbaar / gelijkwaardig model.",
            "terms_3": "Wij zijn niet verantwoordelijk voor de informatie die de fabrikant op zijn website geeft. Dit is puur ter informatie.",
            "terms_4": "Het totaalbedrag is het bedrag van de bestelde en betaalde goederenwaarde per factuur. Extra gefactureerde kosten tellen niet mee voor de promotie (verzending / transport / pallets).",
            "terms_5": "Productgarantie is alleen van toepassing in Nederland.",
            "terms_6": "Een espressomachine kan niet worden ingeruild voor een korting in contanten.",
            "terms_7": "Deze aanbieding is met zorg voor je gemaakt, maar er kunnen typefouten in staan."
        }
    },
    "registration": {
        "address": "Adres",
        "address_placeholder": "Bedrijfsadres",
        "agree": "Ja, ik stem in met de&nbsp;<a href={{link}} target=\"_blank\">algemene voorwaarden</a>",
        "back_login": "Terug naar login",
        "city": "Stad",
        "city_placeholder": "Bedrijfsstad",
        "company_details": "Bedrijfsgegevens",
        "company_name": "Bedrijfsnaam",
        "company_name_placeholder": "Je Bedrijfsnaam",
        "consent": "Emails en aanbiedingen van {{vendor}}",
        "country": "Land",
        "country_help": "Het land waar je zaken doet",
        "email_address": "Je Emailadres",
        "email_address_help": "Het emailadres waarop we je kunnen bereiken",
        "explanation": "{{vendor}} levert aan zakelijke klanten in heel Europa en daarbuiten. Als gewaardeerde klant van {{vendor}} krijg je toegang tot ons bestelportaal. Geniet 24/7 van de voordelen die het biedt, waaronder altijd inzicht in ons uitgebreide assortiment. Een bestelling plaatsen is eenvoudig: zoeken, kiezen, klikken en klaar!",
        "family_name": "Achternaam",
        "family_name_placeholder": "Je Achternaam",
        "first_name": "Voornaam",
        "first_name_placeholder": "Je Voornaam",
        "import_goods_temp_license": "Ik importeer goederen met een tijdelijke licentie",
        "invite": "Uitnodigingen",
        "message": "Vragen?",
        "message_placeholder": "Heb je vragen voor ons? Voel je vrij om die hier te stellen...",
        "name": "Je Naam",
        "position": "Je Bedrijfsrol",
        "position_placeholder": "Je Bedrijfsrol",
        "postal_code": "Postcode",
        "postal_code_placeholder": "Bedrijf Postcode",
        "redeem": {
            "code": "Uitnodigingscode",
            "code_help": "Je unieke code voor toegang tot ons portaal",
            "partnership": {
                "footer": "With love for our planet",
                "header": "{{vendor}} is een trotse partner van {{partner}}."
            },
            "step0_desc": "Ben je net zo enthousiast over sterke dranken als wij? Log in met je persoonlijke code om je cadeau te claimen en toegang te krijgen tot ons uitgebreide, gevarieerde en scherp geprijsde assortiment.",
            "step0_slug": "Samen maken we de wereld beter",
            "step1_desc": "Vul hieronder je e-mailadres in en kies een wachtwoord. Tijd om aan de slag te gaan!",
            "step1_slug": "Je gegevens",
            "step2_desc": "Kloppen de onderstaande gegevens? Druk dan op ‘registreer en login’ en je komt direct terecht in onze portal.",
            "step2_slug": "Overzicht van je registratie",
            "step3_slug": "Registreer & Login"
        },
        "review_request": "Controleer alsjeblieft de informatie die je hebt ingevuld. Als alles klopt kun je je registratie voltooien, waarna we zo snel mogelijk van ons laten horen.",
        "signup": "Word Klant",
        "step0_desc": "{{vendor}} levert aan de Business-Business markt. Voor welk bedrijf wil je een account aanvragen?",
        "step0_slug": "Je Bedrijf",
        "step1_desc": "Vul aub het adres in van je bedrijf.",
        "step1_slug": "Bedrijfsadres",
        "step2_desc": "Hoe kunnen we contact opnemen met je?",
        "step2_slug": "Contactgegevens",
        "step3_desc": "Gebruik je de faciliteiten van een bonded warehouse in de EU?",
        "step3_desc_vat": "Vul je BTW-nummer en warehousing voorkeuren in.",
        "step3_slug": "Logistieke Gegevens",
        "step4_desc": "Controleer de gegevens die je hebt ingevuld, voordat je ze naar ons opstuurt. Gebruik het veld hieronder om vragen aan ons te stellen.",
        "step4_slug": "Registratie Controleren",
        "step5_desc": "We controleren de bedrijfsgegevens die je met ons hebt gedeeld. Dit proces duurt meestal een paar dagen. In het geval dat we nog wat extra informatie nodig hebben, zullen we nog persoonlijk contact opnemen. Na bevestiging krijg je een email met daarin de bevestiging van de registratie.",
        "step5_footer": "We kijken uit naar je eerste online bestelling!",
        "step5_incentive": "Wist je dat we ons eigen <a target=\"_blank\" href=\"https://www.youtube.com/@movingspirits474/videos\">Youtube kanaal</a> hebben?",
        "step5_notify": "Bedankt voor je aanvraag! We verwerken je aanvraag binnen een paar werkdagen.",
        "step5_slug": "Aanvraag Versturen",
        "step5_title": "Hoe nu verder?",
        "submit": "Voltooi Registratie",
        "telephone": "Je Telefoonnummer",
        "telephone_placeholder": "+31 502110909",
        "terms_conditions": "Algemene voorwaarden",
        "vat_number": "BTW-nummer",
        "vat_number_help": "BTW-nummer is vereist in EU-landen",
        "vat_number_placeholder": "BTW nummer",
        "warehouse": {
            "address": "Warehouse Adres",
            "address_help": "Adres van je warehouse",
            "address_unloading": "Uitlaadadres",
            "address_unloading_help": "De locaties waar we de bestelling kunnen uitladen",
            "bonded_eu": "Bonded warehouse in de EU?",
            "city": "Warehouse Stad",
            "city_unloading": "Uitlaad Stad",
            "excise_id": "Warehouse Accijns ID",
            "location_id": "Warehouse Locatie ID",
            "name": "Warehouse Naam",
            "name_unloading": "Uitlaad",
            "options": {
                "bonded": "Ik heb mijn eigen bonded warehouse",
                "inc_vat": "Ik koop inclusief BTW en accijns uit Nederland",
                "other": "Andere",
                "temp_license": "Ik importeer goederen met een tijdelijke licentie",
                "third_party": "Ik gebruik een third-party warehouse partner"
            },
            "postal_code": "Warehouse Postcode",
            "postal_code_unloading": "Uitlaad Postcode",
            "select": "Selecteer Warehouse",
            "shipping": {
                "free_shipping": "Hartstikke mooi! We bezorgen je order gratis.",
                "incentive": "Voeg <b>{{amount}}</b> toe aan je order voor gratis bezorging. Voor orders onder de <b>{{threshold}}</b>, worden transport kosten toegevoegd aan je proforma invoice, die je daarna nog moet bevestigen."
            },
            "warehousing": "Warehousing"
        },
        "website": "Bedrijfswebsite",
        "website_placeholder": "je-bedrijf.com"
    },
    "search": {
        "categories": {
            "category": "categorie",
            "search": "productnaam"
        },
        "exact": "Zoek op productnaam of GTIN met term \"{{term}}\".",
        "placeholder": {
            "general": "Waar ben je naar op zoek?",
            "raw": "Zoek op productnaam of GTIN",
            "raw_term": "Zoek op productnaam of GTIN met term \"{{term}}\".",
            "suggestion": "Zoek op {{type}} \"{{value}}\""
        },
        "suggestions": {
            "activate": "<Ctrl><Enter> voor exact zoeken"
        }
    },
    "select": "Selecteer",
    "spotlight": {
        "countdown": {
            "still_unsold": "Nog niet verkocht",
            "valid_for": "Aanbod nog geldig:"
        }
    },
    "status": {
        "error": {
            "redeem": {
                "invalid": "Helaas, de ingevoerde code is niet juist. Probeer het opnieuw. ",
                "length": "Uitnodigingscode is 6 tekens lang",
                "unknown": "Er is iets misgegaan tijdens het controleren van de code; probeer het later nog eens.",
                "used": "Deze uitnodigingscode is al gebruikt om een account aan te maken."
            }
        }
    },
    "view": {
        "grid": "Grid",
        "grid_switch": "Naar grid weergave",
        "list": "Lijst",
        "list_switch": "Naar lijst weergave"
    },
    "yes": "Ja"
}