import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {proxy} from '@bitstillery/common/lib/proxy'
import {Button, FieldText} from '@bitstillery/common/components'
import {and, email, required, validation} from '@bitstillery/common/lib/validation'
import {$s, $t, api, notifier} from '@bitstillery/common/app'

import {ForgotPasswordRequest, GetPortalOfferItemResponse} from '@/factserver_api/fact2server_api.ts'

export class AuthForgotPassword extends MithrilTsxComponent<any> {

    data = (() => {
        const $f = {
            email_address: '',
        }
        return proxy({$f})
    })()

    $v = {
        email_address: validation([this.data.$f, 'email_address'], and([required(), email()])),
    }

    request_id = m.route.param('request_id')

    constructor() {

        super()
        $s.page.title = $t('page.title.forgot_password', {vendor: process.env.MSI_TITLE})
        $s.page.subtitle = $t('page.subtitle.forgot_password')
    }

    async submit() {
        if (process.env.MSI_PACKAGE === 'portal') {
            const data: ForgotPasswordRequest = {
                email_address: this.data.$f.email_address,
            }
            await api.post<GetPortalOfferItemResponse>('portal/user/forgot-password', data, true)
        } else {
            const data:any = {
                username: this.data.$f.email_address,
            }
            await api.post('auth.forgot_password', data)
        }

        notifier.notify($t('forgot_password.confirmed'), 'info')
        m.route.set('')
    }

    view() {
        return (
            <div className="c-auth-forgot-password landing">
                <div className="form">
                    <h3>{$t('forgot_password.title')}</h3>
                    <p className="step-info">{$t('forgot_password.description')}</p>

                    <FieldText
                        label={$t('forgot_password.email')}
                        model={[this.data.$f, 'email_address']}
                        help={$t('forgot_password.email_help')}
                        validation={this.$v.email_address}
                    />

                    <Button
                        disabled={!!Object.values(this.$v).filter((i) => i && i._invalid).length}
                        onclick={this.submit.bind(this)}
                        text={$t('forgot_password.start_flow')}
                        type="success"
                    />
                </div>
            </div>
        )
    }
}
