{
    "collection": {
        "bulk": {
            "tip": {
                "cancel": "Cancelar edición masiva",
                "cancel_changes": "Cancelar edición masiva (deshacer todos los cambios no realizados)",
                "persist_one": "Persistir elemento masivo modificado",
                "persist_other": "Persistir {{count}} elementos masivos modificados",
                "toggle": "Activar el modo de edición masiva"
            }
        },
        "exclude_label": "Excluye",
        "exclude_one": "Excluye el artículo {{count}} ",
        "exclude_other": "Excluye los artículos de {{count}} ",
        "exclude_tip": "Excluir elementos; {{count}} elementos incluidos actualmente",
        "include_label": "Incluye",
        "include_one": "Incluye {{count}} item",
        "include_other": "Incluye {{count}} artículos",
        "include_tip": "Incluir elementos; {{count}} elementos ya incluidos",
        "modify_selection": "Modificar selección",
        "no_results": "No se han encontrado resultados",
        "no_results_clear_filters": "¿Has probado a borrar los filtros activos?"
    },
    "contact": {},
    "data_card": {
        "text": "Tarjeta de datos",
        "tip": "Alternar Tarjeta de Datos"
    },
    "dialog": {
        "cancel": "Cancelar"
    },
    "filters": {
        "actions": {
            "clear_filter_selection": "Limpiar filtro: {{name}}/{{selection}}",
            "deactivate_filter": "Desactivar filtro",
            "pricelist": "Lista de precios"
        },
        "clear": "Limpiar filtros",
        "collapse": "Colapsar filtros",
        "collapse_tip": "Contraer todos los filtros",
        "expand": "Expandir filtros",
        "expand_tip": "Ampliar todos los filtros",
        "inactive_filter": "El filtro está inactivo",
        "no_results": "No hay productos",
        "no_results_hint": {
            "filters": "¿Modificar tus filtros activos?",
            "search": "¿Modificar tus términos de búsqueda?"
        },
        "no_selection": "No hay elementos seleccionados",
        "panel_title": "Filtros",
        "personal_offer": "Personal",
        "reset_disabled": "Activa los filtros para utilizar esta opción",
        "reset_one": "Restablecer filtro",
        "reset_other": "Restablece los filtros de {{count}} ",
        "sort": {
            "direction": "Orden de clasificación",
            "direction_tip": "Cambiar el orden de clasificación",
            "name": "Clasificar",
            "options": "Opciones de clasificación",
            "reset": "Restablecer ordenación",
            "types": {
                "avg_purchase_price": "Precio medio de compra",
                "created_by": "Creado por",
                "created_on": "Creado el",
                "delivery_period": "Plazo de entrega",
                "entry_date": "Fecha de entrada",
                "invoice_number": "Número de factura",
                "is_invoiced_on": "Fecha de la factura",
                "item_stock_age": "Edad de las existencias",
                "item_throughput": "Rendimiento",
                "last_price_change_age": "Último cambio de precio",
                "list_price": "Precio de venta",
                "list_quantity": "Cantidad",
                "lowest_price_competitors": "El precio más bajo de la competencia",
                "margin_percentage": "Margen",
                "market_score": "Rango",
                "number_of_cases": "Cantidad",
                "number_of_cases_available": "Disponibilidad",
                "portal_status": "Estado",
                "price_age_in_days": "Precio edad (días)",
                "price_per_case": "Precio por caja",
                "product_name": "Nombre del producto",
                "reference": "Referencia",
                "stock_age": "Edad de las existencias",
                "total_value": "Valor total"
            }
        },
        "title": {
            "cases_available": "Número de casos disponibles",
            "category": "Categoría",
            "customs_status": "Estatuto aduanero",
            "flags": "Propiedades del artículo",
            "item_type": "Tipo de artículo",
            "offer_hash": "Ofertas personales",
            "price_age": "Precio edad",
            "properties": "Propiedades",
            "purchase_history_selection_overall": "Historial de compras en general",
            "purchase_history_selection_supplier": "Historial de compras proveedor",
            "relation": "Relación",
            "relation_type": "Tipo de relación",
            "sales_history_selection": "Historial de ventas",
            "stock_age_range": "Edad de las existencias",
            "stock_availability_selection": "Acciones",
            "suppressed_selection": "Selección suprimida"
        },
        "types": {
            "category": "Categorías",
            "packaging": {
                "+ Mug": "+ Taza",
                "2 Glasses": "2 vasos",
                "2 Golden Glasses": "2 Vasos dorados",
                "Aluminium Bottle": "Botella de aluminio",
                "Bottle": "Botella",
                "Can": "Puede",
                "Cannister": "Bote",
                "Cradle": "Cuna",
                "Flask": "Frasco",
                "Frosted Bottle": "Botella esmerilada",
                "GB + Jar of Cherries": "GB + Tarro de Cerezas",
                "GB with Cradle": "GB con cuna",
                "Giftbag": "Bolsa de regalo",
                "Giftbox": "Caja de regalo",
                "Gifttin": "Lata de regalo",
                "Leather GB": "Cuero GB",
                "PET bottle": "Botella PET",
                "Pack": "Paquete",
                "Porcelain Bottle": "Botella de porcelana",
                "Slightly dirty outercases": "Cajas exteriores ligeramente sucias",
                "Square Bottle": "Botella cuadrada",
                "Wooden Giftbox": "Caja de regalo de madera"
            },
            "products": "Productos"
        },
        "units": {
            "%": "%",
            "cl": "cl",
            "cs": "cs",
            "day_one": "día",
            "day_other": "días"
        }
    },
    "forgot_password": {
        "confirmed": "El correo electrónico está en camino. Comprueba tu bandeja de entrada (y tu carpeta de correo no deseado) para ver el correo electrónico con el enlace para restablecer la contraseña.",
        "description": "¿Has olvidado tu contraseña? Introduce tu dirección de correo electrónico a continuación, haz clic en el botón y se te enviará un correo electrónico que te permitirá restablecer tu contraseña.",
        "email": "Tu dirección de correo electrónico",
        "email_help": "La dirección de correo electrónico que utilizas para iniciar sesión",
        "start_flow": "Solicitar correo electrónico de restablecimiento",
        "title": "¿Has olvidado la contraseña?"
    },
    "issues": {
        "button": {
            "label": {
                "select_image": "Seleccionar imagen",
                "submit_issue": "Enviar tema",
                "take_snapshot": "Toma una instantánea"
            }
        },
        "field": {
            "help": {
                "description": "Una descripción concisa y completa del asunto",
                "media": "Una captura de pantalla o un vídeo que muestre el problema",
                "title": "Cuatro o cinco palabras que resuman el tema",
                "url": "URL de la página del problema"
            },
            "label": {
                "description": "Descripción",
                "media": "Medios de comunicación",
                "title": "Título",
                "url": "URL"
            },
            "placeholder": {
                "description": "Una descripción concisa y completa del problema, para que podamos identificarlo mejor y más rápidamente.",
                "media": "Ctrl-v para pegar una imagen",
                "title": "Cuatro o cinco palabras que resuman el tema",
                "url": "URL de la página del problema"
            }
        },
        "label": {
            "title": "Informar de un problema",
            "title_submitted": "¡Gracias por tu informe de errores!"
        },
        "support": {
            "help": {
                "template": "La plantilla que se utiliza para las nuevas incidencias de Gitlab"
            },
            "label": {
                "button_submit_issue": "Guardar ajustes",
                "template": "Plantilla"
            },
            "placeholder": {
                "template": "Contiene una plantilla clara para informes de errores óptimos."
            }
        },
        "text": {
            "submitted": "¡Gracias por enviar tu informe de incidencia! Puedes comprobar el estado de tu incidencia en nuestro <a href={{link}} target='_blank'>rastreador de incidencias</a>. No dudes en añadir comentarios relacionados en la incidencia; ya sea para proporcionar contexto adicional o simplemente si deseas algunas aclaraciones adicionales. Hacemos que Descubrir y Portal sea mejor paso a paso, ¡gracias a tu ayuda!"
        }
    },
    "landing": {
        "force_logout": "Lo siento, tienes que volver a conectarte...",
        "login": {
            "otp_code": "Ficha de seguridad",
            "otp_code_help": "Introduce aquí el código generado por la app",
            "step0_desc": "Utiliza tus credenciales de {{vendor}} para acceder. En caso de que hayas perdido tu contraseña, puedes introducir tu dirección de correo electrónico a continuación y hacer clic en el enlace \"He olvidado mi contraseña\".",
            "step0_slug": "Acceder a nuestro portal",
            "step1_desc": "Se necesita una aplicación autenticadora adicional para mantener tu cuenta a salvo de accesos no autorizados. Instala una de estas sugerencias de aplicaciones autenticadoras en tu teléfono móvil y escanea el código QR. Utiliza el token de tu teléfono para registrar tu dispositivo e iniciar sesión. Vuelve a añadir el token en tu aplicación si algo va mal durante el proceso. ¿Sigues sin tener suerte? Ponte en contacto con nosotros.",
            "step1_slug": "Seguridad adicional",
            "step2_desc": "Se necesita un código de seguridad adicional. Comprueba si tu aplicación de autenticación tiene el código. ¿Has perdido el acceso a tu aplicación de autenticación? Ponte en contacto con nosotros.",
            "step2_slug": "Se requiere token de seguridad",
            "subtitle": "Inicia sesión para continuar...",
            "title": "¡Bienvenido a {{vendor}}!"
        }
    },
    "languages": {
        "de": "Alemán",
        "en_gb": "Inglés",
        "es": "Español",
        "fr": "Francés",
        "it": "Italiano",
        "nl": "Holandés"
    },
    "my_portal": {
        "label": "Mi portal",
        "logout": "Cerrar sesión",
        "settings": {
            "language": "Lengua",
            "language_help": "Generado por la IA; puede contener un uso incorrecto del lenguaje"
        },
        "tip": "Mi Portal - Noticias, Perfil y Configuración"
    },
    "page": {
        "subtitle": {
            "forgot_password": "Restablecer tu contraseña",
            "reset_password": "Por favor, comprueba dos veces tu nueva contraseña..."
        },
        "title": {
            "forgot_password": "¿Has olvidado la contraseña?",
            "login": "¡Bienvenido a {{vendor}}!",
            "login_slug": "Iniciar sesión",
            "registration": "Inscripción",
            "reset_password": "Restablecer contraseña"
        }
    },
    "password_reset": {
        "confirmed": "Se ha restablecido tu contraseña",
        "description": {
            "new-account": "Después de establecer tu contraseña, se te pedirá que inicies sesión.",
            "reset": "Después de restablecer tu contraseña, se te pedirá que te conectes de nuevo."
        },
        "email": "Tu dirección de correo electrónico",
        "email_help": "La dirección de correo electrónico que utilizas para el portal",
        "failed": "La solicitud para cambiar tu contraseña ha fallado: \"{{reason}}\"",
        "new_password": "Nueva contraseña",
        "new_password_help": "Tu contraseña preferida; comprueba con el ojo si tu contraseña es correcta",
        "new_password_repeat": "Repite la contraseña",
        "new_password_repeat_help": "Repite tu contraseña",
        "reset_button": "Actualizar contraseña",
        "title": {
            "new-account": "Crear contraseña",
            "reset": "Actualizar contraseña"
        }
    },
    "price": {
        "offer_until_one": "¡Último día de oferta!",
        "offer_until_other": "Válido durante otros {{count}} días",
        "price_down": "Recientemente rebajado de {{price}} {{currency}}"
    },
    "product": {
        "availability": {
            "eta": "Llegada ~ {{period}}",
            "stock": "En stock",
            "tbo": "Por Encargar"
        }
    },
    "profile": {
        "email": "Tu dirección de correo electrónico",
        "email_help": "Tu dirección de correo electrónico para iniciar sesión",
        "email_placeholder": "you@yourbusiness.com",
        "forgot_pw": "¿Has olvidado tu contraseña?",
        "login": "Iniciar sesión",
        "password": "Tu contraseña",
        "password_help": "La contraseña para {{vendor}}",
        "password_placeholder": "Psst, ¡es un secreto!"
    },
    "rating": {
        "rate": {
            "description": "Tu opinión nos ayudará a mejorar el servicio que te prestamos",
            "placeholder": "Por favor, haznos saber si tienes alguna sugerencia sobre cómo podemos mejorar tu pedido.",
            "sent": "¡Muchas gracias por el comentario!",
            "title": "¿Qué opinas de nuestro proceso de pedido?"
        },
        "send.title": "Envía",
        "thanks": {
            "description": "Hacemos todo lo posible para que nuestro servicio sea lo mejor posible. Si tienes más preguntas o sugerencias, ponte en contacto con tu gestor de asistencia.",
            "notification": "¡Muchas gracias por el comentario!",
            "title": "¡Gracias en nombre de {{team}}!"
        }
    },
    "sales_order_addition_types": {
        "discount": "Descuento",
        "other": "Otros",
        "pallet": "palé",
        "revenue": "Ingresos",
        "transport": "transporte",
        "voucher": "Vale",
        "waste_fund": "Fondo de residuos"
    },
    "settings": {
        "beta": {
            "active": "Modo Beta activado",
            "inactive": "Modo Beta desactivado"
        }
    },
    "status": {
        "error": {
            "login": {
                "credentials_invalid": "Hay un problema con tus credenciales de acceso. Por favor, verifica tu dirección de correo electrónico y tu contraseña."
            },
            "message": "Se ha producido un error inesperado; inténtalo de nuevo más tarde (referencia: {{message}})",
            "order": {
                "delivery_invalid": "No se cumple la condición de entrega"
            },
            "otp": {
                "invalid": "El token de autenticación no es válido; comprueba tu aplicación de autenticación.",
                "invalid_notify": "Tu código de autenticación no es válido. Por favor, comprueba si tu aplicación tiene el código correcto. Si el problema persiste, comprueba si los ajustes de fecha/hora de tu dispositivo son correctos.",
                "length": "El token de autenticación es de 6 cifras"
            },
            "unknown": "Lo sentimos. Se ha producido un error inesperado. Nos anticipamos activamente a este tipo de problemas. Vuelve a intentarlo más tarde para ver si el problema ya se ha resuelto. Ponte en contacto con nosotros si el problema persiste durante más tiempo. Gracias por tu paciencia."
        },
        "success": {
            "login": {
                "forgot_pw": "Se enviará un correo electrónico con instrucciones para restablecer tu contraseña a {{email}} si se trata de una dirección de correo electrónico válida. ¿No hay correo electrónico? Verifica la dirección de correo electrónico y comprueba tu carpeta de correo no deseado. Ponte en contacto con nosotros si sigues sin recibir ningún correo electrónico."
            },
            "redeem": {
                "verified": "¡Enhorabuena! Has utilizado correctamente el código para: {{name}} ¡!"
            }
        }
    },
    "support": {
        "contact": {
            "collapse": "Alternar detalles de soporte",
            "email": "Correo electrónico de contacto: {{contact}}",
            "mobile": "Móvil de contacto: {{contact}}",
            "phone": "Teléfono de contacto: {{contact}}",
            "support_manager": "gestor de apoyo",
            "team_etr": "Equipo A2BC",
            "team_msp": "Equipo Moving Spirits",
            "tech_support": "Tech Ayuda"
        },
        "roles": {
            "default": "Responsable de Apoyo",
            "sales_nl": "Comerciante Países Bajos",
            "tech": "Soporte técnico"
        }
    },
    "system": {
        "incompatible": "Tu navegador no cumple los requisitos mínimos; actualiza primero tu navegador ({{code}})",
        "reload_portal": "Recargar Portal",
        "scheme_change": "Una actualización reciente requiere que te conectes de nuevo"
    },
    "unit": {
        "bottle_one": "botella",
        "bottle_other": "botellas",
        "case_one": "caso",
        "case_other": "casos",
        "percentage": "%"
    },
    "validation": {
        "email": "Parece ser una dirección de correo electrónico no válida.",
        "form": {
            "action_required": "Estos campos requieren su atención:",
            "valid": "El formulario parece válido"
        },
        "length": {
            "fixed_length": "Este campo requiere exactamente {{fixed_length}} caracteres",
            "max_length": "Este campo no requiere más de {{max_length}} caracteres",
            "min_length": "Este campo requiere al menos {{min_length}} caracteres"
        },
        "must_match": "Los campos deben coincidir",
        "password": {
            "cannot_end_with_space": "La contraseña no puede terminar con un espacio",
            "min_length": "La contraseña requiere al menos 9 caracteres",
            "one_lower_case": "La contraseña debe contener al menos una letra minúscula",
            "one_upper_case": "La contraseña debe contener al menos una letra mayúscula"
        },
        "required": "Este campo es obligatorio",
        "vat_id": {
            "country_incorrect": "El CIF tiene un código de país incorrecto; se esperaba: '{{expected}}'",
            "help_disabled": "No se admite el CIF para los países no pertenecientes a la UE: {{country}}",
            "help_validate": "Utiliza el botón Validar para verificar el CIF del país: {{country}}",
            "invalid_format": "<a href='{{link}}' target='_blank'>Formato de CIF</a> no válido para el país: {{country_code}}",
            "label": "CIF ({{country}})",
            "verify": "Verificar ID",
            "vies_match": "Coincidencia del CIF: {{match}}",
            "vies_nomatch": "Este NIF no parece válido"
        },
        "website": "Esta dirección no es válida"
    }
}