import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Button} from '@bitstillery/common/components'
import {next_tick, proxy} from '@bitstillery/common/lib/proxy'
import {copy_object, merge_deep} from '@bitstillery/common/lib/utils'
import m from 'mithril'
import {$s} from '@bitstillery/common/app'

interface RowActionDetailsAttrs {
    /** CollectionProxy to adjust detail state for */
    collection: any
    /** A collection item */
    row: any
}

export class RowActionDetails extends MithrilTsxComponent<RowActionDetailsAttrs> {
    view(vnode:m.Vnode<RowActionDetailsAttrs>) {
        return <Button
            active={vnode.attrs.collection.state.detail && (vnode.attrs.row.artkey === vnode.attrs.collection.state.detail)}
            disabled={vnode.attrs.row.loading}
            icon="info"
            onclick={() => {
                if (vnode.attrs.collection.state.detail !== vnode.attrs.row.artkey) {
                    vnode.attrs.collection.state.detail = vnode.attrs.row.artkey
                } else {
                    vnode.attrs.collection.state.detail = null
                }
            }}
            tip={() => vnode.attrs.collection.state.detail && (vnode.attrs.row.artkey === vnode.attrs.collection.state.detail) ? 'Hide item details' : 'Show item details'}
            type="info"
            variant="toggle"
        />
    }
}

interface RowActionDeleteAttrs {
    /** An optional icon override (default is 'trash') */
    icon?: 'string'
    /** Whether to require an additional click for high-risk actions */
    needs_confirmation?: boolean
    /** A collection item */
    row: any
    /** A save_row method to call when clicking this save button */
    row_delete: Function
    // An optional tip override
    tip?: string
    // An optional button type override (default is 'success')
    type?: string
    /** The $v validation object that determines if the related fields have been validated before trying to save*/
    validation: any
    /** Deactivate flavour of a delete action */
    variant?: 'deactivate'

}

export class RowActionDelete extends MithrilTsxComponent<RowActionDeleteAttrs> {

    data = proxy({
        confirmed: false,
    })

    view(vnode:m.Vnode<RowActionDeleteAttrs>) {
        let icon, tip, tip_confirm
        if (vnode.attrs.icon) {
            icon = vnode.attrs.icon
        } else if (vnode.attrs.variant === 'deactivate') {
            icon = 'deactivate'
            tip = 'Deactivate this item'
            tip_confirm = 'I am sure; delete this item'
        } else {
            icon = 'trash'
            tip = 'Delete this item'
            tip_confirm = 'I am sure; deactivate this item'
        }
        if (this.data.confirmed) {
            return [
                <Button
                    icon={icon}
                    onclick={async(e) => {
                        e.stopPropagation()
                        vnode.attrs.row.loading = true
                        await vnode.attrs.row_delete()
                        vnode.attrs.row.loading = false
                    }}
                    tip={tip_confirm}
                    type='danger'
                    variant="toggle"
                />,
                <Button
                    icon='cancel'
                    onclick={async(e) => {
                        e.stopPropagation()
                        this.data.confirmed = false
                    }}
                    tip='Cancel action'
                    variant="toggle"
                />,
            ]

        }
        return <Button
            disabled={vnode.attrs.row.loading}
            icon={icon}
            onclick={async(e) => {
                e.stopPropagation()
                vnode.attrs.row.loading = true
                if (vnode.attrs.needs_confirmation) {
                    this.data.confirmed = true
                    setTimeout(() => {
                        this.data.confirmed = false
                    }, 1500)
                } else {
                    vnode.attrs.row.loading = true
                    await vnode.attrs.row_delete()
                    vnode.attrs.row.loading = false
                }
                vnode.attrs.row.loading = false
            }}
            tip={() => {
                if (vnode.attrs.tip) {
                    if (typeof vnode.attrs.tip === 'function') {
                        return vnode.attrs.tip()
                    }
                    return vnode.attrs.tip
                }

                if (vnode.attrs.needs_confirmation) {
                    return `${tip} (needs confirmation)`
                } else {
                    return tip
                }
            }}
            type={vnode.attrs.type ? vnode.attrs.type : 'danger'}
            variant="toggle"
        />
    }
}

interface RowActionDownloadAttrs {
    icon?: string
    row: any
    row_download: Function
    tip?: string
    type?: 'default' | 'info' | 'success' | 'warning' | 'danger'
}
export class RowActionDownload extends MithrilTsxComponent<RowActionDownloadAttrs> {

    view(vnode:m.Vnode<RowActionDownloadAttrs>) {
        return <Button
            icon={vnode.attrs.icon ? vnode.attrs.icon : 'download'}
            onclick={async(e) => {
                e.stopPropagation()
                vnode.attrs.row.loading = true
                await vnode.attrs.row_download()
                vnode.attrs.row.loading = false
            }}
            tip={() => {
                if (vnode.attrs.tip) {
                    return vnode.attrs.tip
                }

                return 'Download this item'
            }}
            type={vnode.attrs.type ? vnode.attrs.type : 'info'}
            variant="toggle"
        />
    }
}

interface RowActionEditAttrs {
    /** Condition whether the edit action is activated or not */
    active: boolean
    /** The collection to interact with */
    collection?: any
    /** The context name to check for when using the panel variant */
    context?: {
        id?: number
        name: string
        title?: string
    }
    /** When using multiple edit actions; use the icon to distinguish multiple edit actions */
    icon?: string
    /** Redirects to a different route for editing when set. */
    link?: string
    /**
     * - 'inline' uses a limited amount of fields on the row
     * - 'panel' means the context is retrieved from the current row data
     * - 'panel-context' requires additional setup, but comes with additional routing state & endpoint data.
     */
    mode: 'inline' | 'panel' | 'panel-context'
    onactivate?: Function
    /** A collection item row */
    row: {
        artkey: number
        /** A loading row is disabled */
        loading?: boolean
        /** A toggle that determines if the row is in edit mode */
        edit?: boolean
    }
    tip?: Function
    type?: string
}

/**
 * This is the UI element for a row edit action. An edit action
 * can be redirected to a PanelContext, an inline row edit mode or
 * a different view, by using the link attribute.
 */
export class RowActionEdit extends MithrilTsxComponent<RowActionEditAttrs> {

    oninit(vnode) {
        if (vnode.attrs.context?.link) {
            const {artkey} = m.route.param()
            if (Number(artkey) === vnode.attrs.row.artkey) {
                this.toggle_context(vnode, true)
            }
        }
    }

    async toggle_context(vnode, active) {
        if (active) {
            // Activate the edit option.
            if (vnode.attrs.onactivate) {
                vnode.attrs.onactivate(vnode.attrs.row)
            }

            if (vnode.attrs.mode === 'panel') {
                $s.context.data = copy_object(vnode.attrs.row)
            }

            $s.panels.context.collapsed = false

            // If the PanelContext contains a stepper; this is the place to reset it.
            if ($s.context.stepper) {
                $s.context.stepper.selection = 0
            }
            Object.assign($s.context, {
                icon: vnode.attrs.icon ? vnode.attrs.icon : null,
                id: vnode.attrs.row.artkey,
                name: vnode.attrs.context?.name,
                title: vnode.attrs.context?.title,
            })
            // Force the panel to focus once after the context is set,
            // so keyboard navigation works without manual focus.

            await next_tick()
            await next_tick()
            const element = document.querySelector('.c-panel-context')
            if (element) {
                element.focus()
            }
        } else {
            merge_deep($s.context, {
                active: false,
                id: null,
                link: null,
                mode: '',
                name: null,
            })
        }
    }

    view(vnode:m.Vnode<RowActionEditAttrs>) {
        // Do not show individual Edit actions when Bulk edit mode is active
        if (vnode.attrs.collection.state.bulk_mode === 'edit') return

        const context = $s.context
        let is_active
        // Make sure not to access any context-related variables if the link is set,
        // because context it not passed along with the link option.
        if (vnode.attrs.link) {
            is_active = false
        } else {
            is_active = (context.name === vnode.attrs.context.name && context.id === vnode.attrs.row.artkey)
        }

        return <Button
            active={is_active}
            disabled={vnode.attrs.row.loading}
            icon={vnode.attrs.icon ? vnode.attrs.icon : 'edit'}
            link={vnode.attrs.link}
            onclick={async(e) => {
                e.stopPropagation()

                // Edit form is in a different view
                if (vnode.attrs.mode === 'link') {
                    // Page is redirecting; nothing to be done.
                    return
                }

                if (vnode.attrs.row.artkey === $s.context.id && $s.context.name === vnode.attrs.context.name) {
                    this.toggle_context(vnode, false)
                } else {
                    this.toggle_context(vnode, true)
                }
            }}
            tip={() => {
                let is_active

                if (vnode.attrs.link) {
                    is_active = false
                } else {
                    is_active = (context.name === vnode.attrs.context.name && context.id === vnode.attrs.row.artkey)
                }
                if (vnode.attrs.tip) {
                    if (typeof vnode.attrs.tip === 'function') {
                        return vnode.attrs.tip()
                    }
                    return vnode.attrs.tip
                }

                if (is_active) {
                    return 'Cancel edit item'
                }
                return 'Edit item'
            }}
            type="info"
            variant="toggle"
        />
    }
}

interface RowActionProcessedAttrs {
    /** A collection item */
    row: any
    /** The row to mark as processed; it is removed afterwardes */
    row_process: Function
    tip?: Function
    type?: string
}

export class RowActionProcessed extends MithrilTsxComponent<RowActionProcessedAttrs> {

    view(vnode:m.Vnode<RowActionProcessedAttrs>) {
        return <Button
            disabled={vnode.attrs.row.loading}
            icon="checked"
            onclick={(e) => {
                if (vnode.attrs.row_process) {
                    // Change row logic in the onclick.
                    vnode.attrs.row_process(vnode.attrs.row)
                }

                e.stopPropagation()
            }}
            tip={() => vnode.attrs.tip ? vnode.attrs.tip : 'Mark this item as processed, so it doesn\'t show up in this list again.'}
            type={vnode.attrs.type ? vnode.attrs.type : 'info'}
            variant="toggle"
        />
    }
}

interface RowActionSaveAttrs {
    // An optional icon override (default is 'save)
    icon?: 'string'
    /** A collection item */
    row: any
    /** A save_row method to call when clicking this save button */
    row_save: Function
    // An optional tip override
    tip?: string
    // An optional button type override (default is 'success')
    type?: string
    /** The $v validation object that determines if the related fields have been validated before trying to save*/
    validation: any

}

export class RowActionSave extends MithrilTsxComponent<RowActionSaveAttrs> {
    view(vnode:m.Vnode<RowActionSaveAttrs>) {
        return <Button
            disabled={vnode.attrs.row.loading || !!Object.values(vnode.attrs.validation).filter((i:any) => i && i._invalid).length}
            icon={vnode.attrs.icon ? vnode.attrs.icon : 'save'}
            onclick={async(e) => {
                e.stopPropagation()
                vnode.attrs.row.loading = true
                await vnode.attrs.row_save()
                vnode.attrs.row.loading = false
            }}
            tip={() => vnode.attrs.tip ? vnode.attrs.tip : 'Save this item'}
            type={vnode.attrs.type ? vnode.attrs.type : 'success'}
            variant="toggle"
        />
    }
}
