import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {proxy} from '@bitstillery/common/lib/proxy'
import {Button, FieldText} from '@bitstillery/common/components'
import {copy_object} from '@bitstillery/common/lib/utils'
import {$m, $s, $t, notifier} from '@bitstillery/common/app'

import {CartItemState} from '@/models/cart'

export class ProductQuantity extends MithrilTsxComponent<any> {

    data: any
    indicators: any
    input_selector: string
    notification_id: any
    offer_item: any

    constructor(vn:m.Vnode<any>) {
        super()

        this.offer_item = vn.attrs.offer_item
        // Used to keep the focus on the number input with focus & blur
        // events, while modifying the cart amount.
        this.input_selector = `js-amount-${this.offer_item.case_artkey} input`

        if (vn.attrs.indicators === undefined) {
            this.indicators = true
        } else {
            this.indicators = vn.attrs.indicators
        }

        this.data = proxy({
            _api_error: () => {
                const cart_item = this.data._cart_item
                if (!cart_item) return false
                return (cart_item.case_artkey in $s.cart.errors.api)
            },
            _cart_cases: () => {
                const cart_item = this.data._cart_item
                if (cart_item) {
                    return cart_item.number_of_cases
                }

                return 0
            },

            _cart_units: () => {
                if ($s.cart.show_bottles) {
                    return this.data._cart_cases * this.offer_item.case_number_of_bottles
                }
                return this.data._cart_cases
            },
            _cart_item: () => {
                return $s.cart.items[this.offer_item.case_artkey]
            },

            /**
             * The amount that will be added, considering the current
             * widget value (_model_value), the current amount in the basket
             * and the minimum required quantity for this product.
             */
            _count_unit_add: () => {
                const model_diff = this.data._model_value - this.data._cart_units
                // Add the value of the widget minus what is already in the basket
                if (model_diff > 0) return model_diff

                if (model_diff === 0) {
                    const min_quantity = this.data._min_quantity_unit
                    if (!min_quantity) return this.data._model_value_unit
                    // Basket & widget value are the same; +1 behaviour

                    // Minimum is MOQ
                    if (this.data._model_value < min_quantity) return min_quantity
                    // Default +1 unit behaviour
                    return this.data._model_value_unit
                }

                return 0
            },

            /**
             * The amount that will be removed, considering the current
             * widget value (_model_value), the current amount in the basket
             * and the minimum required quantity for this product.
             */
            _count_unit_remove: () => {
                // Nothing in the basket; so nothing to remove
                if (!this.data._cart_units) return 0
                // No widget value; assume remove all
                if (!this.data._model_value) return this.data._cart_units
                const model_diff = this.data._cart_units - this.data._model_value
                // Widget has more than basket; nothing to remove
                if (model_diff < 0) return 0

                const min_quantity = this.data._min_quantity_unit
                if (!min_quantity) {
                    // Widget value & basket are the same; -1 unit
                    if (model_diff === 0) return this.data._model_value_unit
                    // Remove the intended amount
                    return model_diff
                }

                // Widget & basket value are the same
                if (model_diff === 0) {
                    // Any widget value below MOQ is a remove all
                    if (this.data._model_value <= min_quantity) return this.data._cart_units
                    // Default -1 unit behaviour
                    return this.data._model_value_unit
                }
                // Widget value is less than MOQ; this could happen typing
                // the amount manually in the input.
                if (this.data._model_value < min_quantity) {
                    return this.data._cart_units
                }
                // Remove the intended amount
                return model_diff
            },

            _less_available: () => {
                const cart_item = this.data._cart_item
                // Without basket salesOrderItem, this is always false. Less
                // available means, less than what is in the basket now.
                if (!cart_item) return false
                // Less available means at least one unit is available.
                if (this.data._unavailable) return false
                // Always use the validation data; soi & offer_item may be outdated.
                return (cart_item.case_artkey in $s.cart.errors.less_available)
            },
            _list_quantity_unit: () => {
                if ($s.cart.show_bottles) {
                    return this.offer_item.list_quantity * this.offer_item.case_number_of_bottles
                }

                return this.offer_item.list_quantity
            },

            /**
             * Inform the user when MOQ is active.
             */
            _min_quantity_active: () => {
                // No need to convert between units
                return this.data._min_quantity_cases > 0
            },

            _min_quantity_cases: () => {
                // A minimum amount of 0 means there is no minimum amount
                if (!this.offer_item.minimum_quantity) return 0
                // Minimum amount is total available amount with less availability
                if (this.offer_item.list_quantity < this.offer_item.minimum_quantity) return this.offer_item.list_quantity
                return this.offer_item.minimum_quantity
            },

            _min_quantity_unit: () => {
                const cart_item = this.data._cart_item
                let min_quantity = this.data._min_quantity_cases
                if (cart_item) {
                    if ($s.cart.errors.minimum_quantity[cart_item.case_artkey]) {
                        min_quantity = $s.cart.errors.minimum_quantity[cart_item.case_artkey]
                    }
                }
                if ($s.cart.show_bottles) {
                    return min_quantity * this.offer_item.case_number_of_bottles
                }
                return min_quantity
            },

            /**
             * This concerns the MOQ in combination with the current basket;
             * if the basket contains less than MOQ, then we raise an error
             * and the UI should warn the user. MOQ without a basket combination
             * should just inform the user; see `_min_quantity_active`.
             */
            _min_quantity_violation: () => {
                const min_quantity = this.data._min_quantity_unit
                const violation = this.data._cart_units > 0 && min_quantity > 0 && (this.data._cart_units < min_quantity)
                return violation
            },

            /**
             * The component's state is computed; it either returns the external/shared
             * modelValue property, or the current basket amount. This is to allow multiple
             * ProductQuantity widgets to share the same state.
             */
            _model_value: {
                get: () => {
                    if (!$s.cart.items[this.offer_item.case_artkey]) {
                        return null
                    }
                    return $s.cart.items[this.offer_item.case_artkey].value
                },
                set: (value) => {
                    if ($s.cart.items[this.offer_item.case_artkey]) {
                        $s.cart.items[this.offer_item.case_artkey].value = value
                    } else if (value > 0) {
                        this.set_cart_item_state(value)
                    }
                },
            },

            _model_value_bottles: () => {
                if ($s.cart.show_bottles) {
                    return Math.ceil(this.data._model_value / this.offer_item.case_number_of_bottles) * this.offer_item.case_number_of_bottles
                } else {
                    return this.data._model_value * this.offer_item.case_number_of_bottles
                }
            },

            _model_value_cases: () => {
                if ($s.cart.show_bottles) {
                    return Math.ceil(this.data._model_value / this.offer_item.case_number_of_bottles)
                }
                return this.data._model_value
            },

            /**
             * The step-size for product amount, depending on
             * the amount of bottles per product when using
             * bottles as preference; otherwise just 1.
             */
            _model_value_unit: () => {
                const cart_item = this.data._cart_item
                if ($s.cart.show_bottles) {
                    if (cart_item) {
                        return cart_item.case_number_of_bottles
                    } else {
                        return this.offer_item.case_number_of_bottles
                    }
                }
                return 1
            },

            _price_up: () => {
                const cart_item = this.data._cart_item
                if (!cart_item) return false

                if ($s.cart.errors.price_up[cart_item.case_artkey]) return true
                return false
            },

            _rounded_bottles: () => {
                if ($s.cart.show_bottles) {
                    return Math.ceil(this.data._model_value / this.offer_item.case_number_of_bottles) * this.offer_item.case_number_of_bottles
                }
                return this.data._model_value * this.offer_item.case_number_of_bottles
            },

            _rounded_unit_add: () => {
                let count_unit = this.data._count_unit_add
                if ($s.cart.show_bottles) {
                    return Math.ceil(count_unit / this.offer_item.case_number_of_bottles) * this.offer_item.case_number_of_bottles
                }

                return count_unit
            },

            _rounded_unit_remove: () => {
                let count_unit = this.data._count_unit_remove
                // Rounding up to whole cases, in case the input was in bottles
                if ($s.cart.show_bottles) {
                    return Math.floor(count_unit / this.offer_item.case_number_of_bottles) * this.offer_item.case_number_of_bottles
                }

                return count_unit
            },

            _rounding_required: () => {
                if (!this.data._model_value) return false
                if (!$s.cart.show_bottles) return false
                return (this.data._model_value % this.offer_item.case_number_of_bottles !== 0)
            },

            _step_unit: () => {
                if ($s.cart.show_bottles) {
                    return this.offer_item.case_number_of_bottles
                }

                return 1
            },
            _unavailable: () => {
                const cart_item = this.data._cart_item
                if (cart_item) {
                    // Always use validation data when having a salesOrderItem.
                    return ($s.cart.items[this.offer_item.case_artkey].artkey in $s.cart.errors.unavailable)
                }
                // Determine availability based on the offer_item data.
                return this.data._list_quantity_unit === 0
            },
            indicators: {
                _active: () => this.indicators && (this.data._min_quantity_active || this.data.indicators._warning),
                _tip: () => {
                    let tip = ''
                    const cart_item = this.data._cart_item
                    if (this.data._price_up) {
                        tip = $t('cart.price_changed', {
                            old_price: $m.offer.unit_price(
                                $s.cart.errors.price_up[cart_item.case_artkey].basket_price,
                                this.offer_item.case_number_of_bottles,
                            ),
                            new_price: $m.offer.unit_price(
                                $s.cart.errors.price_up[cart_item.case_artkey].list_price,
                                this.offer_item.case_number_of_bottles,
                            ),
                            currency: $s.identity.user.currency,
                        })
                    } else if (this.data._rounding_required && this.data._count_unit_remove > 0) {
                        tip = $t('cart.rounded', {
                            bottles: this.data._rounded_bottles,
                            count: this.data._model_value_cases,
                        })
                    }

                    return tip
                },
                _warning: () => {
                    return (
                        (this.data._unavailable || this.data._less_available || this.data._price_up) ||
                        (this.data._rounding_required && this.data._count_unit_remove > 0)
                    )
                } ,
            },
            left: {
                _active: () => this.data._cart_units > 0,
                _disabled: () => {
                    return !(
                        // Exceptions, why not to disable the left button:
                        (
                            // There is something in the basket and the widget value
                            // is empty or smaller than what is in the basket and
                            // the amount to be removed is at least 1.
                            this.data._cart_units > 0 &&
                            (!this.data._model_value || this.data._model_value <= this.data._cart_units) &&
                            this.data._rounded_unit_remove > 0
                        ) ||
                        // - Validation actions are always allowed
                        (
                            this.data._price_up ||
                            this.data._less_available ||
                            this.data._unavailable ||
                            this.data._min_quantity_violation
                        )
                    )
                },
                _state: () => {
                    const state = {
                        icon: '',
                        iconSize: 's',
                        tip: '',
                        type: 'default',
                    }

                    // About to remove everything anyway
                    if (this.data._rounded_unit_remove === this.data._cart_units) {
                        state.tip = $t('cart.minus_all', {product: this.offer_item.product_name})
                    }

                    if (
                        this.data._unavailable ||
                        this.data._less_available ||
                        this.data._min_quantity_violation
                    ) {
                        state.type = 'default'
                        state.icon = 'trash'
                        // Left button action for these actions is delete.
                        // Right button is hidden for unavailable; other
                        // right buttons are confirm.
                        state.tip = $t('cart.minus_all', {product: this.offer_item.product_name})
                    } else if (this.data._cart_units > 0) {
                        // Removal triggered when:
                        //   - About to remove all
                        //   - Empty widget value means deletion
                        if (
                            this.data._rounded_unit_remove === this.data._cart_units ||
                            !this.data._model_value ||
                            (this.data._model_value === this.data._model_value_unit && this.data._cart_units === this.data._model_value) ||
                            (this.data._rounded_unit_remove > 0 && this.data._rounded_unit_remove === this.data._cart_units)
                        ) {
                            state.icon = 'trash'
                            state.tip = $t('cart.minus_all', {product: this.offer_item.product_name})
                        } else {
                            if (this.data._model_value < this.data._cart_units) {
                                state.icon = 'checked'
                            } else {
                                state.icon = 'minus'
                            }
                            state.tip = $t('cart.minus', {
                                count: this.data._rounded_unit_remove,
                                product: this.offer_item.product_name,
                                units: $t(`unit.${$s.identity.user.price_preference}`, {count: this.data._rounded_unit_remove}),
                            })
                        }
                    }
                    return state
                },
            },
            loading: false,
            right: {
                _active: () => this.data._list_quantity_unit > 0,
                _disabled: () => {
                    // Why not to disable the right button:
                    //   - Nothing in the cart and there are available units left
                    //   - Cart and widget amount are the same; next step allowed when it is less than availability
                    //   - Cart contains less than widget; next step is allowed until it is the same as availability
                    //   - Validation actions are always allowed
                    return !(
                        !this.data._cart_units && this.data._list_quantity_unit > 0 ||
                        ((this.data._cart_units === this.data._model_value) && (this.data._model_value < this.data._list_quantity_unit)) ||
                        ((this.data._cart_units < this.data._model_value) && (this.data._model_value <= this.data._list_quantity_unit)) ||
                        (this.data._less_available || this.data._min_quantity_violation)
                    )
                },
                _state: () => {
                    const state = {
                        icon: 'plus',
                        iconSize: 's',
                        tip: '',
                        type: 'default',
                    }

                    // No need to determine further state when the button isn't there
                    if (!this.data.right._active) return state

                    if (this.data._less_available || this.data._min_quantity_violation) {
                        state.type = 'default'
                        state.icon = 'checked'
                    } else if (this.data._cart_units === 0) {
                        state.icon = 'cartPlus'
                        state.iconSize = 'd'
                        state.type = 'success'
                    } else if (this.data._count_unit_add > this.data._model_value_unit) {
                        state.icon = 'plus'
                    }

                    if (this.data._cart_units === 0) {
                        state.tip = $t('cart.plus_all', {product: this.offer_item.product_name})
                    } else if (this.data._less_available) {
                        state.tip = $t(this.data._rounded_unit_remove === this.data._cart_units ? 'cart.minus_all' : 'cart.minus', {
                            count: this.data._cart_units - this.data._list_quantity_unit,
                            product: this.offer_item.product_name,
                            units: $t(`unit.${$s.identity.user.price_preference}`, {count: this.data._rounded_unit_remove}),
                        })
                    } else if (this.data._min_quantity_unit && this.data._count_unit_add > this.data._model_value_unit) {
                        // MOQ warns about a minimum order amount to be used.
                        state.tip = $t('cart.min_quantity_confirm', {
                            count: this.data._min_quantity_unit,
                            units: $t(`unit.${$s.identity.user.price_preference}`, {count: this.data._min_quantity_unit}),
                        })
                    } else {
                        // Add next available unit to the basket.
                        state.tip = $t('cart.plus', {
                            count: this.data._rounded_unit_add,
                            units: $t(`unit.${$s.identity.user.price_preference}`, {count: this.data._rounded_unit_add}),
                        })
                    }

                    return state
                },
            },
            tips: {
                _cart: () => {
                    let tip = ''

                    if (this.data._api_error) {
                        tip = `${$t('cart.error_api')} ${$t('cart.error_help.api')}`
                    } else if (this.data._unavailable) {
                        tip = $t('cart.unavailable')
                        if (this.data._cart_cases > 0) {
                            tip = `${tip} ${$t('cart.unavailable_action')}`
                        }
                    } else if (this.data._less_available) {
                        const basket_content = $t('cart.content', {
                            count: this.data._cart_units,
                            units: $t(`unit.${$s.identity.user.price_preference}`, {count: this.data._cart_units}),
                        })
                        const less_quantity = $t('cart.less_available', {
                            count: this.data._list_quantity_unit,
                            units: $t(`unit.${$s.identity.user.price_preference}`, {count: this.data._list_quantity_unit}),
                        })

                        tip = `${basket_content} ${less_quantity} ${$t('cart.less_available_action')}`
                    } else {
                        tip = $t('cart.content', {
                            count: this.data._cart_units,
                            units: $t(`unit.${$s.identity.user.price_preference}`, {count: this.data._cart_units}),
                        })
                    }
                    return tip
                },
                _min_quantity: () => {
                    const count = this.data._min_quantity_unit
                    let tip = $t('cart.min_quantity', {
                        count,
                        units: $t(`unit.${$s.identity.user.price_preference}`, {count}),
                    })
                    if (!this.data._min_quantity_violation) return tip
                    return `${tip} ${$t('cart.min_quantity_action')}`
                },
                _rounding: () => {
                    return $t('cart.rounded', {
                        bottles: this.data._rounded_bottles,
                        count: this.data._model_value_cases,
                    })
                },
                _stock: () => {
                    return $t('cart.stock', {
                        count: this.data._list_quantity_unit,
                        units: $t(`unit.${$s.identity.user.price_preference}`, {count: this.data._list_quantity_unit}),
                    })
                },
            },
        })

        // Context is an externally assigned object, which allows an external component to
        // access and reuse the ProductQuantity logic; e.g. ProductInfo.
        vn.attrs.context[0][vn.attrs.context[1]] = this.data
    }

    async cart_add(units) {
        this.data._model_value = this.data._cart_units + units
        const case_artkey = this.offer_item.case_artkey
        if (!(case_artkey in $s.cart.items)) {
            // State may be gone when removing from other widgets. Make
            // sure adding items to the cart is done only through THIS widget.
            this.set_cart_item_state(units)
        }

        // Rounding up to whole cases, in case the input was in bottles
        if (this.data._rounding_required) {
            this.data._model_value = this.data._model_value_cases * this.offer_item.case_number_of_bottles
            notifier.notify($t('cart.rounded', {
                bottles: this.data._model_value_bottles,
                count: this.data._model_value_cases,
            }), 'warning', null, 'case')
        }

        await $m.cart.update_cart({
            number_of_cases: this.data._model_value_cases,
            price_per_case: this.offer_item.list_price,
        }, this.offer_item.case_artkey)
        $m.cart.validate_cart_reset(this.offer_item.case_artkey, true, false)

        let notify_text = $t('cart.product_amount', {
            count: this.data._model_value,
            product: this.offer_item.product_name,
            units: $t(`unit.${$s.identity.user.price_preference}`, {count: this.data._model_value}),
        })

        this.notification_id = notifier.notify(notify_text, 'info', null, 'cartPlus', this.notification_id)
    }

    async cart_remove(units) {
        this.data._model_value = this.data._cart_units - units

        // Rounding up to whole cases, in case the input was in bottles
        if (this.data._rounding_required) {
            this.data._model_value = this.data._cart_units - this.data._rounded_unit_remove
            notifier.notify($t('cart.rounded', {
                bottles: this.data._model_value_bottles,
                count: this.data._model_value_cases,
            }), 'warning', null, 'case')
        }

        await $m.cart.update_cart({
            number_of_cases: this.data._model_value_cases,
            price_per_case: this.offer_item.list_price,
        }, this.offer_item.case_artkey)

        $m.cart.validate_cart_reset(this.offer_item.case_artkey, true, false)

        let notify_text
        if (this.data._model_value === 0) {
            notify_text = $t('cart.product_removed', {product: this.offer_item.product_name})
            const input_selector = document.querySelector(`.${this.input_selector}`) as HTMLElement
            if (input_selector) {
                input_selector.blur()
            }
        } else {
            notify_text = $t('cart.product_amount', {
                count: this.data._model_value,
                product: this.offer_item.product_name,
                units: $t(`unit.${$s.identity.user.price_preference}`, {count: this.data._model_value}),
            })
        }
        this.notification_id = notifier.notify(notify_text, 'info', null, 'cartRemove', this.notification_id)
    }

    set_cart_item_state(value) {
        const {
            bottle_alcohol_percentage, bottle_refill_status, bottle_volume,
            case_artkey, case_customs_status, case_gift_box_type, case_number_of_bottles,
            excise_per_case, list_price, number_of_cases, artkey: offer_item_artkey, offer_item_type,
            portal_comment, product_name, vat_percentage,
        } = copy_object(this.offer_item)

        const cart_item_state:CartItemState = {
            artkey: null,
            bottle_alcohol_percentage,
            bottle_refill_status,
            bottle_volume,
            case_artkey,
            case_customs_status,
            case_gift_box_type,
            case_number_of_bottles,
            excise_per_case,
            list_price,
            number_of_cases,
            offer_item_artkey,
            offer_item_type,
            portal_comment,
            product_name,
            unit: $s.identity.user.price_preference,
            value,
            vat_percentage,
        }
        $m.cart.add_cart_item_state(cart_item_state)
        if (!(this.offer_item.case_artkey in $s.cart.offer_items)) {
            $s.cart.offer_items[this.offer_item.case_artkey] = this.offer_item
        }
    }

    view() {
        return (
            <div className={classes('c-product-quantity', {
                'less-available': this.data._less_available,
                'min-quantity': this.data._min_quantity_violation,
                'price-up': this.data._price_up,
                unavailable: this.data._unavailable,
            })}>
                <div className="widget">
                    <div className={classes('actions-extended', {active: this.data._cart_units})}>
                        {this.data.left._active && <Button
                            className="btn-quantity btn-left"
                            disabled={this.data.left._disabled}
                            icon={this.data.left._state.icon}
                            onclick={async() => {
                                this.data.loading = true

                                // Don't focus automatically with onscreen keyboards; e.g. tablet/mobile.
                                if ($s.env.layout === 'desktop') {
                                    const input_selector = document.querySelector(`.${this.input_selector}`) as HTMLElement
                                    if (input_selector) {
                                        input_selector.focus()
                                    }
                                } else if (navigator.vibrate) {
                                    navigator.vibrate(40)
                                }
                                // Left button action for these validation rules
                                // are interpreted as "Remove all" action.
                                if (
                                    this.data._unavailable ||
                                    this.data._less_available ||
                                    this.data._min_quantity_violation
                                ) {

                                    await this.cart_remove(this.data._cart_units)
                                } else {
                                    // Regular the intended amount
                                    await this.cart_remove(this.data._count_unit_remove)
                                }

                                this.data.loading = false
                            }}
                            size={this.data.left._state.iconSize}
                            tip={() => this.data.left._state.tip}
                            type={this.data.left._state.type}
                            variant="toggle"
                        />}
                        <FieldText
                            className={`input-amount ${this.input_selector}`}
                            min={this.data._min_quantity_unit}
                            max={this.data._list_quantity_unit}
                            model={[this.data, '_model_value']}
                            onenter={() => {
                                if (this.data._count_unit_add > 0) {
                                    // Don't exceed the stock; this happens when setting the input
                                    // to the maximum stock amount and then pressing enter.
                                    // The _count_unit_add doesn't look at available stock.
                                    if (this.data._cart_units + this.data._count_unit_add <= this.data._list_quantity_unit) {
                                        this.cart_add(this.data._count_unit_add)
                                    }
                                } else if (this.data._count_unit_remove > 0) {
                                    this.cart_remove(this.data._count_unit_remove)
                                }
                            }}
                            step={this.data._step_unit}
                            type="number"
                        />
                    </div>

                    {/* Skip right button actions for offer_items without availability. */}
                    {this.data.right._active && <Button
                        className="btn-quantity btn-right"
                        disabled={this.data.right._disabled}
                        icon={this.data.right._state.icon}
                        onclick={async() => {
                            this.data.loading = true
                            // Maintain same order as indicator messages; e.g. one
                            // validation action at a time to fix.
                            if (this.data._less_available) {
                                await this.cart_remove(this.data._cart_units - this.data._list_quantity_unit)
                            } else if (this.data._min_quantity_violation) {
                                await this.cart_add(this.data._min_quantity_unit - this.data._cart_units)
                            } else {
                                await this.cart_add(this.data._count_unit_add)
                            }

                            // Don't focus automatically with onscreen keyboards; e.g. tablet/mobile.
                            if ($s.env.layout === 'desktop') {
                                const input_selector = document.querySelector(`.${this.input_selector}`) as HTMLElement
                                if (input_selector) {
                                    input_selector.focus()
                                }
                            } else if (navigator.vibrate) {
                                navigator.vibrate(40)
                            }

                            this.data.loading = false
                        }}
                        size={this.data.right._state.iconSize}
                        tip={() => {
                            return this.data.right._state.tip
                        }}
                        type={this.data.right._state.type}
                        variant="toggle"
                    />}
                </div>
            </div>
        )
    }
}
