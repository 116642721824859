import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {Icon, Link} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'

export interface LotAttrs {
    type: 'default' | 'brand' | 'success' | 'info' | 'warning' | 'danger'
    purchase_order_reference?: string
    lot_date: String
    stock_age?: number
    tip: string | {(): string}
    warehouse_name?: String 
    lot_number?: String
    mutation?: {
        reference: String
    }
}

export class Lot extends MithrilTsxComponent<LotAttrs> {

    view(vn: m.Vnode<LotAttrs>) {
        if (!vn.attrs.type) {
            vn.attrs.type = 'default'
        }

        return <div className={classes('c-lot', `type-${vn.attrs.type}`)}>
            {vn.attrs.lot_date && <div className="lot-date">
                {vn.attrs.lot_date} {vn.attrs.stock_age && <span>{vn.attrs.stock_age} days</span>}
            </div>}

            <div className="lot-reference">
                {vn.attrs.type !== 'default' && <Icon
                    name='stock'
                    size='s'
                    tip={vn.attrs.tip}
                    type={vn.attrs.type}
                />}
                {vn.attrs.warehouse_name && <div className="lot-warehouse">
                    {vn.attrs.warehouse_name.split(' ').map((i) => i[0]).join('')}
                </div>}
                {vn.attrs.warehouse_name && vn.attrs.lot_number && '-'}
                <div className="lot-number">
                    {(() => {
                        if (vn.attrs.lot_number) {
                            const stock_url = `/stock/manage?search=${vn.attrs.lot_number}&available_only=false`.replace(/ /g, '+')
                            return <Link href={stock_url}>{vn.attrs.lot_number}</Link>
                        } else if (vn.attrs.mutation) {
                            return <Icon
                                name='plane'
                                size='s'
                                tip={() => {
                                    return `Part of <a href='#!/stock/mutations/manage/${vn.attrs.mutation?.reference}' target='_blank'>mutation ${vn.attrs.mutation?.reference}</a>`
                                }}
                                type='info'
                            />
                        } else {
                            return <Icon
                                name='cart'
                                size='s'
                                tip={() => {
                                    return `This is a purchase order item: ${vn.attrs.purchase_order_reference}`
                                }}
                                type='info'
                            />
                        }
                    })()}
                </div>
            </div>
        </div>
    }
}
