import m from 'mithril'
import {proxy} from '@bitstillery/common/lib/proxy'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {classes} from '@bitstillery/common/lib/utils'

export interface DropdownAttrs {
    clickOutside?: boolean
    className?: string
    key?: string | undefined
    model?: {
        toggle: boolean
    }
    trigger: 'click' | 'hover'
}

export class Dropdown extends MithrilTsxComponent<DropdownAttrs> {
    clickOutside:boolean = false
    data: {
        toggle: boolean
    }

    model: {
        toggle: boolean
    }

    remove_on_click_outside: Function

    constructor(vnode: m.Vnode<DropdownAttrs>) {
        super()

        if (vnode.attrs.model) {
            this.model = vnode.attrs.model
            this.data = this.model
        } else {
            this.data = proxy({toggle: false})
            this.model = this.data
        }
    }

    add_on_click_outside(vnode: m.Vnode<DropdownAttrs>) {
        const outside_click_listener = (event) => {
            if (!vnode.dom.contains(event.target)) {
                this.model.toggle = false
            } else {
                this.model.toggle = true
            }
        }

        document.addEventListener('click', outside_click_listener)
        this.remove_on_click_outside = () => {
            document.removeEventListener('click', outside_click_listener)
        }
    }

    oncreate(vnode: m.Vnode<DropdownAttrs>) {
        if (vnode.attrs.trigger === 'click') {
            if (vnode.attrs.clickOutside === undefined) {
                this.clickOutside = true
            } else {
                this.clickOutside = !!vnode.attrs.clickOutside
            }
            if (this.clickOutside) {
                this.add_on_click_outside(vnode)
            }
        }
    }

    onremove(vnode: m.Vnode<DropdownAttrs>) {
        if (vnode.attrs.trigger === 'click' && this.clickOutside) {
            this.remove_on_click_outside()
        }
    }

    view(vnode: m.Vnode<DropdownAttrs>) {
        if (vnode.attrs.model) {
            this.model = vnode.attrs.model
        } else {
            this.model = this.data
        }
        return <div
            className={classes('c-dropdown', vnode.attrs.className, {
                active: !vnode.attrs.disabled && this.model.toggle,
                disabled: vnode.attrs.disabled,
            })}
            onmouseenter={() => {
                if (vnode.attrs.trigger === 'hover' && !this.model.toggle) {
                    this.model.toggle = true
                }
            }}
            onmouseleave={() => {
                if (vnode.attrs.trigger === 'hover' && this.model.toggle) {
                    this.model.toggle = false
                }
            }}
            key={vnode.attrs.key}
        >
            {vnode.children[0]}
            {vnode.children[1]}
        </div>
    }
}
