import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {Icon} from '@bitstillery/common/components'
import {classes} from '@bitstillery/common/lib/utils'
import {$s} from '@bitstillery/common/app'

interface PanelBulkAttrs {
    className?: string
    collection: any
}

export class PanelBulk extends MithrilTsxComponent<PanelBulkAttrs> {

    view(vnode:m.Vnode<PanelBulkAttrs>) {
        return <div className={classes('c-panel-bulk float-panel', vnode.attrs.className, {
            'float-panel--active': ['mobile', 'tablet'].includes($s.env.layout),
        })}>
            {vnode.attrs.collection.state.ready && [
                <header onclick={() => {
                    $s.panels.view.filters.collapsed = !$s.panels.view.filters.collapsed
                }}>
                    <div className="title">
                        <Icon className="icon-d" name="bulkActions"/>
                        <span>Bulk</span>
                    </div>
                </header>,

                <div className="scroll-container">
                    {vnode.children}
                </div>,
            ]}
        </div>
    }
}
