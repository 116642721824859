import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Button, Dropdown, Icon} from '@bitstillery/common/components'
import {classes} from '@bitstillery/common/lib/utils'
import m from 'mithril'
import {proxy} from '@bitstillery/common/lib/proxy'
import {$t} from '@bitstillery/common/app'

interface CollectionSorterAttrs {
    className: string
    collection: any
    disabled?: boolean
}

export class CollectionSorter extends MithrilTsxComponent<CollectionSorterAttrs> {

    data = proxy({
        toggle: false,
    })

    view(vnode:m.Vnode<CollectionSorterAttrs>) {
        const collection = vnode.attrs.collection

        if (!collection.state.ready) return null
        return <div className={classes('c-collection-sorter', {
            disabled: vnode.attrs.collection.state.bulk_mode === 'edit',
        })}>
            <div className="sorter-label">Sort by</div>
            <Dropdown
                clickOutside={true}
                model={this.data}
                trigger="click"
            >

                <Button
                    active={this.data.toggle}
                    className="sort-placeholder"
                    onclick={() => {
                        this.data.toggle = !this.data.toggle
                    }}
                    size="d"
                    tip={$t('filters.sort.options')}
                    type="unset"
                    variant="unset"
                >
                    <span>{(() => {
                        const sort_option = collection.state.sort.options.find((o) => o[0] === collection.state.sort.by)
                        if (sort_option) return sort_option[1]
                        return 'Unknown'
                    })()}</span>
                    <Icon name="chevronDown" type="unset" />
                </Button>

                <div className="dropdown-body">
                    {collection.state.sort.options.map((option) => <button
                        disabled={vnode.attrs.disabled}
                        className={classes('sort-item', {active: option === collection.state.sort.by})}
                        onclick={(e) => {
                            e.stopPropagation()
                            collection.state.sort.by = option[0]
                            this.data.toggle = false
                        }}>
                        {option[1]}
                    </button>)}
                </div>
            </Dropdown>
            <Button
                className="sort-direction"
                disabled={collection.state.bulk_mode !== ''}
                icon={collection.state.sort.order === 'desc' ? 'sortDescending' : 'sortAscending'}
                onclick={(e) => {
                    e.stopPropagation()
                    if (collection.state.sort.order === 'asc') {
                        collection.state.sort.order = 'desc'
                    } else {
                        collection.state.sort.order = 'asc'
                    }
                    this.data.toggle = false
                }}
                tip={$t('filters.sort.direction_tip')}
                type="unset"
                variant="unset"
            />
        </div>
    }
}
