import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {
    CollectionColumns,
    CollectionFilterControl,
    CollectionBulkControl,
    CollectionSorter,
    CollectionStatus,
} from '@bitstillery/common/components'
import {$s} from '@bitstillery/common/app'

interface CollectionHeaderAttrs {
    collection: any
    columns: any[]
}
export class CollectionHeader extends MithrilTsxComponent<CollectionHeaderAttrs> {

    view(vnode:m.Vnode<any>) {
        return <div className="c-collection-header">
            {vnode.children.length ? vnode.children : [
                <div className="actions">
                    {$s.env.layout === 'desktop' && !!Object.values(vnode.attrs.collection.filters).length &&
                        <CollectionFilterControl collection={vnode.attrs.collection} />}
                    {vnode.attrs.collection.bulk && <CollectionBulkControl
                        action="edit"
                        collection={vnode.attrs.collection}
                    />}
                    <CollectionSorter collection={vnode.attrs.collection}/>
                    <CollectionStatus collection={vnode.attrs.collection}/>
                </div>,
                <CollectionColumns
                    collection={vnode.attrs.collection}
                    columns={vnode.attrs.columns}
                />,
            ]}
        </div>
    }
}
