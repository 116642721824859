import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {unique_id} from '@bitstillery/common/lib/utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {$t} from '@bitstillery/common/app'

export class RadioGroup extends MithrilTsxComponent<any> {

    name = unique_id()

    view(vn: m.Vnode<any>) {
        return (
            <div className={classes('c-radio-group', 'field', vn.attrs.className)}>
                {vn.attrs.label && <label>{vn.attrs.label}</label>}
                {vn.attrs.options.map((option) => {
                    return (
                        <div className={classes('item', {disabled: option.length >= 3 && option[2] === 0})}>
                            <input
                                autocomplete="off"
                                checked={(option[0] === vn.attrs.model[0][vn.attrs.model[1]] ? 'checked' : '')}
                                disabled={vn.attrs.disabled}
                                onclick={() => {vn.attrs.model[0][vn.attrs.model[1]] = option[0]}}
                                type="radio"
                                id={`${this.name}-${option[0]}`}
                                name={`${this.name}-${option[0]}`}
                            />
                            <label for={`${this.name}-${option[0]}`}>
                                {(() => {
                                    if (!vn.attrs.translate) return option[1]
                                    return $t(`${vn.attrs.translate.prefix}${option[1]}`)
                                })()}
                            </label>
                            {option.length >= 3 && <div className="count">{option[2]}</div>}
                        </div>
                    )
                })}
                {vn.attrs.help && <div class="help">
                    {typeof vn.attrs.help === 'function' ? vn.attrs.help() : vn.attrs.help}
                </div>}
            </div>
        )
    }
}
