{
    "collection": {
        "bulk": {
            "tip": {
                "cancel": "Bulkbewerking annuleren",
                "cancel_changes": "Bulkbewerking annuleren (alle niet-uitgevoerde wijzigingen ongedaan maken)",
                "persist_one": "Gewijzigd bulkitem behouden",
                "persist_other": "Volhouden {{count}} gewijzigde bulkitems",
                "toggle": "Schakel de bulkbewerkingsmodus in"
            }
        },
        "exclude_label": "Exclusief",
        "exclude_one": "Exclusief {{count}} item",
        "exclude_other": "Exclusief {{count}} artikelen",
        "exclude_tip": "Sluit items uit; {{count}} items die momenteel zijn inbegrepen",
        "include_label": "Opnemen",
        "include_one": "Inclusief {{count}} item",
        "include_other": "Inclusief {{count}} items",
        "include_tip": "Items opnemen; {{count}} reeds opgenomen items",
        "modify_selection": "Selectie wijzigen",
        "no_results": "Geen resultaten gevonden",
        "no_results_clear_filters": "Heb je geprobeerd de actieve filters te wissen?"
    },
    "contact": {},
    "data_card": {
        "text": "Gegevenskaart",
        "tip": "Toggle gegevenskaart"
    },
    "dialog": {
        "cancel": "Annuleren"
    },
    "filters": {
        "actions": {
            "clear_filter_selection": "Filter wissen: {{name}}/{{selection}}",
            "deactivate_filter": "Filter deactiveren",
            "pricelist": "Prijslijst"
        },
        "clear": "Filters wissen",
        "collapse": "Filters samenvouwen",
        "collapse_tip": "Alle filters samenvouwen",
        "expand": "Filters uitbreiden",
        "expand_tip": "Alle filters uitvouwen",
        "inactive_filter": "Filter is inactief",
        "multi_selection": {},
        "no_results": "Geen producten",
        "no_results_hint": {
            "filters": "Je actieve filters aanpassen?",
            "search": "Je zoektermen aanpassen?"
        },
        "no_selection": "Geen items geselecteerd",
        "panel_title": "Filters",
        "personal_offer": "Persoonlijk",
        "reset_disabled": "Schakel filters in om deze optie te gebruiken",
        "reset_one": "Filter resetten",
        "reset_other": "Reset {{count}} filters",
        "sort": {
            "direction": "Sorteer volgorde",
            "direction_tip": "Sorteervolgorde wijzigen",
            "name": "Sorteer",
            "options": "Sorteeropties",
            "reset": "Sorteren opnieuw instellen",
            "types": {
                "avg_purchase_price": "Aankoopprijs",
                "created_by": "Gemaakt door",
                "created_on": "Gemaakt op",
                "delivery_period": "Levertijd",
                "entry_date": "Ingangsdatum",
                "invoice_number": "Factuurnummer",
                "is_invoiced_on": "Factuurdatum",
                "item_stock_age": "Leeftijd voorraad",
                "item_throughput": "Doorvoer",
                "last_price_change_age": "Prijs leeftijd",
                "list_price": "Verkoopprijs",
                "list_quantity": "Hoeveelheid",
                "lowest_price_competitors": "Laagste prijs bij de concurrent",
                "margin_percentage": "Marge",
                "market_score": "Rang",
                "number_of_cases": "Hoeveelheid",
                "number_of_cases_available": "Beschikbaarheid",
                "portal_status": "Status",
                "price_age_in_days": "Prijs leeftijd (dagen)",
                "price_per_case": "Basisverkoopprijs",
                "product_name": "Naam product",
                "reference": "Referentie",
                "stock_age": "Leeftijd voorraad",
                "total_value": "Totale waarde"
            }
        },
        "title": {
            "cases_available": "Aantal beschikbare dozen",
            "category": "Categorie",
            "customs_status": "Douanestatus",
            "flags": "Item Eigenschappen",
            "item_type": "Type artikel",
            "offer_hash": "Persoonlijke aanbiedingen",
            "price_age": "Prijs leeftijd",
            "properties": "Eigenschappen",
            "purchase_history_selection_overall": "Aankoopgeschiedenis algemeen",
            "purchase_history_selection_supplier": "Aankoopgeschiedenis leverancier",
            "relation": "Relatie",
            "relation_type": "Type relatie",
            "sales_history_selection": "Verkoopgeschiedenis",
            "stock_age_range": "Leeftijd voorraad",
            "stock_availability_selection": "Voorraad",
            "suppressed_selection": "Onderdrukte selectie"
        },
        "types": {
            "category": "Categorieën",
            "packaging": {
                "+ Mug": "+ Mok",
                "2 Glasses": "2 glazen",
                "2 Golden Glasses": "2 gouden glazen",
                "Aluminium Bottle": "Aluminium fles",
                "Bottle": "Fles",
                "Can": "Kan",
                "Cannister": "Bus",
                "Cradle": "Wieg",
                "Flask": "Fles",
                "Frosted Bottle": "Matte fles",
                "GB + Jar of Cherries": "GB + pot kersen",
                "GB with Cradle": "GB met houder",
                "Giftbag": "Cadeautas",
                "Giftbox": "Cadeaudoos",
                "Gifttin": "Geschenkblik",
                "Leather GB": "Leer GB",
                "PET bottle": "PET-fles",
                "Pack": "Pak",
                "Porcelain Bottle": "Porseleinen fles",
                "Slightly dirty outercases": "Licht vervuilde buitenkasten",
                "Square Bottle": "Vierkante fles",
                "Wooden Giftbox": "Houten cadeaudoos"
            },
            "products": "Producten"
        },
        "unit": {},
        "units": {
            "%": "%",
            "cl": "cl",
            "cs": "cs",
            "day_one": "dag",
            "day_other": "dagen"
        }
    },
    "forgot_password": {
        "confirmed": "De e-mail is onderweg. Controleer je inbox (en je spamfolder) voor de e-mail met de link voor het resetten van je wachtwoord.",
        "description": "Wachtwoord vergeten? Voer hieronder je e-mailadres in, klik op de knop en je ontvangt een e-mail waarmee je je wachtwoord opnieuw kunt instellen.",
        "email": "Je e-mailadres",
        "email_help": "Het e-mailadres dat je gebruikt om in te loggen",
        "start_flow": "Reset e-mail aanvragen",
        "title": "Wachtwoord vergeten"
    },
    "issues": {
        "button": {
            "label": {
                "select_image": "Afbeelding selecteren",
                "submit_issue": "Vraag indienen",
                "take_snapshot": "Momentopname maken"
            }
        },
        "field": {
            "help": {
                "description": "Een beknopte en volledige beschrijving van de kwestie",
                "media": "Een screenshot of video die het probleem laat zien",
                "title": "Vier of vijf woorden die de kwestie samenvatten",
                "url": "URL naar de uitgiftepagina"
            },
            "label": {
                "description": "Beschrijving",
                "media": "Media",
                "title": "Titel",
                "url": "URL"
            },
            "placeholder": {
                "description": "Een beknopte en volledige beschrijving van het probleem, zodat we het probleem beter en sneller kunnen identificeren.",
                "media": "Ctrl-v om een afbeelding te plakken",
                "title": "Vier of vijf woorden die de kwestie samenvatten",
                "url": "URL naar de uitgiftepagina"
            }
        },
        "label": {
            "title": "Een probleem melden",
            "title_submitted": "Bedankt voor je bugrapport!"
        },
        "support": {
            "help": {
                "template": "Het sjabloon dat wordt gebruikt voor nieuwe Gitlab issues"
            },
            "label": {
                "button_submit_issue": "Instellingen opslaan",
                "template": "Sjabloon"
            },
            "placeholder": {
                "template": "Dit bevat een duidelijk sjabloon voor optimale bugrapporten."
            }
        },
        "text": {
            "submitted": "Bedankt voor het indienen van je issue report! Je kunt de status van je issue controleren in onze <a href={{link}} target='_blank'>issue tracker</a>. Voel je vrij om gerelateerde opmerkingen toe te voegen aan het issue; hetzij om extra context te bieden of gewoon als je wat extra verduidelijking wilt. We maken Discover & Portal stap voor stap beter, dankzij jouw hulp!"
        }
    },
    "landing": {
        "force_logout": "Sorry, je moet opnieuw inloggen...",
        "login": {
            "otp_code": "Veiligheidstoken",
            "otp_code_help": "Voer hier de code in die door de app wordt gegenereerd",
            "step0_desc": "Gebruik je {{vendor}} inloggegevens voor toegang. Als je je wachtwoord kwijt bent, kun je hieronder je e-mailadres invullen en op de link \"Wachtwoord vergeten\" klikken.",
            "step0_slug": "Inloggen",
            "step1_desc": "Je hebt een extra authenticatie-app nodig om je account te beschermen tegen ongeautoriseerde toegang. Installeer een van deze authenticator app suggesties op je mobiele telefoon en scan de QR-code. Gebruik het token van je telefoon om je apparaat te registreren en in te loggen. Voeg het token opnieuw toe in je app als er iets misgaat tijdens het proces. Nog steeds geen geluk? Neem contact met ons op voor ondersteuning.",
            "step1_slug": "Extra beveiliging",
            "step2_desc": "Er is een extra beveiligingstoken nodig. Controleer je authenticator app voor het token. Geen toegang meer tot je authenticator app? Neem contact met ons op voor hulp.",
            "step2_slug": "Beveiligingstoken vereist",
            "subtitle": "Log in om verder te gaan...",
            "title": "Welkom op {{vendor}}!"
        }
    },
    "languages": {
        "de": "Duits",
        "en_gb": "Engels",
        "es": "Spaans",
        "fr": "Frans",
        "it": "Italiaans",
        "nl": "Nederlands"
    },
    "my_portal": {
        "label": "Mijn portaal",
        "logout": "Afmelden",
        "settings": {
            "language": "Taal",
            "language_help": "Gegenereerd door AI; kan onjuist taalgebruik bevatten"
        },
        "tip": "Mijn portaal - Nieuws, profiel en instellingen"
    },
    "page": {
        "subtitle": {
            "forgot_password": "Wachtwoord opnieuw instellen",
            "reset_password": "Controleer je nieuwe wachtwoord nog een keer..."
        },
        "title": {
            "forgot_password": "Wachtwoord vergeten",
            "login": "Welkom op {{vendor}}!",
            "login_slug": "Inloggen",
            "registration": "Registratie",
            "reset_password": "Wachtwoord opnieuw instellen"
        }
    },
    "password_reset": {
        "confirmed": "Je wachtwoord is opnieuw ingesteld",
        "description": {
            "new-account": "Nadat je je wachtwoord hebt ingesteld, wordt je gevraagd om in te loggen.",
            "reset": "Na het resetten van je wachtwoord wordt je gevraagd om opnieuw in te loggen."
        },
        "email": "Je e-mailadres",
        "email_help": "Het e-mailadres dat je gebruikt voor het portaal",
        "failed": "Verzoek om je wachtwoord te wijzigen mislukt: \"{{reason}}\"",
        "new_password": "Nieuw wachtwoord",
        "new_password_help": "Je voorkeurswachtwoord; controleer met het oog of je wachtwoord juist is",
        "new_password_repeat": "Herhaal wachtwoord",
        "new_password_repeat_help": "Herhaal je wachtwoord",
        "reset_button": "Wachtwoord bijwerken",
        "title": {
            "new-account": "Wachtwoord aanmaken",
            "reset": "Wachtwoord bijwerken"
        }
    },
    "price": {
        "offer_until_one": "Laatste aanbiedingsdag!",
        "offer_until_other": "Nog {{count}} dagen geldig",
        "price_down": "Onlangs afgeprijsd van {{price}} {{currency}}"
    },
    "product": {
        "availability": {
            "eta": "Aankomst ~ {{period}}",
            "stock": "Op voorraad",
            "tbo": "Te bestellen"
        }
    },
    "profile": {
        "email": "Je e-mailadres",
        "email_help": "Je e-mailadres om mee in te loggen",
        "email_placeholder": "you@yourbusiness.com",
        "forgot_pw": "Wachtwoord vergeten?",
        "login": "Inloggen",
        "password": "Je wachtwoord",
        "password_help": "Het wachtwoord voor {{vendor}}",
        "password_placeholder": "Psst, het is een geheim!"
    },
    "rating": {
        "rate": {
            "description": "Jouw feedback helpt ons om onze service aan jou te verbeteren",
            "placeholder": "Laat het ons weten als je suggesties hebt over hoe we de manier waarop je bestelt kunnen verbeteren.",
            "sent": "Hartelijk dank voor de feedback!",
            "title": "Wat vind je van ons bestelproces?"
        },
        "send.title": "Stuur",
        "thanks": {
            "description": "We doen ons best om onze service zo goed mogelijk te maken. Als je nog vragen of suggesties hebt, neem dan contact op met je support manager.",
            "notification": "Hartelijk dank voor de feedback!",
            "title": "Hartelijk dank namens {{team}}!"
        }
    },
    "sales_order_addition_types": {
        "discount": "Korting",
        "other": "Diversen",
        "pallet": "Pallet",
        "revenue": "Inkomsten",
        "transport": "Tranport",
        "voucher": "Voucher",
        "waste_fund": "Afvalfonds"
    },
    "settings": {
        "beta": {
            "active": "Bètamodus geactiveerd",
            "inactive": "Beta-modus uitgeschakeld"
        }
    },
    "status": {
        "error": {
            "login": {
                "credentials_invalid": "Er is een probleem met je inloggegevens! Controleer je e-mailadres en wachtwoord."
            },
            "message": "Er is een onverwachte fout opgetreden; probeer het later nog eens (referentie: {{message}})",
            "order": {
                "delivery_invalid": "Aan de leveringsvoorwaarde is niet voldaan"
            },
            "otp": {
                "invalid": "Authenticatietoken is ongeldig; controleer je authenticator app.",
                "invalid_notify": "Je authenticatietoken is ongeldig. Controleer je app voor de juiste code. Als het probleem zich blijft voordoen, controleer dan of de datum/tijd-instellingen van je apparaat juist zijn.",
                "length": "Authenticatietoken is 6 cijfers"
            },
            "unknown": "Het spijt ons! Er heeft zich een onverwachte fout voorgedaan. We anticiperen actief op dit soort problemen. Probeer het later nog eens om te zien of het probleem al is opgelost. Neem contact met ons op als het probleem langere tijd aanhoudt. Bedankt voor je geduld."
        },
        "success": {
            "login": {
                "forgot_pw": "Er wordt een e-mail met instructies om je wachtwoord opnieuw in te stellen naar {{email}} gestuurd als het een geldig e-mailadres is. Geen e-mail? Controleer het e-mailadres en controleer je spammap. Neem contact met ons op als je nog steeds geen e-mail hebt ontvangen."
            },
            "redeem": {
                "verified": "Gefeliciteerd! Je hebt met succes de code gebruikt voor: {{name}}!"
            }
        }
    },
    "support": {
        "contact": {
            "collapse": "Toggle ondersteuningsdetails",
            "email": "Contact e-mail: {{contact}}",
            "mobile": "Contact mobiel: {{contact}}",
            "phone": "Telefoon contactpersoon: {{contact}}",
            "support_manager": "Je support manager",
            "team_etr": "Team A2BC",
            "team_msp": "Team Moving Spirits",
            "tech_support": "Tech Ondersteuning"
        },
        "roles": {
            "default": "Support Manager",
            "sales_nl": "Trader Nederland",
            "tech": "Technische ondersteuning"
        }
    },
    "system": {
        "incompatible": "Je browser voldoet niet aan de minimale vereisten; werk je browser eerst bij ({{code}})",
        "reload_portal": "Herlaad portaal",
        "scheme_change": "Een recente update vereist dat je opnieuw inlogt"
    },
    "unit": {
        "bottle_one": "fles",
        "bottle_other": "flessen",
        "case_one": "doos",
        "case_other": "dozen",
        "percentage": "%"
    },
    "validation": {
        "email": "Dit lijkt een ongeldig emailadres te zijn",
        "form": {
            "action_required": "Deze velden vereisen je aandacht:",
            "valid": "Het formulier ziet er goed uit"
        },
        "length": {
            "fixed_length": "Dit veld moet precies {{fixed_length}} tekens lang zijn",
            "max_length": "Dit veld field moet niet langer zijn dan {{max_length}} tekens",
            "min_length": "Dit veld moet tenminste {{min_length}} tekens lang zijn"
        },
        "must_match": "De velden moeten overeenkomen met",
        "password": {
            "cannot_end_with_space": "Wachtwoord kan niet eindigen met een spatie",
            "min_length": "Wachtwoord vereist minstens 9 tekens",
            "one_lower_case": "Wachtwoord moet tenminste 1 kleine letter bevatten",
            "one_upper_case": "Wachtwoord moet tenminste 1 hoofdletter bevatten"
        },
        "required": "Dit veld is verplicht",
        "vat_id": {
            "country_incorrect": "BTW-nummer heeft een onjuiste landcode; verwacht: '{{expected}}'",
            "help_disabled": "BTW-ID wordt niet ondersteund voor niet-EU-landen: {{country}}",
            "help_validate": "Gebruik de knop Valideren om de btw-id voor het land te verifiëren: {{country}}",
            "invalid_format": "Ongeldig <a href='{{link}}' target='_blank'>BTW ID-formaat</a> voor land: {{country_code}}",
            "label": "BTW-NUMMER ({{country}})",
            "verify": "ID verifiëren",
            "vies_match": "BTW ID komt overeen: {{match}}",
            "vies_nomatch": "Dit BTW-nummer lijkt ongeldig"
        },
        "website": "Dis is geen geldig website adres"
    }
}