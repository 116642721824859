import m from 'mithril'
import {FiltersDescription, Filters, NonPartial} from '@bitstillery/common/types'
import {proxy} from '@bitstillery/common/lib/proxy'
import {merge_deep} from '@bitstillery/common/lib/utils'
import {
    FilterSelectMultiple,
    FilterSelectSingle,
    FilterSelectRange,
    FilterToggle,
    FilterText,
} from '@bitstillery/common/components'
import {logger} from '@bitstillery/common/app'

/**
 * Returns how many filters are currently activated.
 * @param filters - The filters state to check on
 * @returns Number of filters active
 */
export function active_filters(filters) {
    let active = 0
    if (!filters) return active

    for (const [, filter] of Object.entries(filters)) {
        if (!is_filter_active(filter)) continue
        if (filter.type !== 'SELECT_MULTIPLE') {
            active += 1
            continue
        }

        // A SELECT_MULTIPLE filter with children; the amount of active
        // filters is the amount of parents or the amount of children,
        // when not all children are selected.
        if (filter.options.length) {
            const ids = filter.options.map((i) => {
                const ids = []
                if (filter.selection.includes(i[0])) {
                    // Parent is selected; no need to add the children.
                    ids.push(i[0])
                } else if (i.length >= 4) {
                    // Parent is not selected; add the children instead.
                    const subids = i[3].filter((_i) => filter.selection.includes(_i[0])).map(((_i) => _i[0]))
                    ids.push.apply(ids, subids)
                }
                return ids
            }).flat()
            active += ids.length
        } else {
            active += filter.selection.length
        }

    }
    return active
}

/**
 * Set a filter back to its unset state.
 * @param filter
 */
export function clear_filter(filter) {
    if (Array.isArray(filter.serialize)) {
        filter.selection.splice(0, filter.selection.length, ...unset_filter_selection(filter))
    } else {
        filter.selection = unset_filter_selection(filter)
    }

    filter.collapsed = true
}

/**
 * Deserialize filter url query parameters using one of the serializable
 * types: string, number, boolean, [string], [number], [boolean]
 * @param data_type
 * @param value
 * @returns
 */
export function deserialize_filter_param(data_type, value) {
    if (data_type === 'string') {
        return String(value)
    } else if (data_type === 'number') {
        return Number(value)
    } else if (data_type === 'boolean') {
        if (value === 'true') {
            return true
        } else {
            return false
        }
    } else if (Array.isArray(data_type)) {
        const array_type = data_type[0]

        let selection
        if (array_type === 'string' || array_type === '[string]') {
            selection = value.split(',')
        }
        else if (array_type === 'number' || array_type === '[number]') {
            selection = value.split(',').map((i) => {
                if (i === 'Infinity') {
                    return Infinity
                }
                return Number(i)
            })
        }

        return selection
    }
}

/**
 * Generate URL query parameters from active filters.
 * @param filters
 */
export function filters_to_url(filters:Filters) {
    logger.debug('[filters] filters_to_url')
    const params = {}
    const pathname = m.parsePathname(m.route.get()) as any

    const params_from_url = Object.fromEntries(new URLSearchParams(pathname.params))
    for (const [filter_name, filter] of Object.entries(filters)) {
        if (is_filter_active(filter)) {
            if (Array.isArray(filter.selection)) {
                params[filter_name] = encodeURI(filter.selection.join(','))
            } else {
                params[filter_name] = encodeURI(filter.selection)
            }
        } else if (filter_name in params_from_url) {
            // Remove any inactive filters from the new URL.
            delete params_from_url[filter_name]
        }
    }

    merge_deep(params_from_url, params)
    return params_from_url
}

/**
 * Generate the initial filter datamodel from a filter description.
 * Data that can be derived is added to each filter, to keep the
 * initial data model as compact as possible.
 * @param filters
 */
export function generate_filters<T extends FiltersDescription>(filters_description:T): NonPartial<T> {
    const filters: Filters = {}
    for (const [filter_key, filter_description] of Object.entries(filters_description)) {
        const filter_type = filter_description.type
        const derived_data = {
            count: 'count' in filter_description ? filter_description.count : false,
            loading: true,
            title: `filters.title.${filter_key}`,
        }

        if (!('selection' in filter_description)) {
            if (filter_type === 'SELECT_SINGLE') {
                derived_data.selection = ''
            } else if (filter_type === 'SELECT_MULTIPLE') {
                derived_data.selection = []
            } else if (filter_type === 'SELECT_RANGE') {
                derived_data.selection = filter_description.default
            } else if (filter_type === 'TEXT') {
                Object.assign(derived_data, {
                    icon: 'search',
                    input: '',
                    selection: unset_filter_selection(filter_description),
                })
            }
        }

        if (['SELECT_MULTIPLE', 'SELECT_SINGLE', 'TEXT', 'TOGGLE'].includes(filter_type) && !('options' in filter_description)) {
            derived_data.options = []
        }

        filters[filter_key] = {...filter_description, ...derived_data}
        filters[filter_key].collapsed = !is_filter_active(filters[filter_key])
    }

    return proxy<NonPartial<T>>(filters)
}

export function is_filter_active(filter) {
    if (filter.disabled) {
        return false
    }

    if (Array.isArray(filter.serialize)) {
        // A range filter acts a bit different; the default range value
        // is as an empty selection/unset filter.
        if (filter.type === 'SELECT_RANGE') {
            const is_active = (
                filter.selection.length === 2 && (
                    (filter.selection[0] !== filter.default[0]) ||
                    (filter.selection[1] !== filter.default[1])
                )
            )
            return is_active
        }

        // Regular filters just look for selection items.
        return !!filter.selection.length
    } else {
        const unset_value = unset_filter_selection(filter)
        return filter.selection !== unset_value
    }
}

export function place_filter(filter) {
    if (filter.type === 'TEXT') {
        return <FilterText
            filter={filter}
        />
    } else if (filter.type === 'SELECT_SINGLE') {
        return <FilterSelectSingle
            filter={filter}
            filter_count={filter.count}
            translate={filter.options_i18n ? {prefix: filter.options_i18n} : undefined}
        />
    } else if (filter.type === 'SELECT_RANGE') {
        return <FilterSelectRange
            filter={filter}
        />
    } else if (filter.type === 'SELECT_MULTIPLE') {
        return <FilterSelectMultiple
            filter={filter}
            filter_count={filter.count}
            translate={filter.options_i18n ? {prefix: filter.options_i18n} : undefined}
        />
    } else if (filter.type === 'TOGGLE') {
        return <FilterToggle
            filter={filter}
            filter_count={filter.count}
            translate={filter.options_i18n ? {prefix: filter.options_i18n} : undefined}
        />
    }
}

export function place_filters(filters:Filters) {
    return Object.values(filters)
        .filter((filter) => {
            // Only filters with a placement option are rendered. Filters
            // with a min(imum) amount are only rendered, when there are at
            // least x options to choose from.
            return ('placement' in filter) && (!filter.placement.min || filter.options.length >= filter.placement.min)})
        .sort((a, b) => a.placement.order - b.placement.order)
        .map((filter) => place_filter(filter))
}

/**
 * Return filters back to their unset state.
 * @param filters
 */
export function reset_filters(filters:Filters) {
    for (const filter of Object.values(filters)) {
        clear_filter(filter)
    }
}

export function unset_filter_selection(filter):any {
    const data_type = filter.serialize
    if (data_type === 'string') {
        return ''
    } else if (data_type === 'number') {
        // Not the null type is used here, because the watcher can't handle it.
        return ''
    } else if (data_type === 'boolean') {
        return false
    } else if (Array.isArray(data_type)) {
        return []
    }
}

/**
 * Together with its counterpart ("filters_to_url"), this function
 * generates the filters state from the URL.
 * @param filters
 * @param collection
 * @returns
 */
export function url_to_filters(filters:Filters, collection) {
    // A filter like search has a different UI-flow than the ones in the filter-panel,
    // which makes it unsuitable to start with a collapsed state.
    logger.debug('[filters] url_to_filters')
    const pathname = m.parsePathname(m.route.get()) as any
    const url_params = Object.fromEntries(new URLSearchParams(pathname.params))
    if ('meta' in url_params) {
        // The URL params are ignored; instead the defaults from the meta endpoint
        // are applied to the filters. The url is pushed to the navigation bar as
        // soon asthe filter combination changes
        return
    }

    // Assign URL params to related filters state.
    const active_filter_keys = Object.keys(filters)
    for (const [url_key, url_value] of Object.entries(url_params)) {
        if (active_filter_keys.includes(url_key)) {
            const filter = filters[url_key]
            const value = decodeURI(url_value) as any
            if (Array.isArray(filter.serialize)) {
                filter.selection.splice(0, filter.selection.length, ...deserialize_filter_param(filter.serialize, value))
            } else {
                filter.selection = deserialize_filter_param(filter.serialize, value)
            }

            filter.collapsed = false
        }
    }
    // Assign default values to filters that are not in the URL.
    for (const [filter_name, filter] of Object.entries(filters)) {
        if (!(filter_name in url_params)) {
            clear_filter(filter)
        }
    }

    // Assign collection params to optional collection state.
    if (collection) {
        if (url_params.sort_by) {
            collection.state.sort.by = url_params.sort_by
        }
        if (url_params.sort_order) {
            collection.state.sort.order = url_params.sort_order
        }
    }
}
